import React from 'react';

import { CreditCard } from 'src/interfaces/user';
import Input from 'src/components/atoms/form/Input';
import ToggleHeader from 'src/components/common/ToggleHeader';
import PaymentForm from 'src/components/payment/PaymentForm';

import CSSModule from './PaymentMethodSelect.module.scss';

export type PaymentMethodType = 'card' | 'coupon';

interface Props {
  staffId: string;
  selectedPaymentMethodType: PaymentMethodType | null;
  onSelectPaymentMethodType: (type: PaymentMethodType) => void;
  couponCode: string;
  onChangeCouponCode: (code: string) => void;
  creditCard: CreditCard;
}

const PaymentMethodSelect: React.FC<Props> = ({
  staffId,
  selectedPaymentMethodType,
  onSelectPaymentMethodType,
  couponCode,
  onChangeCouponCode,
  creditCard,
}) => (
  <div className={CSSModule.PaymentMethodSelect}>
    <div>
      <ToggleHeader isOpen={selectedPaymentMethodType === 'coupon'} toggle={() => onSelectPaymentMethodType('coupon')}>
        <div>ギフトコードを利用する</div>
      </ToggleHeader>
      {selectedPaymentMethodType === 'coupon' && (
        <div>
          <Input
            name="couponCode"
            value={couponCode}
            onChange={e => onChangeCouponCode(e.currentTarget.value)}
            placeholder="ギフトコードを入力"
          />
        </div>
      )}
    </div>
    <div>
      <ToggleHeader isOpen={selectedPaymentMethodType === 'card'} toggle={() => onSelectPaymentMethodType('card')}>
        <div>クレジットカードで決済する</div>
      </ToggleHeader>
      {selectedPaymentMethodType === 'card' && (
        <div>
          {creditCard.last4 ? (
            <div className={CSSModule.PaymentMethodSelect__CardInfo}>
              <p>
                {creditCard.brand} **** **** **** {creditCard.last4}
              </p>
              <p>
                有効期限: {creditCard.expMonth}/{creditCard.expYear}
              </p>
            </div>
          ) : (
            <PaymentForm returnTo={`/staffs/${staffId}/orders/payment`} />
          )}
        </div>
      )}
    </div>
  </div>
);

export default PaymentMethodSelect;
