import React from 'react';
import { Link } from 'react-router-dom';

import { StaffCategoryName, StaffCategoryLabels } from '../../interfaces/staff';
import Input from '../atoms/form/Input';

import CSSModule from './StaffFilter.module.scss';
import classNames from 'classnames';
import Button from '../atoms/button/Button';

interface Props {
  categories: StaffCategoryName[];
  keyword: string;
  onChangekeyword: (keyword: string) => void;
  onSubmit: () => void;
}

const getSelectedCategoryText = (categories: StaffCategoryName[]) => {
  const categoryNames = categories.map((category: StaffCategoryName) => StaffCategoryLabels[category]);
  switch (categoryNames.length) {
    case 0:
      return 'カテゴリを選択する';
    case 1:
    case 2:
      return categoryNames.join('・');
    default:
      return `${categoryNames.slice(0, 2).join('・')}ほか`;
  }
};

const StaffFilter: React.FC<Props> = ({ categories, keyword, onChangekeyword, onSubmit }) => {
  const categoriesSelectClasses = classNames(CSSModule.categoryLink, { [CSSModule.active]: categories.length > 0 });

  return (
    <form className={CSSModule.staffFilter} onSubmit={onSubmit}>
      <h2 className={CSSModule.title}>書店員を探す</h2>
      <div className={CSSModule.filterBox}>
        <div className={CSSModule.label}>カテゴリ</div>
        <Link to="/staffs/categories" className={categoriesSelectClasses}>
          {getSelectedCategoryText(categories)}
        </Link>
        <div className={CSSModule.label}>フリーワード</div>
        <Input name="keyword" value={keyword} color="white" onChange={e => onChangekeyword(e.currentTarget.value)} />
      </div>
      <div className={CSSModule.submit}>
        <Button type="submit" onClick={onSubmit}>
          検索
        </Button>
      </div>
    </form>
  );
};

export default StaffFilter;
