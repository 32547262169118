type ActionName =
  | 'search_staff'
  | 'save_karte_draft'
  | 'save_karte'
  | 'purchase'
  | 'send_gift'
  | 'send_gift_by_post'
  | 'send_gift_by_hand'
  | 'click_withdrawal_button'
  | 'withdraw';

const GA_METRICS_ID = 'G-3NCF9FX2NG';

class GATracker {
  public static trackPageEvent(pathname: string) {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('config', GA_METRICS_ID, { page_path: pathname });
    }
  }

  public static trackEvent(actionName: ActionName) {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', actionName);
    }
  }
}

export default GATracker;
