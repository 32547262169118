import React from 'react';
import { Link } from 'react-router-dom';

import MainLogo from '../../assets/logo/main.png';
import XLogo from '../../assets/logo/x.png';

import CSSModule from './Footer.module.scss';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={CSSModule.Footer}>
      <div className={CSSModule.Footer__Content}>
        <Link to="/staffs" className={CSSModule.Footer__Link}>
          選書してもらう
        </Link>
        <span>|</span>
        <Link to="/gifts" className={CSSModule.Footer__Link}>
          ギフトを贈る
        </Link>
      </div>
      <div className={CSSModule.Footer__Content}>
        <a href="https://www.8-lynx.co.jp" className={CSSModule.Footer__Link} target="_blank" rel="noopener noreferrer">
          会社概要
        </a>
        <span>|</span>
        <Link to="/terms" className={CSSModule.Footer__Link}>
          利用規約
        </Link>
        <span>|</span>
        <a
          href="https://www.8-lynx.co.jp/privacy.html"
          className={CSSModule.Footer__Link}
          target="_blank"
          rel="noopener noreferrer"
        >
          プライバシーポリシー
        </a>
        <span>|</span>
        <a
          href="https://forms.gle/xRjvrbzNTk8JxQH4A"
          className={CSSModule.Footer__Link}
          target="_blank"
          rel="noopener noreferrer"
        >
          お問い合わせ
        </a>
      </div>
      <div className={CSSModule.Footer__Logos}>
        <img src={MainLogo} alt="ブックカルテ" className={CSSModule.Footer__Logo} />
        <a href="https://x.com/bookkarte" target="_blank" rel="noreferrer">
          <img src={XLogo} alt="X(旧Twitter)" className={CSSModule.Footer__XLogo} />
        </a>
      </div>
      <div className={CSSModule.Footer__Copyright}>© {currentYear} 8-Lynx Co.,Ltd. All rights reserved.</div>
    </footer>
  );
};

export default Footer;
