import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import Authenticated from '../../containers/common/Authenticated';
import useQuery from '../../hooks/common/useQuery';
import useToast from '../../hooks/store/useToast';
import AppTemplate from '../templates/AppTemplate';
import GiftRequestForm, { FormValues } from './GiftRequestForm';

const requestBookCouponQuery = `mutation requestBookCoupon(
  $recipientName: String!,
  $email: String!,
  $message: String
) {
  requestBookCoupon(input: {
    recipientName: $recipientName,
    email: $email,
    message: $message
  }) { email }
}`;

interface RequestBookCouponResult {
  requestBookCoupon: {
    email: string;
  };
}

const GiftRequestPage: React.FC = () => {
  const navigate = useNavigate();
  const showToast = useToast();
  const { executeQuery, isLoading, error, result } = useQuery<FormValues, RequestBookCouponResult>(
    requestBookCouponQuery,
  );

  useEffect(() => {
    if (result?.requestBookCoupon.email) {
      showToast('ギフトリクエストを送信しました。');

      navigate('/mypage');
    }
  }, [navigate, showToast, result]);

  const onSubmit = (values: FormValues) => executeQuery(values);

  const disabled = isLoading;
  const serverErrors = error ? [error.message] : [];

  return (
    <AppTemplate>
      <Authenticated>
        <GiftRequestForm disabled={disabled} serverErrors={serverErrors} onSubmit={onSubmit} />
      </Authenticated>
    </AppTemplate>
  );
};

export default GiftRequestPage;
