import React from 'react';
import classNames from 'classnames';

import { KarteTemplate, optionNames } from '../../interfaces/karte';
import Select from '../atoms/form/Select';

import CSSModule from './KarteTemplateSelect.module.scss';

interface Props {
  selectedQuestoinTemplateId: string;
  selectedTemplate?: KarteTemplate;
  templates: KarteTemplate[];
  onChange: (karte?: KarteTemplate) => void;
}

const KarteTemplateSelect: React.FC<Props> = ({
  selectedQuestoinTemplateId,
  selectedTemplate,
  templates,
  onChange,
}) => {
  const options = templates.map(template => ({
    label: optionNames[template.orderNumber],
    value: template.id,
    disabled: template.karteQuestionTemplate.id !== selectedQuestoinTemplateId,
  }));

  const onTemplateSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTemplate = templates.find(template => template.id === e.currentTarget.value);
    onChange(selectedTemplate);
  };

  const classes = classNames(CSSModule.wrapper, { [CSSModule.active]: selectedTemplate });

  return (
    <div className={classes}>
      <Select
        name="karteTemplate"
        options={options}
        onChange={onTemplateSelected}
        className={CSSModule.select}
        hasEmptyOption
        emptyOptionLabel="引用しない"
        label="マイカルテを引用"
      />
    </div>
  );
};

export default KarteTemplateSelect;
