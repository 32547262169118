import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { AdminStaff, StaffCategoryName, PhotoData } from 'src/interfaces/staff';
import { setFlashMessage } from 'src/actions/common';
import useMutation from 'src/hooks/common/useMutation';
import useFileUploader from 'src/hooks/common/useFileUploader';
import FileLoader from 'src/utils/fileLoader';
import { StaffPhotoMaxSize } from 'src/app.constants';
import parseToInt from 'src/utils/parseToInt';
import { FormValues } from 'src/components/admin/staffs/StaffForm';
import AdminEditStaffPage from 'src/components/admin/staffs/AdminEditStaffPage';

const createStaffQuery = `
  mutation createStaff(
    $staff: StaffInput!,
    $photoKeys: [String!]!,
    $categories: [String!]!
  ) {
    createStaff(input: {
      staff: $staff,
      photoKeys: $photoKeys,
      categories: $categories
    }) { error }
  }
`;

const updateStaffQuery = `
  mutation updateStaff(
    $staffId: String!,
    $staff: StaffInput!,
    $photoKeys: [String!]!,
    $categories: [String!]!
  ) {
    updateStaff(input: {
      staffId: $staffId,
      staff: $staff,
      photoKeys: $photoKeys,
      categories: $categories
    }) { error }
  }
`;

interface StaffInput {
  name: string;
  email: string;
  bookStoreName: string;
  slogan: string;
  profile: string;
  monthlyLimit: number;
  searchable: 'true' | 'false';
  adminRecommendationNumber?: number;
}

interface CreateStaffParams {
  staff: StaffInput;
  photoKeys: string[];
  categories: StaffCategoryName[];
}

interface CreateStaffResult {
  createStaff: {
    error: string | null;
    staff: AdminStaff;
  };
}

interface UpdateStaffParams {
  staffId: string;
  staff: StaffInput;
  photoKeys: string[];
  categories: StaffCategoryName[];
}

interface UpdateStaffResult {
  updateStaff: {
    error: string | null;
    staff: AdminStaff;
  };
}

interface Props {
  staff?: AdminStaff;
}

const StaffFormContainer: React.FC<Props> = ({ staff }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState<PhotoData[]>(staff ? staff.photos : []);
  const [photoError, setPhotoError] = useState<Error | null>(null);
  const [categories, setCategories] = useState<StaffCategoryName[]>(
    staff ? staff.categories.map(category => category.name) : [],
  );
  const [categoryError, setCategoryError] = useState<Error | null>(null);
  const [mutationError, setMutationError] = useState<Error | null>(null);
  const { upload, isUploading, uploadingError } = useFileUploader();
  const {
    executeMutation: createMutation,
    isLoading: isCreating,
    error: creatingError,
  } = useMutation<CreateStaffParams, CreateStaffResult>();
  const {
    executeMutation: updateMutation,
    isLoading: isUpdating,
    error: updatingError,
  } = useMutation<UpdateStaffParams, UpdateStaffResult>();

  const addPhotoError = () => {
    setPhotoError(new Error('画像を1枚以上選択してください。'));
  };

  const onAddPhoto = async (file: File | null) => {
    setPhotoError(null);
    if (file) {
      const fileLoader = new FileLoader(file);

      if (!fileLoader.isImage) {
        setPhotoError(new Error('画像ファイルを選択してください。'));
        return;
      }

      if (file.size > StaffPhotoMaxSize) {
        const filesize = StaffPhotoMaxSize / 1024;
        setPhotoError(new Error(`${filesize}KB以内のファイルを選択してください。`));
        return;
      }

      const { key, src } = await upload(file);
      setPhotos([...photos, { src, file, key }]);
    }
  };

  const onRemovePhoto = (src: string) => {
    const newPhotos = photos.filter(photo => photo.src !== src);
    setPhotos(newPhotos);
    if (newPhotos.length === 0) {
      addPhotoError();
    }
  };

  const createStaff = async (values: StaffInput) => {
    const result = await createMutation(createStaffQuery, {
      staff: {
        ...values,
        monthlyLimit: parseToInt(values.monthlyLimit) || 0,
        adminRecommendationNumber: parseToInt(values.adminRecommendationNumber) || 0,
      },
      photoKeys: photos.map(photo => photo.key).filter((key?: string | null): key is string => !!key),
      categories,
    });

    if (result && result.createStaff && result.createStaff.error) {
      throw new Error(result.createStaff.error);
    }
  };

  const updateStaff = async (staffId: string, values: StaffInput) => {
    const result = await updateMutation(updateStaffQuery, {
      staffId,
      staff: {
        ...values,
        monthlyLimit: parseToInt(values.monthlyLimit) || 0,
        adminRecommendationNumber: parseToInt(values.adminRecommendationNumber) || 0,
      },
      photoKeys: photos.map(photo => photo.key || photo.src),
      categories,
    });

    if (result && result.updateStaff && result.updateStaff.error) {
      throw new Error(result.updateStaff.error);
    }
  };

  const onSubmit = async (values: FormValues) => {
    setMutationError(null);

    if (photos.length === 0) {
      addPhotoError();
      return;
    }

    if (categories.length === 0) {
      setCategoryError(new Error('カテゴリが登録されていません。'));
      return;
    }

    try {
      const staffInput: StaffInput = {
        bookStoreName: values.bookStoreName,
        name: values.name,
        email: values.email,
        profile: values.profile,
        slogan: values.slogan,
        monthlyLimit: values.monthlyLimit,
        searchable: values.searchable,
        adminRecommendationNumber: values.adminRecommendationNumber,
      };

      if (staff) {
        await updateStaff(staff.id, staffInput);
      } else {
        await createStaff(staffInput);
      }
      const flashMessage = staff ? '書店員情報を更新しました。' : '書店員を新規作成しました。';
      dispatch(setFlashMessage({ message: flashMessage }));
      navigate('/admin');
    } catch (e) {
      setMutationError(new Error(`${e}`));
    }
  };

  const onCancel = () => {
    navigate('/admin');
  };

  const initialValues = staff && {
    bookStoreName: staff.bookStoreName,
    name: staff.name,
    email: staff.email,
    slogan: staff.slogan,
    profile: staff.profile,
    monthlyLimit: staff.monthlyLimit,
    searchable: staff.searchable,
    adminRecommendationNumber: staff.adminRecommendationNumber,
  };

  return (
    <AdminEditStaffPage
      title="書店員登録"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onCancel}
      photos={photos}
      photoError={photoError}
      onAddPhoto={onAddPhoto}
      onRemovePhoto={onRemovePhoto}
      isUploading={isUploading}
      uploadingError={uploadingError}
      categories={categories}
      onChangeCategories={setCategories}
      categoryError={categoryError}
      error={mutationError || creatingError || updatingError}
      disabled={isCreating || isUpdating || isUploading}
    />
  );
};

export default StaffFormContainer;
