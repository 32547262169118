import React from 'react';
import classNames from 'classnames';

import CSSModule from './Select.module.scss';

export interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  id?: string;
  name: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  options: Option[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  hasEmptyOption?: boolean;
  emptyOptionLabel?: string;
  className?: string;
}

const Select = React.forwardRef<HTMLSelectElement, Props>(
  ({ id, name, options, label, required, onChange, hasEmptyOption, emptyOptionLabel, className }, ref) => {
    const inputId = id || name;
    const classes = classNames(CSSModule.select, className, 'form-control');

    const emptyOption = {
      label: emptyOptionLabel || '',
      value: '',
    };

    return (
      <div>
        {label && (
          <label htmlFor={inputId} className={CSSModule.label}>
            {label}
            {required && <span className={CSSModule.required}>※</span>}
          </label>
        )}
        <select name={name} id={inputId} className={classes} onChange={onChange} ref={ref}>
          {hasEmptyOption && <option value={emptyOption.value}>{emptyOption.label}</option>}
          {options.map(({ label, value, disabled }) => (
            <option key={`${label}-${value}`} value={value} disabled={disabled}>
              {label}
            </option>
          ))}
        </select>
      </div>
    );
  },
);

export default Select;
