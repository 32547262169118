import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchStaffIntroduction, fetchStaffList, fetchStaffDetail } from '../actions/staffs';
import { Staff } from '../interfaces/staff';

export interface StaffState {
  isFetchingStaffs: boolean;
  fetchingStaffsError: Error | null;
  isFetchingStaffDetail: boolean;
  fetchingStaffDetailError: Error | null;
  staffs: Staff[];
  staffIntroduction: Staff[];
  staffCount: number;
  bookStoreCount: number;
  staff: Staff | null;
}

const initialState: StaffState = {
  isFetchingStaffs: false,
  fetchingStaffsError: null,
  isFetchingStaffDetail: false,
  fetchingStaffDetailError: null,
  staffs: [],
  staffIntroduction: [],
  staffCount: 0,
  bookStoreCount: 0,
  staff: null,
};

export default reducerWithInitialState(initialState)
  .case(fetchStaffIntroduction.async.started, state => ({
    ...state,
    isFetchingStaffs: true,
  }))
  .case(fetchStaffIntroduction.async.failed, (state, { error }) => ({
    ...state,
    isFetchingStaffs: false,
    fetchingStaffsError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchStaffIntroduction.async.done, (state, { result: { staffs, bookStoreCount } }) => ({
    ...state,
    isFetchingStaffs: false,
    staffIntroduction: staffs,
    bookStoreCount,
  }))
  .case(fetchStaffList.async.started, state => ({
    ...state,
    isFetchingStaffs: true,
  }))
  .case(fetchStaffList.async.failed, (state, { error }) => ({
    ...state,
    isFetchingStaffs: false,
    fetchingStaffsError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchStaffList.async.done, (state, { result: { staffs, staffCount } }) => ({
    ...state,
    isFetchingStaffs: false,
    staffs,
    staffCount,
  }))
  .case(fetchStaffDetail.async.started, state => ({
    ...state,
    isFetchingStaffDetail: true,
    fetchingStaffDetailError: null,
  }))
  .case(fetchStaffDetail.async.failed, (state, { error }) => ({
    ...state,
    isFetchingStaffDetail: false,
    fetchingStaffDetailError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchStaffDetail.async.done, (state, { result: { staff } }) => ({
    ...state,
    isFetchingStaffDetail: false,
    staff,
  }));
