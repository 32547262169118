import React from 'react';
import classNames from 'classnames';

import CSSModule from './Button.module.scss';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  color?: 'primary' | 'default';
  size?: 'small' | 'medium';
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

const Button: React.FC<Props> = ({ type, disabled, onClick, color = 'primary', size = 'medium', children }) => {
  const onClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };
  const classes = classNames(CSSModule.button, {
    [CSSModule.primaryColor]: color === 'primary',
    [CSSModule.defaultColor]: color === 'default',
    [CSSModule.isSmall]: size === 'small',
  });

  return (
    <button type={type} disabled={disabled} className={classes} onClick={onClickButton}>
      {children}
    </button>
  );
};

export default Button;
