import React from 'react';
import classNames from 'classnames';

import CSSModule from './Input.module.scss';

interface Props {
  id?: string;
  name: string;
  value?: string;
  type?: string;
  color?: 'white' | 'gray';
  disabled?: boolean;
  label?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  ({ type, name, value, placeholder, disabled, label, id, required, onChange, color = 'gray' }, ref) => {
    const classes = classNames(CSSModule.input, 'form-control', {
      [CSSModule.gray]: color === 'gray',
    });
    const inputId = id || name;

    return (
      <>
        {label && (
          <label htmlFor={inputId} className={CSSModule.label}>
            {label}
            {required && <span className={CSSModule.required}>※</span>}
          </label>
        )}
        <input
          {...{ type, name, value, placeholder, disabled }}
          id={inputId}
          className={classes}
          onChange={onChange}
          required={required}
          ref={ref}
        />
      </>
    );
  },
);

export default Input;
