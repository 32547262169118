import React from 'react';
import classNames from 'classnames';

import { SelectedBook, BookRejection } from '../../interfaces/order';
import SelectedBookListItem from './SelectedBookListItem';

import CSSModule from './SelectedBookList.module.scss';

interface Props {
  selectedBooks: SelectedBook[];
  editable?: boolean;
  isOpen: boolean;
  toggleBookList: () => void;
  onApprove?: (bookId: string) => void;
  onReject?: (rejection: BookRejection) => void;
}

const SelectedBookList: React.FC<Props> = ({
  selectedBooks,
  isOpen,
  toggleBookList,
  editable,
  onApprove,
  onReject,
}) => {
  const titleClasses = classNames(CSSModule.title, { [CSSModule.isOpen]: isOpen });

  return (
    <div className={CSSModule.selectedBookList}>
      <div className={titleClasses} onClick={toggleBookList}>
        選書リスト
      </div>
      {isOpen && (
        <div>
          {selectedBooks.map((book, i) => (
            <SelectedBookListItem
              key={book.id}
              index={i + 1}
              selectedBook={book}
              editable={editable}
              onApprove={onApprove}
              onReject={onReject}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectedBookList;
