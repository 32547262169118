import React from 'react';
import { Link } from 'react-router-dom';

import CSSModule from './GiftBottomNavigation.module.scss';

const GiftBottomNavigation: React.FC = () => {
  return (
    <div className={CSSModule.GiftBottomNavigation}>
      <Link to="/gifts" className={CSSModule.GiftBottomNavigation__Button}>
        ギフトの送り方を変更する
      </Link>
      <Link to="/" className={CSSModule.GiftBottomNavigation__Button}>
        TOPに戻る
      </Link>
    </div>
  );
};

export default GiftBottomNavigation;
