import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

import API from './API';
import { FETCH_STAFF_LIST, FETCH_STAFF_INTRODUCTION, FETCH_STAFF_DETAIL } from './actionTypes';
import { StaffCategoryName, Staff } from '../interfaces/staff';
import { getStaffIntroductionQuery, getStaffListQuery, getStaffDetailQuery } from './queries/staffQueries';

const actionCreator = actionCreatorFactory();
const asyncActionCreator = asyncFactory(actionCreator);

interface FetchStaffsParams {
  categories?: StaffCategoryName[];
  keyword?: string;
  hasInventory?: boolean;
  page?: number;
  size?: number;
  randomize: number;
}

interface FetchStaffIntroductionResult {
  staffs: Staff[];
  bookStoreCount: number;
}

export const fetchStaffIntroduction = asyncActionCreator<FetchStaffsParams, FetchStaffIntroductionResult>(
  FETCH_STAFF_INTRODUCTION,
  params => API.query(getStaffIntroductionQuery, params),
);

interface FetchStaffsResult {
  staffs: Staff[];
  staffCount: number;
}

export const fetchStaffList = asyncActionCreator<FetchStaffsParams, FetchStaffsResult>(FETCH_STAFF_LIST, params =>
  API.query(getStaffListQuery, params),
);

interface FetchStaffDetailParams {
  id: string;
}

interface FetchStaffDetailResult {
  staff: Staff;
}

export const fetchStaffDetail = asyncActionCreator<FetchStaffDetailParams, FetchStaffDetailResult>(
  FETCH_STAFF_DETAIL,
  ({ id }) => API.query(getStaffDetailQuery, { id }),
);
