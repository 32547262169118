import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { parse, stringify } from 'query-string';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import has from 'lodash-es/has';

import { GenderLabel } from '../../interfaces/karte';
import { BookOrderStatusLabel } from '../../interfaces/order';
import useQuery from '../../hooks/common/useQuery';
import useMutation from '../../hooks/common/useMutation';
import {
  getAdminBookOrderListQuery,
  GetAdminBookOrderListParams,
  GetAdminBookOrderListResult,
  CreateKarteCsvResult,
  createKarteCsvQuery,
} from '../../actions/queries/orderQueries';
import AdminOrderListPage from '../../components/admin/orders/AdminOrderListPage';
import download from '../../utils/downloader';
import CsvGenerator from '../../utils/CsvGenerator';

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 20;

const isKarteCsvResult = (result: unknown): result is CreateKarteCsvResult => {
  return has(result, 'createKarteCsv');
};

const AdminOrderListContainer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = parse(location.search.substr(1));
  const page = Number(query.page) || DEFAULT_PAGE;

  const { isLoading, error, result, executeQuery } = useQuery<GetAdminBookOrderListParams, GetAdminBookOrderListResult>(
    getAdminBookOrderListQuery,
  );
  const {
    executeMutation: fetchAllBookOrderList,
    isLoading: isFetchingAllBookOrderList,
    error: fetchingAllBookOrderListError,
  } = useMutation<GetAdminBookOrderListParams, GetAdminBookOrderListResult>();

  const { executeMutation: createKarteCsv, isLoading: isCreatingKarteCsv } = useMutation<void, CreateKarteCsvResult>();

  useEffect(() => {
    executeQuery({
      page,
      size: DEFAULT_SIZE,
      sortBy: 'for_admin',
    });
  }, [executeQuery, page]);

  const onDownloadCSV = async () => {
    if (result) {
      const allResult = await fetchAllBookOrderList(getAdminBookOrderListQuery, {
        size: result.bookOrderCount,
        page: DEFAULT_PAGE,
      });
      if (allResult) {
        const content = new CsvGenerator({
          items: allResult.bookOrders,
          headers: [
            '注文ID',
            '会員名',
            '性別',
            '注文日',
            '書店名',
            '担当書店員',
            'メールアドレス',
            '電話番号',
            'ステータス',
            '配送番号',
          ],
          generateRow: item => [
            item.id,
            `${item.user ? item.user.name : '退会済ユーザー'}(ID: ${item.user ? item.user.id : '-'})`,
            `${item.karte.gender && GenderLabel[item.karte.gender]}`,
            item.orderedAt ? format(parseISO(item.orderedAt), 'yyyy/MM/dd') : '-',
            `${item.staff.bookStoreName}`,
            `${item.staff.name}(ID: ${item.staff.id})`,
            item.user ? item.user.email : '-',
            item.user ? item.user.tel : '-',
            BookOrderStatusLabel[item.status],
            item.shipmentCode || '-',
          ],
          sortItems: (item1, item2) => Number(item1.id) - Number(item2.id),
        }).call();
        download({
          filename: 'orders.csv',
          contentType: 'text/csv',
          content,
        });
      }
    }
  };

  const onDownloadKarteCSV = async () => {
    const result = await createKarteCsv(createKarteCsvQuery);

    if (isKarteCsvResult(result)) {
      window.open(result.createKarteCsv.uploadedUrl);
    }
  };

  const onClickRow = (bookOrderId: string) => {
    navigate(`/admin/orders/${bookOrderId}/edit`);
  };

  const onChangePage = (newPage: number) => {
    const nextLocation = `${location.pathname}?${stringify({ ...query, page: newPage })}`;
    navigate(nextLocation);
  };

  return (
    <AdminOrderListPage
      bookOrders={(result && result.bookOrders) || []}
      isLoading={isLoading}
      error={error || fetchingAllBookOrderListError}
      totalCount={(result && result.bookOrderCount) || 0}
      page={page}
      size={DEFAULT_SIZE}
      disableDownload={isFetchingAllBookOrderList}
      disableKarteDownload={isCreatingKarteCsv}
      onChangePage={onChangePage}
      onClickRow={onClickRow}
      onClickCsvDownloadButton={onDownloadCSV}
      onClickKarteCsvDownloadButton={onDownloadKarteCSV}
    />
  );
};

export default AdminOrderListContainer;
