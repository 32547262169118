export enum Gender {
  Male = 'male',
  Female = 'female',
  XGender = 'x_gender',
}

export const GenderLabel: { [key in Gender]: string } = {
  [Gender.Male]: '男性',
  [Gender.Female]: '女性',
  [Gender.XGender]: 'その他',
};

export interface KarteQuestionTemplate {
  id: string;
  questions: string[];
}

export interface Karte {
  id: string;
  gender: Gender | null;
  age: number | null;
  karteQuestionTemplate: KarteQuestionTemplate;
  answer1: string | null;
  answer2: string | null;
  answer3: string | null;
  answer4: string | null;
  answer5: string | null;
  answer6: string | null;
  answer7: string | null;
  answer8: string | null;
  answer9: string | null;
  answer10: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface KarteInput {
  gender: Gender | null;
  age: number | null;
  answer1: string | null;
  answer2: string | null;
  answer3: string | null;
  answer4: string | null;
  answer5: string | null;
  answer6: string | null;
  answer7: string | null;
  answer8: string | null;
  answer9: string | null;
  answer10: string | null;
  karteQuestionTemplateId: string;
}

export interface KarteTemplate extends Karte {
  orderNumber: number;
}

export const optionNames = ['カルテ①', 'カルテ②', 'カルテ③', 'カルテ④', 'カルテ⑤'];
