import React from 'react';

import { Karte, GenderLabel, KarteTemplate } from '../../interfaces/karte';

import CSSModule from './KarteDetail.module.scss';

interface Props {
  karte: Karte | KarteTemplate;
}

const KarteDetail: React.FC<Props> = ({ karte }) => {
  return (
    <div className={CSSModule.karteDetail}>
      <dl>
        <dt>性別</dt>
        <dd>{karte.gender ? GenderLabel[karte.gender] : '未選択'}</dd>
        <dt>年齢</dt>
        <dd>{karte.age || ' - '}歳</dd>
        {karte.karteQuestionTemplate.questions.map((question, i) => (
          <React.Fragment key={i}>
            <dt>
              Q{i + 1}.{question}
            </dt>
            <dd>{getKarteAnswerByIndex(karte, i) || '未記入'}</dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  );
};

export default KarteDetail;

const getKarteAnswerByIndex = (karte: Karte, index: number) => {
  const answers = [
    karte.answer1,
    karte.answer2,
    karte.answer3,
    karte.answer4,
    karte.answer5,
    karte.answer6,
    karte.answer7,
    karte.answer8,
    karte.answer9,
    karte.answer10,
  ];

  return answers[index];
};
