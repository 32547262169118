import { generateColumnQuery } from '.';
import { User, UserListItem } from '../../interfaces/user';
import { meColumns, userColumns } from './authQueries';

export const fetchAdminUsersQuery = `query fetchAdminUsers(
  $page: Int!,
  $size: Int!
) {
  adminUsers(
    page: $page,
    size: $size
  ) { ${generateColumnQuery(userColumns)} }
  adminUserCount
}`;

export interface FetchAdminUsersParams {
  page: number;
  size: number;
}

export interface FetchAdminUsersResult {
  adminUsers: UserListItem[];
  adminUserCount: number;
}

export const fetchAdminUserDetailQuery = `query fetchAdminUserDetail(
  $id: String!
) {
  adminUser(id: $id) { ${generateColumnQuery(meColumns)} }
  adminAccessToken(id: $id)
}`;

export interface FetchAdminDetailParams {
  id: string;
}

export interface FetchAdminDetailResult {
  adminUser: User;
  adminAccessToken: string;
}
