import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import Authenticated from '../common/Authenticated';
import AdminTemplate from '../../components/admin/common/AdminTemplate';
import useCurrentUser from 'src/hooks/store/useCurrentUser';

interface Props {
  children: React.ReactNode;
}

const AdminAuthenticated: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [user] = useCurrentUser();

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/mypage');
    }
  }, [user, navigate]);

  return (
    <AdminTemplate>
      <Authenticated>
        <div>{children}</div>
      </Authenticated>
    </AdminTemplate>
  );
};

export default AdminAuthenticated;
