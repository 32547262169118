import React from 'react';

import CSSModule from './ResetPasswordEmailSentPage.module.scss';
import Button from '../atoms/button/Button';

interface Props {
  email: string;
  onCancel: () => void;
}

const ResetPasswordEmailSentPage: React.FC<Props> = ({ email, onCancel }) => {
  return (
    <div className={CSSModule.resetPasswordEmailSentPage}>
      <h2 className={CSSModule.title}>パスワードの再設定</h2>
      <div className={CSSModule.description}>
        <p>下記メールアドレスにパスワード再設定用URLを送付します。</p>
      </div>
      <div className={CSSModule.email}>{email}</div>
      <div className={CSSModule.description}>
        <p>※30分が経過してもメールが届かない場合、再度お試しいただくかお問い合わせください。</p>
      </div>
      <div className={CSSModule.button}>
        <Button onClick={onCancel}>戻る</Button>
      </div>
    </div>
  );
};

export default ResetPasswordEmailSentPage;
