import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Authenticated from 'src/containers/common/Authenticated';
import GiftForm, { FormValues as GiftFormValues } from 'src/components/gifts/GiftForm';
import GiftDescription from 'src/components/gifts/GiftDescription';
import GiftBottomNavigation from 'src/components/gifts/GiftBottomNavigation';
import AppTemplate from 'src/components/templates/AppTemplate';
import useDraftGift from 'src/hooks/gifts/useDraftGift';

const NewGiftPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { isLoading, error, createDraftLetter } = useDraftGift();

  const onSubmit = async (values: GiftFormValues) => createDraftLetter({ ...values, shipmentType: 'email' });

  const initialValues = {
    email: searchParams.get('email') ?? '',
  };
  const serverError = error?.message ?? null;

  return (
    <AppTemplate>
      <Authenticated>
        <GiftDescription title="メールで送る">
          <p>
            送りたい相手のメールアドレス宛に、
            <br />
            ギフトコードを記載したメールを送ります。
          </p>
        </GiftDescription>
        <GiftForm initialValues={initialValues} disabled={isLoading} serverError={serverError} onSubmit={onSubmit} />
        <GiftBottomNavigation />
      </Authenticated>
    </AppTemplate>
  );
};

export default NewGiftPage;
