import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './reducers';
import Routes from './routes';
import Authentication from './containers/common/Authentication';
import FlashMessageContainer from './containers/common/FlashMessageContainer';
import Analytics from './routes/Analytics';
import ScrollToTop from './routes/ScrollToTop';

import './styles/global.scss';

const App: React.FC = () => (
  <Provider store={store}>
    <Authentication>
      <BrowserRouter>
        <Routes />
        <Analytics />
        <ScrollToTop />
      </BrowserRouter>
    </Authentication>
    <FlashMessageContainer />
  </Provider>
);

export default App;
