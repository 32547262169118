import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { StaffIntroduction } from '../../interfaces/staff';

import CSSModule from './StaffLink.module.scss';
import TextWithEllipsis from '../atoms/text/TextWithEllipsis';

interface Props {
  staff: StaffIntroduction;
}

const StaffLink: React.FC<Props> = ({ staff }) => {
  return (
    <Link to={`/staffs/${staff.id}`} className={classNames(CSSModule.staffLink, 'linkNavigator')}>
      <div className={CSSModule.staffPhoto}>
        {staff.photos[0] && <img src={staff.photos[0].src} alt={staff.name} className={CSSModule.photo} />}
      </div>
      <div className={CSSModule.staffContentWrapper}>
        <div className={CSSModule.staffContent}>
          <div className={CSSModule.bookStoreName}>
            <TextWithEllipsis text={staff.bookStoreName} />
          </div>
          <div className={CSSModule.staffName}>
            <TextWithEllipsis text={staff.name} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StaffLink;
