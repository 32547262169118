import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FetchAdminUsersParams, fetchAdminUsersQuery, FetchAdminUsersResult } from '../../actions/queries/userQueries';
import AdminUserListPage from '../../components/admin/users/AdminUserListPage';
import useQuery from '../../hooks/common/useQuery';

const DEFAULT_PAGE_POSITION = 1;
const DEFAULT_PAGE_SIZE = 20;

const AdminUserListContainer: React.FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(DEFAULT_PAGE_POSITION);
  const { isLoading, error, result, executeQuery } = useQuery<FetchAdminUsersParams, FetchAdminUsersResult>(
    fetchAdminUsersQuery,
  );

  useEffect(() => {
    executeQuery({
      page,
      size: DEFAULT_PAGE_SIZE,
    });
  }, [executeQuery, page]);

  const onChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const onClickRow = (userId: string) => {
    navigate(`/admin/users/${userId}`);
  };

  const users = result ? result.adminUsers : [];
  const totalCount = result ? result.adminUserCount : 0;

  return (
    <AdminUserListPage
      isLoading={isLoading}
      error={error}
      users={users}
      page={page}
      size={DEFAULT_PAGE_SIZE}
      totalCount={totalCount}
      onChangePage={onChangePage}
      onClickRow={onClickRow}
    />
  );
};

export default AdminUserListContainer;
