import React from 'react';
import classNames from 'classnames';

import UiStackTemplate from '../common/UiStackTemplate';
import DefaultProfileImage from '../../assets/auth/profile.png';

import CSSModule from './UserPhoto.module.scss';

interface Props {
  src: string | null;
  className?: string;
  isLoading?: boolean;
}

const UserPhoto: React.FC<Props> = ({ src, className, isLoading = false }) => {
  const source = src || DefaultProfileImage;
  const classes = classNames(CSSModule.photo, className);

  return (
    <UiStackTemplate isLoading={isLoading} error={null}>
      <img src={source} alt="Profile" className={classes} />
    </UiStackTemplate>
  );
};

export default UserPhoto;
