import React from 'react';

import CSSModule from './TextWithEllipsis.module.scss';

interface Props {
  text: string;
}

const TextWithEllipsis: React.FC<Props> = ({ text }) => <div className={CSSModule.text}>{text}</div>;

export default TextWithEllipsis;
