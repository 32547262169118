import { useState } from 'react';

import useMutation from './useMutation';
import FileLoader from '../../utils/fileLoader';
import { CdnDomain } from '../../app.constants';

interface GetPresignedUrlParams {
  extname: string;
}

interface GetPresignedUrlResult {
  presignedUrl: {
    presignedUrl: string;
    key: string;
  };
}

const getPresignedUrlQuery = `
  query getPresignedUrl($extname: String!) {
    presignedUrl(extname: $extname) {
      presignedUrl key
    }
  }
`;

interface MutationState {
  isUploading: boolean;
  uploadingError: Error | null;
}

const useFileUploader = () => {
  const { executeMutation } = useMutation<GetPresignedUrlParams, GetPresignedUrlResult>();

  const [state, setState] = useState<MutationState>({ isUploading: false, uploadingError: null });

  const upload = async (file: File) => {
    setState({ ...state, isUploading: true, uploadingError: null });

    try {
      const fileLoader = new FileLoader(file);
      const result = await executeMutation(getPresignedUrlQuery, { extname: fileLoader.extname });

      if (result) {
        const {
          presignedUrl: { presignedUrl, key },
        } = result;

        await fetch(presignedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Encoding': 'base64',
            'Content-Type': file.type,
          },
        });

        setState({ ...state, isUploading: false });

        return { key, src: `${CdnDomain}/${key}` };
      } else {
        throw new Error('Runtime Error.');
      }
    } catch (error) {
      const uploadingError = error instanceof Error ? error : new Error('unexpected error');
      setState({ ...state, isUploading: false, uploadingError });
      throw error;
    }
  };

  return { ...state, upload };
};

export default useFileUploader;
