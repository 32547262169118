import React from 'react';
import { Link } from 'react-router-dom';

import Input from '../atoms/form/Input';
import Button from '../atoms/button/Button';
import Alert from '../atoms/text/Alert';

import CSSModule from './LoginPage.module.scss';

interface Props {
  email: string;
  password: string;
  error: Error | null;
  disabled: boolean;
  onChange: (name: string, value: string) => void;
  onSubmit: () => void;
}

const LoginPage: React.FC<Props> = ({ email, password, error, disabled, onChange, onSubmit }) => (
  <form onSubmit={onSubmit}>
    <div className={CSSModule.form}>
      <Input
        label="メールアドレス"
        name="email"
        color="white"
        value={email}
        onChange={e => onChange('email', e.currentTarget.value)}
      />
      <Input
        label="パスワード"
        type="password"
        color="white"
        name="password"
        value={password}
        onChange={e => onChange('password', e.currentTarget.value)}
      />
      <Link to="/forgot-password" className={CSSModule.resetPasswordLink}>
        パスワードを忘れた方
      </Link>
    </div>
    {error && (
      <div className={CSSModule.error}>
        <Alert error={error} />
      </div>
    )}
    <div className={CSSModule.button}>
      <Button disabled={disabled} onClick={onSubmit}>
        ログイン
      </Button>
    </div>
  </form>
);

export default LoginPage;
