import React from 'react';
import { ResolveThunks, connect } from 'react-redux';

import { RootState } from '../../reducers';
import { login } from '../../actions/auth';
import { setFlashMessage as setFlashMessageAction } from '../../actions/common';
import LoginPage from '../../components/auth/LoginPage';

const mapStateToProps = (state: RootState) => ({
  isFetching: state.auth.isFetchingToken,
  error: state.auth.fetchingTokenError,
});

const mapDispatchToProps = {
  signIn: login.action,
  setFlashMessage: setFlashMessageAction,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

interface State {
  email: string;
  password: string;
}

class LoginContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public render() {
    const { email, password } = this.state;
    const { error } = this.props;

    return (
      <LoginPage
        email={email}
        password={password}
        error={error}
        disabled={this.disabled}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }

  private get disabled() {
    const { email, password } = this.state;
    const { isFetching } = this.props;
    return isFetching || !email || !password;
  }

  private onChange(name: string, value: string) {
    this.setState(state => ({
      ...state,
      [name]: value,
    }));
  }

  private async onSubmit() {
    const { signIn, setFlashMessage } = this.props;
    const { email, password } = this.state;
    await signIn({ email, password });
    setFlashMessage({ message: 'ログインに成功しました。' });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
