import React from 'react';
import ReactModal from 'react-modal';

import CSSModule from './Modal.module.scss';

ReactModal.setAppElement('#root');

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<Props> = ({ isOpen, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={CSSModule.overlay}
      className={{ base: CSSModule.body, afterOpen: CSSModule.afterOpen, beforeClose: CSSModule.beforeClose }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
