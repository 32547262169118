import React from 'react';
import classNames from 'classnames';

import { StaffCategoryName, StaffCategories, ParentStaffCategories } from '../../interfaces/staff';
import AppTemplate from '../templates/AppTemplate';
import ParentCategorySelector from './ParentCategorySelector';

import CSSModule from './SelectCategoriesPage.module.scss';

interface Props {
  categories: StaffCategoryName[];
  onChangeCategories: (categories: StaffCategoryName[]) => void;
  onSubmit: () => void;
  onClear: () => void;
}

const SelectCategoriesPage: React.FC<Props> = ({ categories, onChangeCategories, onSubmit, onClear }) => (
  <AppTemplate marginBottom="70px">
    <>
      {ParentStaffCategories.map(parentCategoryName => (
        <ParentCategorySelector
          key={parentCategoryName}
          parentCategoryName={parentCategoryName}
          categories={StaffCategories.filter(category => category.parent === parentCategoryName)}
          selectedCategories={categories}
          onChangeCategories={onChangeCategories}
        />
      ))}
      <div className={CSSModule.stickyButton}>
        <div className={CSSModule.content}>
          <div className={classNames(CSSModule.button, CSSModule.clearButton)} onClick={onClear}>
            クリア
          </div>
          <div className={classNames(CSSModule.button, CSSModule.submit)} onClick={onSubmit}>
            設定
          </div>
        </div>
      </div>
    </>
  </AppTemplate>
);

export default SelectCategoriesPage;
