import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import useMutation from 'src/hooks/common/useMutation';
import { setFlashMessage } from 'src/actions/common';
import AppTemplate from 'src/components/templates/AppTemplate';
import ForgotPasswordPage from 'src/components/auth/ForgotPasswordPage';
import ResetPasswordEmailSentPage from 'src/components/auth/ResetPasswordEmailSentPage';
import useCurrentUser from 'src/hooks/store/useCurrentUser';
import { FormValues } from 'src/components/auth/ForgotPasswordForm';

interface SendResetPasswordEmailResult {
  sendResetPasswordEmail: {
    error: string | null;
  };
}

const sendResetPasswordEmailQuery = `
  mutation sendResetPasswordEmail(
    $email: String!
  ) {
    sendResetPasswordEmail(input: {
      email: $email
    }) { error }
  }
`;

const ForgotPasswordContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [user] = useCurrentUser();
  const { executeMutation, isLoading, error } = useMutation<FormValues, SendResetPasswordEmailResult>();

  const onSubmit = async (values: FormValues) => {
    const result = await executeMutation(sendResetPasswordEmailQuery, values);
    if (result && result.sendResetPasswordEmail.error) {
      dispatch(setFlashMessage({ message: result.sendResetPasswordEmail.error }));
    } else {
      setEmail(values.email);
    }
  };

  const onCancel = () => {
    navigate('/');
  };

  useEffect(() => {
    if (user) {
      dispatch(setFlashMessage({ message: '既にログイン済みです。' }));
      navigate('/mypage');
    }
  }, [user, dispatch, navigate]);

  useEffect(() => {
    if (error) {
      dispatch(setFlashMessage({ message: error.message }));
    }
  }, [error, dispatch]);

  return (
    <AppTemplate>
      {email ? (
        <ResetPasswordEmailSentPage email={email} onCancel={onCancel} />
      ) : (
        <ForgotPasswordPage disabled={isLoading} onSubmit={onSubmit} />
      )}
    </AppTemplate>
  );
};

export default ForgotPasswordContainer;
