import React from 'react';

import { BookOrder, isDraftBookOrder, isListedBookOrder, BookRejection } from '../../interfaces/order';
import DraftOrderItem from './DraftOrderItem';
import ListedOrderItem from './ListedOrderItem';
import BasicOrderItem from './BasicOrderItem';

interface Props {
  bookOrder: BookOrder;
  isLoading: boolean;
  onSubmitReview: (bookOrderId: string, approvedIds: string[], rejections: BookRejection[]) => void;
}

const OrderListItem: React.FC<Props> = ({ bookOrder, onSubmitReview, isLoading }) => {
  if (isDraftBookOrder(bookOrder)) {
    return <DraftOrderItem bookOrder={bookOrder} />;
  }
  if (isListedBookOrder(bookOrder)) {
    return <ListedOrderItem bookOrder={bookOrder} onSubmitReview={onSubmitReview} isLoading={isLoading} />;
  }
  return <BasicOrderItem bookOrder={bookOrder} />;
};

export default OrderListItem;
