import React from 'react';

import { AdminStaff } from '../../../interfaces/staff';

interface Props {
  staffs: AdminStaff[];
  onClickRow: (staffId: string) => void;
}

const AdminStaffList: React.FC<Props> = ({ staffs, onClickRow }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>書店員ID</th>
          <th>書店員名</th>
          <th>書店名</th>
          <th>メールアドレス</th>
          <th>累計完了数</th>
          <th>今月の注文状況</th>
          <th>リスト未送信</th>
          <th>未配送</th>
          <th>詳細</th>
        </tr>
      </thead>
      <tbody>
        {staffs.map(staff => (
          <tr key={staff.id} onClick={() => onClickRow(staff.id)}>
            <td>{staff.id}</td>
            <td>{staff.name}</td>
            <td>{staff.bookStoreName}</td>
            <td>{staff.email}</td>
            <td>{staff.completedOrderCount}</td>
            <td>
              {staff.monthlyLimit - staff.monthlyCapacity}/{staff.monthlyLimit}
            </td>
            <td>{staff.unlistedOrderCount}</td>
            <td>{staff.undeliveredOrderCount}</td>
            <td onClick={e => e.stopPropagation()}>
              <a href={`/staffs/${staff.id}`} target="blank">
                詳細
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AdminStaffList;
