import React from 'react';
import { uniq } from 'lodash-es';

import {
  ParentStaffCategories,
  StaffCategories,
  StaffCategoryName,
  ParentStaffCategoryLabels,
} from '../../../interfaces/staff';
import StaffCategoryItem from './StaffCategoryItem';

import CSSModule from './StaffCategoryForm.module.scss';

interface Props {
  categories: StaffCategoryName[];
  onChangeCategories: (categories: StaffCategoryName[]) => void;
}

const StaffCategoryForm: React.FC<Props> = ({ categories, onChangeCategories }) => {
  const onChange = (category: StaffCategoryName) => {
    if (categories.includes(category)) {
      onChangeCategories(categories.filter(c => c !== category));
    } else {
      onChangeCategories(uniq([...categories, category]));
    }
  };

  return (
    <div className={CSSModule.staffCategoryForm}>
      {ParentStaffCategories.map(parentCategory => (
        <div key={parentCategory} className={CSSModule.parentCategoryItem}>
          <h4 className={CSSModule.subtitle}>{ParentStaffCategoryLabels[parentCategory]}</h4>
          <div className={CSSModule.categories}>
            {StaffCategories.filter(category => category.parent === parentCategory).map(category => (
              <StaffCategoryItem
                key={category.value}
                category={category}
                isSelected={categories.includes(category.value)}
                onClick={onChange}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StaffCategoryForm;
