interface DownloadParams {
  filename: string;
  contentType: string;
  content: string;
}

const download = ({ filename, content, contentType }: DownloadParams) => {
  const blob = new Blob([content], { type: contentType });
  const anchor = window.document.createElement('a');

  anchor.download = filename;
  anchor.href = window.URL.createObjectURL(blob);

  window.document.body.appendChild(anchor);
  anchor.click();
  window.document.body.removeChild(anchor);
};

export default download;
