import React from 'react';

import CSSModule from './SimpleDocument.module.scss';

interface Props {
  children: React.ReactNode;
}

const SimpleDocument: React.FC<Props> = ({ children }) => <div className={CSSModule.SimpleDocument}>{children}</div>;

export default SimpleDocument;
