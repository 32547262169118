import React from 'react';
import classNames from 'classnames';

import { ReactComponent as CheckboxIcon } from '../../../assets/system/checkbox.svg';

import CSSModule from './Checkbox.module.scss';

interface Props {
  name: string;
  id?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  children: React.ReactNode;
}

const Checkbox: React.FC<Props> = ({ name, id, onChange, value, children }) => {
  const inputId = id || name;
  const checkedClasses = classNames(CSSModule.check, { [CSSModule.isChecked]: value });

  return (
    <label htmlFor={inputId} className={CSSModule.checkbox}>
      <input
        type="checkbox"
        name={name}
        id={inputId}
        className={CSSModule.input}
        onChange={e => onChange(e.currentTarget.checked)}
        checked={value}
      />
      <div className={CSSModule.checkWrapper}>
        <CheckboxIcon className={checkedClasses} />
      </div>
      <div className={CSSModule.label}>
        <div>{children}</div>
      </div>
    </label>
  );
};

export default Checkbox;
