import React from 'react';

import SelectStaffIcon from '../../assets/welcome/select-staff.png';
import WriteKarteIcon from '../../assets/welcome/write-karte.png';
import ReceiveListIcon from '../../assets/welcome/receive-list.png';
import ReceiveBooksIcon from '../../assets/welcome/receive-books.png';

import CSSModule from './FlowDescription.module.scss';

const itemData = [
  {
    title: '書店員を選ぶ',
    description: 'カテゴリなどを選択して、自分に合う書店員さんを探しましょう！',
    src: SelectStaffIcon,
  },
  {
    title: '必要事項を記入',
    description: 'カルテにある質問に答えます。あまり考え込まず、直感で答えるのがコツです。',
    src: WriteKarteIcon,
  },
  {
    title: '選書リストが届く',
    description: '今のあなたにぴったりの本のリストが届きます。承認をします。',
    src: ReceiveListIcon,
  },
  {
    title: '本がお手元に届く',
    description: 'お手元に本が届きます。書店員さんに感想を送ってあげましょう！',
    src: ReceiveBooksIcon,
  },
];

const FlowDescription: React.FC = () => (
  <div className={CSSModule.FlowDescription}>
    <h2 className={CSSModule.FlowDescription__Title}>選書してもらう</h2>
    <p className={CSSModule.FlowDescription__Description}>受け取りまでの{itemData.length}つの流れ</p>
    <div className={CSSModule.FlowDescription__FlowList}>
      {itemData.map((item, i) => (
        <div key={i} className={CSSModule.FlowDescription__ListItem}>
          <h3 className={CSSModule.FlowDescription__ItemTitle}>
            <span className={CSSModule.FlowDescription__ListItemIndex}>{i + 1}</span>
            {item.title}
          </h3>
          <div>{item.description}</div>
          <img src={item.src} alt={item.title} className={CSSModule.FlowDescription__Image} />
        </div>
      ))}
    </div>
  </div>
);

export default FlowDescription;
