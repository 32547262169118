import React from 'react';
import classNames from 'classnames';

import { User } from '../../interfaces/user';
import UserPhoto from '../users/UserPhoto';

import CSSModule from './ProfilePanel.module.scss';
import { Link } from 'react-router-dom';
import TextWithEllipsis from '../atoms/text/TextWithEllipsis';
import MenuLink from './MenuLink';

interface Props {
  user: User;
}

const ProfilePanel: React.FC<Props> = ({ user }) => {
  const linkClasses = classNames(CSSModule.panelHeader, 'linkNavigator', 'clearfix');

  return (
    <div className={CSSModule.profilePanel}>
      <Link to="/mypage/profile/menu" className={linkClasses}>
        <div className={CSSModule.userPhoto}>
          <UserPhoto src={user.photo} />
        </div>
        <div className={CSSModule.userContent}>
          <div className={CSSModule.userProfileWrapper}>
            <div className={CSSModule.userProfile}>
              <h3 className={CSSModule.userName}>
                <TextWithEllipsis text={`${user.name}さん`} />
              </h3>
              <div className={CSSModule.userLink}>アカウント情報</div>
            </div>
          </div>
        </div>
      </Link>
      <MenuLink label="マイカルテを編集" to="/mypage/karte-templates" />
      <MenuLink label="ギフトを贈る" to="/mypage/gifts/new" />
      <MenuLink label="ギフトをリクエストする" to="/mypage/gifts/request" />
    </div>
  );
};

export default ProfilePanel;
