import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Input from '../atoms/form/Input';
import Button from '../atoms/button/Button';
import PriceConfirmation from '../payment/PriceConfirmation';
import TextArea from '../atoms/form/TextArea';
import Alert from '../atoms/text/Alert';

import CSSModule from './PostCardGiftForm.module.scss';

export interface FormValues {
  recipientName: string;
  recipientPostalCode: string;
  recipientAddress: string;
}

interface Props {
  disabled: boolean;
  serverError: string | null;
  onSubmit: (values: FormValues) => void;
}

const PostCardGiftForm: React.FC<Props> = ({ disabled, serverError, onSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={CSSModule.PostCardGiftForm}>
      <Input
        {...register('recipientName', { required: '送り先名は必須です。' })}
        label="送り先名"
        placeholder="例）本田　太郎"
      />
      {errors.recipientName && <Alert message={errors.recipientName.message} />}
      <Input
        {...register('recipientPostalCode', {
          required: '送り先郵便番号は必須です。',
          pattern: {
            value: /^\d{7}$/,
            message: '送り先郵便番号は半角英数7桁（ハイフンなし）でご記入ください。',
          },
        })}
        label="送り先郵便番号（ハイフンなしでご記入ください）"
        placeholder="例）1000001"
      />
      {errors.recipientPostalCode && <Alert message={errors.recipientPostalCode.message} />}
      <TextArea
        {...register('recipientAddress', { required: '送り先住所は必須です。' })}
        label="送り先住所"
        placeholder={`例）\n東京都千代田区神田神保町3-3\n神保町SFⅢビル4F`}
      />
      {errors.recipientAddress && <Alert message={errors.recipientAddress.message} />}
      <div className={CSSModule.PostCardGiftForm__Price}>
        <PriceConfirmation />
      </div>
      {serverError && <Alert message={serverError} />}
      <div className={CSSModule.PostCardGiftForm__Button}>
        <Button color="primary" disabled={disabled}>
          決済を行う
        </Button>
        <Link to="/mypage/profile" className={CSSModule.PostCardGiftForm__CardRegistrationLink}>
          カード情報の登録・変更
        </Link>
      </div>
    </form>
  );
};

export default PostCardGiftForm;
