import { combineReducers, legacy_createStore as createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import common, { CommonState } from './common';
import auth, { AuthState } from './auth';
import staffs, { StaffState } from './staffs';
import orders, { OrderState } from './orders';
import kartes, { KarteState } from './kartes';

export interface RootState {
  common: CommonState;
  auth: AuthState;
  staffs: StaffState;
  orders: OrderState;
  kartes: KarteState;
}

const reducer = combineReducers<RootState>({
  common,
  auth,
  staffs,
  orders,
  kartes,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
