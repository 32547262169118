import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { stringify, parse } from 'query-string';

import { RootState } from '../../reducers';
import { ResolveThunks, connect } from 'react-redux';
import { fetchBookOrders, fetchNotifiableOrderCount, reviewSelectedBooks } from '../../actions/orders';
import UiStackTemplate from '../../components/common/UiStackTemplate';
import OrderListPage from '../../components/orders/OrderListPage';
import { BookRejection, BookOrderStatus } from '../../interfaces/order';
import { setFlashMessage as setFlashMessageAction } from '../../actions/common';
import useCurrentUser from '../../hooks/store/useCurrentUser';

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 5;

const mapStateToProps = (state: RootState) => ({
  bookOrders: state.orders.bookOrders,
  totalCount: state.orders.totalCount,
  notificationCount: state.orders.notifiableOrderCount,
  isLoading: state.orders.isFetchingBookOrders,
  error: state.orders.fetchingBookOrdersError || state.orders.submittingReviewError,
  loadingOrderId: state.orders.submittingReviewOrderId,
});

const mapDispatchToProps = {
  fetchOrders: fetchBookOrders.action,
  fetchNotificationCount: fetchNotifiableOrderCount.action,
  submitReview: reviewSelectedBooks.action,
  setFlashMessage: setFlashMessageAction,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

const OrderListContainer: React.FC<Props> = ({
  bookOrders,
  totalCount,
  notificationCount,
  isLoading,
  error,
  loadingOrderId,
  fetchOrders,
  fetchNotificationCount,
  submitReview,
  setFlashMessage,
}) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const query = parse(search);
  const page = Number(query.page || DEFAULT_PAGE);
  const [user] = useCurrentUser();

  const onSubmitReview = async (bookOrderId: string, approvedBookIds: string[], rejections: BookRejection[]) => {
    const { status } = await submitReview({ bookOrderId, approvedBookIds, rejections });

    if (user) {
      await fetchOrders({ userId: user.id, page, size: DEFAULT_SIZE });
    }

    setFlashMessage({
      message: status === BookOrderStatus.Approved ? '選書リストを承認しました。' : 'フィードバックを送信しました。',
    });
  };

  const onChangePage = (newPage: number) => {
    const nextLocation = `${pathname}?${stringify({ page: newPage })}`;
    navigate(nextLocation);
  };

  useEffect(() => {
    if (user) {
      fetchOrders({ userId: user.id, page, size: DEFAULT_SIZE });
    }
  }, [fetchOrders, user, page]);

  useEffect(() => {
    if (user) {
      fetchNotificationCount({ userId: user.id });
    }
  }, [fetchNotificationCount, user]);

  return (
    <UiStackTemplate isLoading={isLoading} error={error}>
      <OrderListPage
        bookOrders={bookOrders}
        notificationCount={notificationCount}
        onSubmitReview={onSubmitReview}
        loadingOrderId={loadingOrderId}
        totalCount={totalCount}
        page={page}
        size={DEFAULT_SIZE}
        onChangePage={onChangePage}
      />
    </UiStackTemplate>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderListContainer);
