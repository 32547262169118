import React from 'react';

import Authenticated from 'src/containers/common/Authenticated';
import AppTemplate from 'src/components/templates/AppTemplate';
import GiftDescription from 'src/components/gifts/GiftDescription';
import GiftBottomNavigation from 'src/components/gifts/GiftBottomNavigation';
import ByHandsGiftForm from 'src/components/gifts/ByHandsGiftForm';
import useCurrentUser from 'src/hooks/store/useCurrentUser';
import useDraftGift from 'src/hooks/gifts/useDraftGift';

const GiftByHandsPage: React.FC = () => {
  const { isLoading, error, createDraftLetter } = useDraftGift();
  const [currentUser] = useCurrentUser();

  const serverError = error ? error.message : null;

  const onSubmit = async () => {
    if (!currentUser) return;

    const postalCode = currentUser.shippingPostalCode || currentUser.postalCode;
    const address = currentUser.shippingAddress || currentUser.address;

    await createDraftLetter({ shipmentType: 'self', postalCode, address });
  };

  return (
    <AppTemplate>
      <Authenticated>
        <GiftDescription title="直接手渡しする">
          <p>
            ギフトコードが印刷されたカードを
            <br />
            送り主のご住所へ郵送します。
            <br />
            受け取り後、相手にカードを直接手渡しできます。
          </p>
        </GiftDescription>
        {currentUser && (
          <ByHandsGiftForm
            postalCode={currentUser.shippingPostalCode || currentUser.postalCode}
            address={currentUser.shippingAddress || currentUser.address}
            disabled={isLoading}
            serverError={serverError}
            onSubmit={onSubmit}
          />
        )}
        <GiftBottomNavigation />
      </Authenticated>
    </AppTemplate>
  );
};

export default GiftByHandsPage;
