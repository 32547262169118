import React, { useState } from 'react';
import classNames from 'classnames';

import { KarteTemplate, optionNames } from '../../interfaces/karte';
import Modal from '../common/Modal';
import Button from '../atoms/button/Button';

import CSSModule from './KarteTemplateRegistrationModal.module.scss';

interface Props {
  isOpen: boolean;
  templates: KarteTemplate[];
  onSubmit: (selectedIndex: number | null) => void;
}

const KarteTemplateRegistrationModal: React.FC<Props> = ({ isOpen, onSubmit }) => {
  const [selectedOption, selectOption] = useState<number | null>(null);

  const onSubmitTemplate = () => {
    onSubmit(selectedOption);
  };

  return (
    <Modal isOpen={isOpen}>
      <div>
        <h3 className={CSSModule.title}>
          カルテをマイページに
          <br />
          登録しますか？
        </h3>
        <div className={CSSModule.description}>
          登録しておくと次回から引用することができます。
          <br />
          登録したカルテはマイページから編集できます。
        </div>
        <div className={CSSModule.karteOptions}>
          {optionNames.map((name, i) => (
            <div
              key={name}
              className={classNames(CSSModule.karteOption, { [CSSModule.active]: selectedOption === i })}
              onClick={() => selectOption(i)}
            >
              {name}
            </div>
          ))}
          <div
            className={classNames(CSSModule.karteOption, { [CSSModule.active]: selectedOption === null })}
            onClick={() => selectOption(null)}
          >
            保存しない
          </div>
        </div>
        <Button onClick={onSubmitTemplate}>送信</Button>
      </div>
    </Modal>
  );
};

export default KarteTemplateRegistrationModal;
