import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import useQuery from '../../hooks/common/useQuery';
import {
  GetBookOrderDetailParams,
  GetBookOrderDetailResult,
  getBookOrderDetailQuery,
  RegisterShipmentCodeParams,
  RegisterShipmentCodeResult,
  registerShipmentCodeQuery,
} from '../../actions/queries/orderQueries';
import UiStackTemplate from '../../components/common/UiStackTemplate';
import EditShipmentPage from '../../components/admin/orders/EditShipmentPage';
import useMutation from '../../hooks/common/useMutation';
import useToast from '../../hooks/store/useToast';

const AdminEditShipmentContainer: React.FC = () => {
  const navigate = useNavigate();
  const { bookOrderId } = useParams<{ bookOrderId: string }>();
  const {
    executeMutation: registerShipmentCode,
    isLoading: isRegistering,
    error: registeringError,
  } = useMutation<RegisterShipmentCodeParams, RegisterShipmentCodeResult>();
  const { isLoading, error, result, executeQuery } = useQuery<GetBookOrderDetailParams, GetBookOrderDetailResult>(
    getBookOrderDetailQuery,
  );
  const showToast = useToast();

  const onSubmit = async (shipmentCode: string) => {
    const mutationResult = await registerShipmentCode(registerShipmentCodeQuery, {
      bookOrderId: bookOrderId!,
      shipmentCode,
    });

    if (mutationResult && mutationResult.registerShipmentCode.error) {
      showToast(mutationResult.registerShipmentCode.error);
    } else {
      showToast('配送番号を登録しました。');
      navigate('/admin/orders');
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (bookOrderId) {
      executeQuery({
        bookOrderId,
      });
    }
  }, [executeQuery, bookOrderId]);

  useEffect(() => {
    if (registeringError) {
      showToast(registeringError.message);
    }
  }, [registeringError, showToast]);

  return (
    <UiStackTemplate isLoading={isLoading} error={error}>
      {result && (
        <EditShipmentPage
          disabled={isRegistering}
          bookOrder={result.bookOrder}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
    </UiStackTemplate>
  );
};

export default AdminEditShipmentContainer;
