import React from 'react';

import AppTemplate from 'src/components/templates/AppTemplate';
import Authenticated from 'src/containers/common/Authenticated';
import useCurrentUser from 'src/hooks/store/useCurrentUser';
import PaymentDetail from 'src/components/payment/PaymentDetail';

const PaymentDetailPage: React.FC = () => {
  const [currentUser] = useCurrentUser();

  return (
    <AppTemplate>
      <Authenticated>{currentUser && <PaymentDetail user={currentUser} />}</Authenticated>
    </AppTemplate>
  );
};

export default PaymentDetailPage;
