import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import {
  FetchAdminDetailParams,
  FetchAdminDetailResult,
  fetchAdminUserDetailQuery,
} from '../../actions/queries/userQueries';
import AdminUserDetail from '../../components/admin/users/AdminUserDetail';
import UiStackTemplate from '../../components/common/UiStackTemplate';
import useQuery from '../../hooks/common/useQuery';
import { setToken } from '../../reducers/auth';

const AdminUserDetailContainer: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const { isLoading, error, result, executeQuery } = useQuery<FetchAdminDetailParams, FetchAdminDetailResult>(
    fetchAdminUserDetailQuery,
  );

  useEffect(() => {
    if (userId) {
      executeQuery({ id: userId });
    }
  }, [executeQuery, userId]);

  const forceLogin = () => {
    const token = result && result.adminAccessToken;
    setToken(token);
    window.location.assign('/mypage');
  };

  return (
    <UiStackTemplate isLoading={isLoading} error={error}>
      {result && <AdminUserDetail user={result.adminUser} onClickLogin={forceLogin} />}
    </UiStackTemplate>
  );
};

export default AdminUserDetailContainer;
