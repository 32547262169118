import React from 'react';

import { KarteTemplate, optionNames } from '../../interfaces/karte';
import KarteTemplateListItem from './KarteTemplateListItem';

interface Props {
  karteTemplates: KarteTemplate[];
}

const KarteTemplateList: React.FC<Props> = ({ karteTemplates }) => {
  return (
    <div>
      {optionNames.map((name, i) => (
        <KarteTemplateListItem
          key={name}
          name={name}
          index={i + 1}
          disabled={!karteTemplates.some(template => template.orderNumber === i)}
        />
      ))}
    </div>
  );
};

export default KarteTemplateList;
