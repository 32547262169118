import React from 'react';

import { AdminStaff } from 'src/interfaces/staff';
import AdminStaffList from 'src/components/admin/staffs/AdminStaffList';
import ListPageTemplate from 'src/components/admin/common/ListPageTemplate';
import Button from 'src/components/atoms/button/Button';

interface Props {
  staffs: AdminStaff[];
  isLoading: boolean;
  error: Error | null;
  page: number;
  size: number;
  totalCount: number;
  disableDownload: boolean;
  onClickCreateButton: () => void;
  onClickCsvDownloadButton: () => void;
  onClickRow: (staffId: string) => void;
  onChangePage: (page: number) => void;
}

const AdminStaffListPage: React.FC<Props> = ({
  staffs,
  isLoading,
  error,
  page,
  size,
  totalCount,
  disableDownload,
  onClickCreateButton,
  onClickCsvDownloadButton,
  onClickRow,
  onChangePage,
}) => {
  return (
    <ListPageTemplate
      title="書店員一覧"
      isLoading={isLoading}
      error={error}
      page={page}
      size={size}
      totalCount={totalCount}
      isEmpty={!!staffs && staffs.length === 0}
      leftSideButton={
        <Button color="default" onClick={onClickCreateButton}>
          新規登録
        </Button>
      }
      onChangePage={onChangePage}
      rightSideButton={
        <Button color="default" disabled={disableDownload} onClick={onClickCsvDownloadButton}>
          書店員一覧CSV出力
        </Button>
      }
    >
      <AdminStaffList staffs={staffs} onClickRow={onClickRow} />
    </ListPageTemplate>
  );
};

export default AdminStaffListPage;
