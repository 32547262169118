import React from 'react';
import { useForm } from 'react-hook-form';

import { PhotoData, StaffCategoryName } from '../../../interfaces/staff';
import Input from '../../atoms/form/Input';
import TextArea from '../../atoms/form/TextArea';
import Button from '../../atoms/button/Button';
import FileChangeButton from '../../atoms/button/FileChangeButton';
import Alert from '../../atoms/text/Alert';
import Select from '../../atoms/form/Select';
import Loading from '../../common/Loading';
import StaffCategoryForm from './StaffCategoryForm';

import CSSModule from './StaffForm.module.scss';

const MAX_PHOTOS_COUNT = 4;

export interface FormValues {
  bookStoreName: string;
  name: string;
  email: string;
  slogan: string;
  profile: string;
  monthlyLimit: number;
  searchable: 'true' | 'false';
  adminRecommendationNumber?: number;
}

interface Props {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  onAddPhoto: (file: File | null) => void;
  onRemovePhoto: (src: string) => void;
  photos: PhotoData[];
  photoError: Error | null;
  isUploading: boolean;
  uploadingError: Error | null;
  categories: StaffCategoryName[];
  onChangeCategories: (categories: StaffCategoryName[]) => void;
  categoryError: Error | null;
  error: Error | null;
  disabled: boolean;
}

const StaffForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onCancel,
  onAddPhoto,
  onRemovePhoto,
  photos,
  photoError,
  isUploading,
  categories,
  onChangeCategories,
  categoryError,
  error,
  disabled,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({ defaultValues: initialValues });

  const searchableOptions = [
    { label: '検索可', value: 'true' },
    { label: '検索不可', value: 'false' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={CSSModule.staffForm}>
      <div className="form-group">
        <Input {...register('bookStoreName', { required: '書店名をご記入ください。' })} label="書店名" />
        {errors.bookStoreName && <Alert message={errors.bookStoreName.message} />}
      </div>
      <div className="form-group">
        <Input {...register('name', { required: '書店員名をご記入ください。' })} label="書店員名" />
        {errors.name && <Alert message={errors.name.message} />}
      </div>
      <div className="form-group">
        <Input {...register('email', { required: 'メールアドレスをご記入ください。' })} label="メールアドレス" />
        {errors.email && <Alert message={errors.email.message} />}
      </div>

      <div className={CSSModule.editFile}>
        <h2 className={CSSModule.title}>画像を編集</h2>
        {photos.length > 0 && (
          <div className={CSSModule.editFilePreview}>
            {photos.map(photo => (
              <div key={photo.src} className={CSSModule.editFileItem}>
                <img src={photo.src} alt="" className={CSSModule.editFileImage} />
                <div className={CSSModule.removePhotoButton}>
                  <Button type="button" color="default" onClick={() => onRemovePhoto(photo.src)}>
                    写真を削除
                  </Button>
                </div>
              </div>
            ))}
            {isUploading && (
              <div className={CSSModule.editFileItem}>
                <Loading />
              </div>
            )}
          </div>
        )}
        {photoError && <Alert error={photoError} />}
        {photos.length < MAX_PHOTOS_COUNT && (
          <div className={CSSModule.fileButton}>
            <FileChangeButton name="photo" onChange={onAddPhoto}>
              画像を追加
            </FileChangeButton>
          </div>
        )}
      </div>

      <div className="form-group">
        <Input {...register('slogan', { required: 'キャッチコピーをご記入ください。' })} label="キャッチコピー" />
        {errors.slogan && <Alert message={errors.slogan.message} />}
      </div>
      <div className="form-group">
        <TextArea {...register('profile', { required: 'プロフィールをご記入ください。' })} label="プロフィール" />
        {errors.profile && <Alert message={errors.profile.message} />}
      </div>
      <div className="form-group">
        <Input
          type="number"
          {...register('monthlyLimit', {
            required: '1ヶ月の受付数をご記入ください。',
            min: { value: 0, message: '1ヶ月の受付数は0以上の値をご入力ください。' },
          })}
          onChange={e => {
            setValue('monthlyLimit', Number(e.currentTarget.value));
          }}
          label="1ヶ月の受付数"
        />
        {errors.monthlyLimit && <Alert message={errors.monthlyLimit.message} />}
      </div>

      <div className={CSSModule.categoryForm}>
        <h2 className={CSSModule.title}>カテゴリを編集</h2>
        <StaffCategoryForm categories={categories} onChangeCategories={onChangeCategories} />
      </div>

      <div className={CSSModule.categoryForm}>
        <h2 className={CSSModule.title}>検索設定</h2>
        <Select label="検索可否" options={searchableOptions} {...register('searchable', { required: true })} />
      </div>

      <div className={CSSModule.categoryForm}>
        <Input
          type="number"
          {...register('adminRecommendationNumber', {
            min: { value: 0, message: '表示優先順位は0以上の値をご入力ください。' },
          })}
          onChange={e => {
            setValue('adminRecommendationNumber', Number(e.currentTarget.value) || undefined);
          }}
          label="表示優先順位"
        />
        <div className={CSSModule.recommendationDescription}>数字が大きいほど優先順位が高くなります。</div>
        {errors.adminRecommendationNumber && <Alert message={errors.adminRecommendationNumber.message} />}
      </div>

      {photoError && <Alert error={photoError} />}
      {categoryError && <Alert error={categoryError} />}
      {error && <Alert error={error} />}

      <div className={CSSModule.buttons}>
        <div className={CSSModule.button}>
          <Button type="button" color="default" onClick={onCancel}>
            キャンセル
          </Button>
        </div>
        <div className={CSSModule.button}>
          <Button disabled={disabled}>送信</Button>
        </div>
      </div>
    </form>
  );
};

export default StaffForm;
