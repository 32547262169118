import React from 'react';
import { Link } from 'react-router-dom';

import CSSModule from './Breadcrumbs.module.scss';
import classNames from 'classnames';

interface Page {
  label: string;
  link?: string;
}

interface Props {
  pages: Page[];
}

const Breadcrumbs: React.FC<Props> = ({ pages }) => (
  <div className={CSSModule.breadcrumbList}>
    {pages.map(page =>
      page.link ? (
        <Link
          key={page.label}
          to={page.link}
          className={classNames(CSSModule.breadcrumbListItem, CSSModule.breadcrumbListItemLink)}
        >
          {page.label}
        </Link>
      ) : (
        <div key={page.label} className={CSSModule.breadcrumbListItem}>
          {page.label}
        </div>
      ),
    )}
  </div>
);

export default Breadcrumbs;
