import { reducerWithInitialState } from 'typescript-fsa-reducers';

import {
  fetchBookOrders,
  fetchBookOrderDetail,
  fetchDraftOrder,
  postBookOrder,
  fetchNotifiableOrderCount,
  reviewSelectedBooks,
} from '../actions/orders';
import { BookOrder } from '../interfaces/order';

export interface OrderState {
  bookOrders: BookOrder[];
  bookOrder: BookOrder | null;
  totalCount: number;
  notifiableOrderCount: number;
  isFetchingBookOrders: boolean;
  isFetchingBookOrderDetail: boolean;
  isInitialDraftOrderFetched: boolean;
  isPostingBookOrder: boolean;
  isFetchingNotifiableCount: boolean;
  submittingReviewOrderId: string | null;
  fetchingBookOrdersError: Error | null;
  fetchingBookOrderDetailError: Error | null;
  postingBookOrderError: string | null;
  fetchingNotifiableCountError: Error | null;
  submittingReviewError: Error | null;
}

const initialState: OrderState = {
  bookOrders: [],
  bookOrder: null,
  totalCount: 0,
  notifiableOrderCount: 0,
  isFetchingBookOrders: false,
  isFetchingBookOrderDetail: false,
  isInitialDraftOrderFetched: false,
  isPostingBookOrder: false,
  isFetchingNotifiableCount: false,
  submittingReviewOrderId: null,
  fetchingBookOrdersError: null,
  fetchingBookOrderDetailError: null,
  postingBookOrderError: null,
  fetchingNotifiableCountError: null,
  submittingReviewError: null,
};

export default reducerWithInitialState(initialState)
  .case(fetchBookOrders.async.started, state => ({
    ...state,
    bookOrder: null,
    isFetchingBookOrders: true,
    fetchingBookOrdersError: null,
  }))
  .case(fetchBookOrders.async.failed, (state, { error }) => ({
    ...state,
    isFetchingBookOrders: false,
    fetchingBookOrdersError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchBookOrders.async.done, (state, { result: { bookOrders, totalCount } }) => ({
    ...state,
    isFetchingBookOrders: false,
    bookOrders,
    totalCount,
  }))
  .case(fetchBookOrderDetail.async.started, state => ({
    ...state,
    bookOrder: null,
    isFetchingBookOrderDetail: true,
    fetchingBookOrderDetailError: null,
  }))
  .case(fetchBookOrderDetail.async.failed, (state, { error }) => ({
    ...state,
    isFetchingBookOrderDetail: false,
    fetchingBookOrderDetailError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchBookOrderDetail.async.done, (state, { result: { bookOrder } }) => ({
    ...state,
    isFetchingBookOrderDetail: false,
    bookOrder,
  }))
  .case(fetchDraftOrder.async.started, state => ({
    ...state,
    bookOrder: null,
    isFetchingBookOrderDetail: true,
    fetchingBookOrderDetailError: null,
  }))
  .case(fetchDraftOrder.async.failed, (state, { error }) => ({
    ...state,
    isFetchingBookOrderDetail: false,
    fetchingBookOrderDetailError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchDraftOrder.async.done, (state, { result: { bookOrder } }) => ({
    ...state,
    bookOrder,
    isFetchingBookOrderDetail: false,
    isInitialDraftOrderFetched: true,
  }))
  .case(postBookOrder.async.started, state => ({
    ...state,
    isPostingBookOrder: true,
    postingBookOrderError: null,
  }))
  .case(postBookOrder.async.failed, (state, { error }) => {
    const anyError = error as any;
    const graphQLError = anyError.graphQLErrors && anyError.graphQLErrors[0] && anyError.graphQLErrors[0].message;
    const mutationError = anyError.message;
    const postingBookOrderError = mutationError || graphQLError || null;

    return {
      ...state,
      isPostingBookOrder: false,
      postingBookOrderError,
    };
  })
  .case(postBookOrder.async.done, (state, { result: { bookOrder } }) => ({
    ...state,
    isPostingBookOrder: false,
    bookOrder,
  }))
  .case(fetchNotifiableOrderCount.async.started, state => ({
    ...state,
    isFetchingNotifiableCount: true,
    fetchingNotifiableCountError: null,
  }))
  .case(fetchNotifiableOrderCount.async.failed, (state, { error }) => ({
    ...state,
    isFetchingNotifiableCount: false,
    fetchingNotifiableCountError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchNotifiableOrderCount.async.done, (state, { result }) => ({
    ...state,
    isFetchingNotifiableCount: false,
    notifiableOrderCount: result,
  }))
  .case(reviewSelectedBooks.async.started, (state, { bookOrderId }) => ({
    ...state,
    submittingReviewOrderId: bookOrderId,
    submittingReviewError: null,
  }))
  .case(reviewSelectedBooks.async.failed, (state, { error }) => ({
    ...state,
    submittingReviewOrderId: null,
    submittingReviewError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(reviewSelectedBooks.async.done, state => ({
    ...state,
    submittingReviewOrderId: null,
  }));
