import React from 'react';

import CSSModule from './PriceConfirmation.module.scss';

const PriceConfirmation: React.FC = () => (
  <div className={CSSModule.PriceConfirmation}>
    <h3 className={CSSModule.PriceConfirmation__Title}>決済金額</h3>
    <div className={CSSModule.PriceConfirmation__Price}>12,500円</div>
    <div className={CSSModule.PriceConfirmation__Note}>※税＋送料・選書料を含みます</div>
  </div>
);

export default PriceConfirmation;
