import React from 'react';

import { SelectedBookFieldValue } from '../../../interfaces/order';
import Input from '../../atoms/form/Input';
import RemoveIcon from '../../../assets/system/remove.png';

import CSSModule from './SelectedBookField.module.scss';

interface Props {
  index: number;
  selectedBook: SelectedBookFieldValue;
  onChange: (selectedBook: SelectedBookFieldValue, index: number) => void;
  onRemove: (index: number) => void;
}

const SelectedBookField: React.FC<Props> = ({ index, selectedBook, onChange, onRemove }) => {
  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...selectedBook, title: e.currentTarget.value }, index);
  };
  const onChangeISBN = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...selectedBook, isbn: e.currentTarget.value }, index);
  };
  const onRemoveFields = () => {
    onRemove(index);
  };

  return (
    <div className={CSSModule.selectedBookField}>
      <div className={CSSModule.fields}>
        <div className={CSSModule.removeButtonWrapper}>
          <img src={RemoveIcon} alt="remove book" className={CSSModule.removeButton} onClick={onRemoveFields} />
        </div>
        <div className={CSSModule.field}>
          <Input
            name="title"
            id={`title${index}`}
            value={selectedBook.title}
            onChange={onChangeTitle}
            label="書籍名"
            placeholder="書籍名"
          />
        </div>
        <div className={CSSModule.field}>
          <Input
            name="isbn"
            id={`isbn${index}`}
            value={selectedBook.isbn}
            onChange={onChangeISBN}
            label="ISBN"
            placeholder="ISBN"
          />
        </div>
      </div>
    </div>
  );
};

export default SelectedBookField;
