import React from 'react';

import LoadingIcon from '../../assets/system/loading.gif';

import CSSModule from './Loading.module.scss';

const Loading: React.FC = () => (
  <div className={CSSModule.loading}>
    <img src={LoadingIcon} alt="Loading..." className={CSSModule.icon} />
  </div>
);

export default Loading;
