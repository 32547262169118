import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../reducers';
import Header from '../../components/common/Header';
import useCurrentUser from '../../hooks/store/useCurrentUser';

const HeaderContainer: React.FC = () => {
  const [user] = useCurrentUser();

  const { notificationCount } = useSelector((state: RootState) => ({
    notificationCount: state.orders.notifiableOrderCount,
  }));

  return <Header user={user} notificationCount={notificationCount} />;
};

export default HeaderContainer;
