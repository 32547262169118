import { generateColumnQuery } from '.';

export const karteQuestionTemplateColumns = ['id', 'questions'];

const karteBaseColumns = [
  'id',
  'gender',
  'age',
  { karteQuestionTemplate: karteQuestionTemplateColumns },
  'answer1',
  'answer2',
  'answer3',
  'answer4',
  'answer5',
  'answer6',
  'answer7',
  'answer8',
  'answer9',
  'answer10',
  'createdAt',
  'updatedAt',
];

export const karteColumns = [...karteBaseColumns];

export const karteTemplateColumns = [...karteBaseColumns, 'orderNumber'];

export const getKarteTemplatesQuery = `
  query getKarteTemplates(
    $userId: String!,
    $size: Int
  ) {
    karteTemplates(
      userId: $userId,
      size: $size
    ) { ${generateColumnQuery(karteTemplateColumns)} }
    defaultKarteQuestionTemplate {
      ${generateColumnQuery(karteQuestionTemplateColumns)}
    }
  }
`;

export const registerKarteTemplateQuery = `
  mutation registerKarteTemplate(
    $orderNumber: Int!,
    $karte: KarteInput!
  ) {
    registerKarteTemplate(input: {
      orderNumber: $orderNumber,
      karte: $karte
    }) {
      karteTemplates { ${generateColumnQuery(karteTemplateColumns)} }
    }
  }
`;
