import DevConfig from './development';
import StagingConfig from './staging';
import ProdConfig from './production';

const devHost = 'localhost:8000';
const stagingHost = 'stg.bookkarte.com';
const prodHost = 'bookkarte.com';

const config = {
  [devHost]: DevConfig,
  [stagingHost]: StagingConfig,
  [prodHost]: ProdConfig,
};

type AppHost = typeof devHost | typeof stagingHost | typeof prodHost;

const host = window.location.host as AppHost;

export default config[host];
