import React from 'react';

import OrderThanksPage from '../../components/orders/OrderThanksPage';
import useCurrentUser from '../../hooks/store/useCurrentUser';

const OrderThanksContainer: React.FC = () => {
  const [user] = useCurrentUser();

  return user && <OrderThanksPage user={user} />;
};

export default OrderThanksContainer;
