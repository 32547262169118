import React from 'react';

import { StaffCategoryName, StaffCategoryType } from '../../interfaces/staff';
import CheckedIcon from './CheckedIcon';

import CSSModule from './CategorySelector.module.scss';

interface Props {
  category: StaffCategoryType;
  isSelected: boolean;
  onChange: (category: StaffCategoryName) => void;
}

const CategorySelector: React.FC<Props> = ({ category, isSelected, onChange }) => (
  <div className={CSSModule.wrapper} onClick={() => onChange(category.value)}>
    <div className={CSSModule.content}>
      <span className={CSSModule.text}>{category.label}</span>
      <div className={CSSModule.icon}>
        <CheckedIcon checked={isSelected} />
      </div>
    </div>
  </div>
);

export default CategorySelector;
