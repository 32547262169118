import React from 'react';

import Authenticated from 'src/containers/common/Authenticated';
import GiftDescription from 'src/components/gifts/GiftDescription';
import GiftBottomNavigation from 'src/components/gifts/GiftBottomNavigation';
import PostCardGiftForm, { FormValues } from 'src/components/gifts/PostCardGiftForm';
import AppTemplate from 'src/components/templates/AppTemplate';
import useDraftGift from 'src/hooks/gifts/useDraftGift';

const GiftByPostCardPage: React.FC = () => {
  const { isLoading, error, createDraftLetter } = useDraftGift();
  const serverError = error ? error.message : null;

  const onSubmit = async (values: FormValues) => {
    await createDraftLetter({
      shipmentType: 'other',
      recipientName: values.recipientName,
      postalCode: values.recipientPostalCode,
      address: values.recipientAddress,
    });
  };

  return (
    <AppTemplate>
      <Authenticated>
        <GiftDescription title="郵送する">
          <p>
            ギフトコードが印刷されたカードを
            <br />
            送りたい相手のご住所に郵送します。
          </p>
        </GiftDescription>
        <PostCardGiftForm disabled={isLoading} serverError={serverError} onSubmit={onSubmit} />
        <GiftBottomNavigation />
      </Authenticated>
    </AppTemplate>
  );
};

export default GiftByPostCardPage;
