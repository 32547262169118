import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';

import { SelectedBookFieldValue, isRejectedBook } from '../../interfaces/order';
import {
  getAdminBookOrderDetailQuery,
  GetBookOrderDetailParams,
  GetBookOrderDetailResult,
  UpdateSelectedBooksParams,
  UpdateSelectedBooksResult,
  updateSelectedBooksQuery,
} from '../../actions/queries/orderQueries';
import useQuery from '../../hooks/common/useQuery';
import useMutation from '../../hooks/common/useMutation';
import useToast from '../../hooks/store/useToast';
import UiStackTemplate from '../../components/common/UiStackTemplate';
import EditOrderPage from '../../components/admin/orders/EditOrderPage';
import { IsbnRegexp } from '../../app.constants';

const AdminEditOrderContainer: React.FC = () => {
  const navigate = useNavigate();
  const { bookOrderId } = useParams<{ bookOrderId: string }>();
  const [selectedBooks, setSelectedBooks] = useState<SelectedBookFieldValue[]>([]);
  const [validationError, setValidationError] = useState<Error | null>(null);
  const showToast = useToast();

  const { isLoading, error, result, executeQuery } = useQuery<GetBookOrderDetailParams, GetBookOrderDetailResult>(
    getAdminBookOrderDetailQuery,
  );
  const {
    executeMutation: updateSelectedBooks,
    isLoading: isUpdating,
    error: updatingError,
  } = useMutation<UpdateSelectedBooksParams, UpdateSelectedBooksResult>();

  useEffect(() => {
    if (bookOrderId) {
      executeQuery({
        bookOrderId,
      });
    }
  }, [executeQuery, bookOrderId]);

  useEffect(() => {
    if (result) {
      setSelectedBooks(result.bookOrder.selectedBooks || []);
    }
  }, [result]);

  const onSubmit = async () => {
    setValidationError(null);

    if (selectedBooks.some(selectedBook => !selectedBook.title || !selectedBook.isbn)) {
      setValidationError(new Error('書籍名とISBNは必須です。'));
      return;
    }

    if (selectedBooks.some(isRejectedBook)) {
      setValidationError(new Error('拒否された書籍を削除してください。'));
      return;
    }

    if (selectedBooks.some(selectedBook => !selectedBook.isbn.match(IsbnRegexp))) {
      setValidationError(new Error('ISBNの形式が不正です。'));
      return;
    }

    if (window.confirm('選書リストを更新しますか？\n（ユーザーに通知が届きます。）')) {
      const mutationResult = await updateSelectedBooks(updateSelectedBooksQuery, {
        bookOrderId: bookOrderId!,
        selectedBooks: selectedBooks.map(({ id, title, isbn }) => ({ id, title, isbn })),
      });
      if (mutationResult && mutationResult.updateSelectedBooks.error) {
        setValidationError(new Error(mutationResult.updateSelectedBooks.error));
      } else {
        showToast('選書リストを更新しました。');
        navigate('/admin/orders');
      }
    }
  };

  const onCancel = () => {
    navigate('/admin/orders');
  };

  return (
    <UiStackTemplate isLoading={isLoading} error={error}>
      {result && (
        <EditOrderPage
          bookOrder={result.bookOrder}
          selectedBooks={selectedBooks}
          disabled={isUpdating}
          error={validationError || updatingError}
          onChangeBooks={setSelectedBooks}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
    </UiStackTemplate>
  );
};

export default AdminEditOrderContainer;
