import React from 'react';

import { SelectedBookFieldValue, isRejectedBook } from 'src/interfaces/order';
import RejectedBook from 'src/components/admin/orders/RejectedBook';
import SelectedBookField from 'src/components/admin/orders/SelectedBookField';
import Button from 'src/components/atoms/button/Button';
import Alert from 'src/components/atoms/text/Alert';

import CSSModule from './SelectedBooksForm.module.scss';

interface Props {
  selectedBooks: SelectedBookFieldValue[];
  disabled: boolean;
  error: Error | null;
  onChangeBooks: (selectedBooks: SelectedBookFieldValue[]) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const SelectedBooksForm: React.FC<Props> = ({ selectedBooks, error, onChangeBooks, onSubmit, onCancel }) => {
  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const onChangeBook = (selectedBook: SelectedBookFieldValue, index: number) => {
    const clonedBooks = [...selectedBooks];
    clonedBooks.splice(index, 1, selectedBook);
    onChangeBooks(clonedBooks);
  };

  const onRemoveBook = (index: number) => {
    onChangeBooks(selectedBooks.filter((_, i) => i !== index));
  };

  const onAddBook = () => {
    onChangeBooks([...selectedBooks, { title: '', isbn: '' }]);
  };

  return (
    <form onSubmit={onSubmitForm} className={CSSModule.selectedBooksForm}>
      <div className={CSSModule.addButtonWrapper}>
        <div className={CSSModule.addButton}>
          <Button type="button" onClick={onAddBook}>
            本を追加する
          </Button>
        </div>
      </div>
      {selectedBooks.length === 0 && <div>書籍が選択されていません。</div>}
      <div className={CSSModule.fields}>
        {selectedBooks.map((selectedBook, i) =>
          isRejectedBook(selectedBook) ? (
            <RejectedBook key={i} rejectedBook={selectedBook} index={i} onRemove={onRemoveBook} />
          ) : (
            <SelectedBookField
              key={i}
              index={i}
              selectedBook={selectedBook}
              onChange={onChangeBook}
              onRemove={onRemoveBook}
            />
          ),
        )}
      </div>
      {error && (
        <div className={CSSModule.error}>
          <Alert error={error} />
        </div>
      )}
      <div className={CSSModule.buttons}>
        <div className={CSSModule.button}>
          <Button type="button" color="default" onClick={onCancel}>
            キャンセル
          </Button>
        </div>
        <div className={CSSModule.button}>
          <Button type="submit" color="primary">
            送信する
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SelectedBooksForm;
