import React, { useState } from 'react';

import useCurrentUser from 'src/hooks/store/useCurrentUser';
import PaymentMethodSelect, { PaymentMethodType } from 'src/components/orders/PaymentMethodSelect';
import Button from 'src/components/atoms/button/Button';

import CSSModule from './OrderPaymentMethodPage.module.scss';

export interface PaymentMethod {
  paymentMethodType: PaymentMethodType;
  couponCode: string | null;
}

interface Props {
  staffId: string;
  onSubmit: (paymentMethod: PaymentMethod) => void;
  onCancel: () => void;
}

const OrderPaymentMethodPage: React.FC<Props> = ({ staffId, onSubmit, onCancel }) => {
  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType | null>(null);
  const [couponCode, setCouponCode] = useState<string>('');
  const [currentUser] = useCurrentUser();

  const onSelectPaymentMethodType = (type: PaymentMethodType) => {
    setPaymentMethodType(type);
  };

  const goNextPage = () => {
    if (paymentMethodType) {
      onSubmit({
        paymentMethodType,
        couponCode: couponCode || null,
      });
    }
  };

  const cardIsRegistered = !!(currentUser && currentUser.cardLast4);
  const submittable =
    (paymentMethodType === 'card' && cardIsRegistered) || (paymentMethodType === 'coupon' && !!couponCode);

  return (
    <div className={CSSModule.OrderPaymentMethodPage}>
      <PaymentMethodSelect
        staffId={staffId}
        selectedPaymentMethodType={paymentMethodType}
        onSelectPaymentMethodType={onSelectPaymentMethodType}
        couponCode={couponCode}
        onChangeCouponCode={setCouponCode}
        creditCard={{
          brand: currentUser?.cardBrand ?? null,
          last4: currentUser?.cardLast4 ?? null,
          expMonth: currentUser?.cardExpMonth ?? null,
          expYear: currentUser?.cardExpYear ?? null,
        }}
      />
      <div className={CSSModule.OrderPaymentMethodPage__Button}>
        <Button color="primary" disabled={!submittable} onClick={goNextPage}>
          次へ
        </Button>
      </div>
      <div className={CSSModule.OrderPaymentMethodPage__Button}>
        <Button color="default" onClick={onCancel}>
          戻る
        </Button>
      </div>
    </div>
  );
};

export default OrderPaymentMethodPage;
