import React from 'react';

import HeaderContainer from '../../containers/common/HeaderContainer';
import Footer from '../common/Footer';

import CSSModule from './AppTemplate.module.scss';

interface Props {
  marginBottom?: string;
  children: React.ReactNode;
}

const AppTemplate: React.FC<Props> = ({ marginBottom, children }) => (
  <div className={CSSModule.template}>
    <div>
      <HeaderContainer />
    </div>
    <div className={CSSModule.main}>{children}</div>
    <div className={CSSModule.footer} style={{ marginBottom }}>
      <Footer />
    </div>
  </div>
);

export default AppTemplate;
