export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE';

export const REGISTER = 'REGISTER';
export const UNREGISTER = 'UNREGISTER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FETCH_ME = 'FETCH_ME';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_URL_PRESIGNED_URL = 'FETCH_URL_PRESIGNED_URL';
export const UPLOAD_USER_PHOTO = 'UPLOAD_USER_PHOTO';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

export const FETCH_STAFF_INTRODUCTION = 'FETCH_STAFF_INTRODUCTION';
export const FETCH_STAFF_LIST = 'FETCH_STAFF_LIST';
export const FETCH_STAFF_DETAIL = 'FETCH_STAFF_DETAIL';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDER_DETAIL = 'FETCH_ORDER_DETAIL';
export const FETCH_DRAFT_ORDER = 'FETCH_DRAFT_ORDER';
export const FETCH_NOTIFIABLE_ORDER_COUNT = 'FETCH_NOTIFIABLE_ORDER_COUNT';
export const POST_ORDER = 'POST_ORDER';
export const REVIEW_SELECTED_BOOKS = 'REVIEW_SELECTED_BOOKS';

export const FETCH_KARTE_TEMPLATES = 'FETCH_KARTE_TEMPLATES';
export const REGISTER_KARTE_TEMPLATE = 'REGISTER_KARTE_TEMPLATE';
