import React from 'react';

import CSSModule from './TextWithLineBreak.module.scss';

interface Props {
  children: React.ReactNode;
}

const TextWithLineBreak: React.FC<Props> = ({ children }) => <div className={CSSModule.text}>{children}</div>;

export default TextWithLineBreak;
