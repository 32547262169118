import React from 'react';
import { useSwipeable, EventData } from 'react-swipeable';
import classNames from 'classnames';

import { Photo } from '../../interfaces/common';

import CSSModule from './StaffPhotoListItem.module.scss';

interface Props {
  photo: Photo;
  isActive: boolean;
  onSwipeRight: () => void;
  onSwipeLeft: () => void;
}

const StaffPhotoListItem: React.FC<Props> = ({ photo, isActive, onSwipeRight, onSwipeLeft }) => {
  const onSwipedRight = (e: EventData) => onSwipeRight();
  const onSwipedLeft = (e: EventData) => onSwipeLeft();
  const handlers = useSwipeable({ onSwipedRight, onSwipedLeft });

  const classes = classNames(CSSModule.photo, { [CSSModule.active]: isActive });

  return (
    <div {...handlers} className={CSSModule.staffPhoto}>
      <img src={photo.src} alt="Staff" className={classes} />
    </div>
  );
};

export default StaffPhotoListItem;
