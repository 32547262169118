import React from 'react';

import ForgotPasswordForm, { FormValues } from './ForgotPasswordForm';

import CSSModule from './ForgotPasswordPage.module.scss';

interface Props {
  disabled: boolean;
  onSubmit: (values: FormValues) => void;
}

const ForgotPasswordPage: React.FC<Props> = ({ disabled, onSubmit }) => {
  return (
    <div className={CSSModule.forgotPasswordPage}>
      <h2 className={CSSModule.title}>パスワードの再設定</h2>
      <p>パスワード再設定用のURLをお送りします。アカウントのメールアドレスをご入力ください。</p>
      <hr />
      <ForgotPasswordForm disabled={disabled} onSubmit={onSubmit} />
    </div>
  );
};

export default ForgotPasswordPage;
