import React from 'react';

import Loading from './Loading';

import CSSModule from './UiStackTemplate.module.scss';

interface Props {
  isLoading: boolean;
  error: Error | null;
  isEmpty?: boolean;
  emptyMessage?: string;
  children: React.ReactNode;
}

const UiStackTemplate: React.FC<Props> = ({ isLoading, error, isEmpty, emptyMessage, children }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <p>
        {error.name}: {error.message}
      </p>
    );
  }

  if (isEmpty) {
    return <div className={CSSModule.empty}>{emptyMessage || '表示できるアイテムがありません。'}</div>;
  }

  return <>{children}</>;
};

export default UiStackTemplate;
