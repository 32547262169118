import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { fetchKarteTemplates, registerKarteTemplate } from '../actions/kartes';
import { KarteQuestionTemplate, KarteTemplate } from '../interfaces/karte';

export interface KarteState {
  templates: KarteTemplate[];
  defaultKarteQuestionTemplate: KarteQuestionTemplate | null;
  isFetchingTemplates: boolean;
  isRegisteringTemplate: boolean;
  fetchingTemplatesError: Error | null;
  registeringTemplateError: Error | null;
}

const initialState: KarteState = {
  templates: [],
  defaultKarteQuestionTemplate: null,
  isFetchingTemplates: false,
  isRegisteringTemplate: false,
  fetchingTemplatesError: null,
  registeringTemplateError: null,
};

export default reducerWithInitialState(initialState)
  .case(fetchKarteTemplates.async.started, state => ({
    ...state,
    isFetchingTemplates: true,
    fetchingTemplatesError: null,
  }))
  .case(fetchKarteTemplates.async.failed, (state, { error }) => ({
    ...state,
    isFetchingTemplates: false,
    fetchingTemplatesError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchKarteTemplates.async.done, (state, { result: { karteTemplates, defaultKarteQuestionTemplate } }) => ({
    ...state,
    isFetchingTemplates: false,
    templates: karteTemplates,
    defaultKarteQuestionTemplate,
  }))
  .case(registerKarteTemplate.async.started, state => ({
    ...state,
    isRegisteringTemplate: true,
    registeringTemplateError: null,
  }))
  .case(registerKarteTemplate.async.failed, (state, { error }) => ({
    ...state,
    isRegisteringTemplate: false,
    registeringTemplateError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(registerKarteTemplate.async.done, (state, { result: { karteTemplates } }) => ({
    ...state,
    isRegisteringTemplate: false,
    templates: karteTemplates,
  }));
