import React from 'react';

import { BookOrder, BookRejection } from '../../interfaces/order';
import OrderListItem from './OrderListItem';

interface Props {
  bookOrders: BookOrder[];
  loadingOrderId: string | null;
  onSubmitReview: (bookOrderId: string, approvedIds: string[], rejections: BookRejection[]) => void;
}

const OrderList: React.FC<Props> = ({ bookOrders, onSubmitReview, loadingOrderId }) => (
  <div>
    {bookOrders.map(order => (
      <div key={order.id} className="my-3">
        <OrderListItem bookOrder={order} onSubmitReview={onSubmitReview} isLoading={loadingOrderId === order.id} />
      </div>
    ))}
  </div>
);

export default OrderList;
