import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import AppTemplate from '../templates/AppTemplate';
import MailIcon from '../../assets/welcome/mail.png';
import PostIcon from '../../assets/welcome/post.png';
import SendIcon from '../../assets/welcome/send.png';
import PassByEmailImage from '../../assets/welcome/pass-by-email.png.png';
import PassByPostCardImage from '../../assets/welcome/pass-by-post-card.png';
import PassByHandImage from '../../assets/welcome/pass-by-hand.png';

import CSSModule from './GiftsPage.module.scss';

const GiftsPage: React.FC = () => {
  const section1 = useRef<HTMLElement>(null);
  const section2 = useRef<HTMLElement>(null);
  const section3 = useRef<HTMLElement>(null);

  const moveToSection1 = () => {
    section1.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const moveToSection2 = () => {
    section2.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const moveToSection3 = () => {
    section3.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <AppTemplate>
      <div className={CSSModule.GiftsPage}>
        <h2 className={CSSModule.GiftsPage__Title}>ギフトを贈る</h2>
        <div className={CSSModule.GiftsPage__FirstDescription}>
          <p>
            ブックカルテの「<Link to="/">選書してもらう体験</Link>」を
            <br />
            ギフトとして贈ることができます。
          </p>
          <p>
            受け取った相手はブックカルテの
            <br />
            決済画面にて、お支払いの代わりに
            <br />
            発行されたギフトコードを入力すると、
            <br />
            選書を依頼できるようになります。
          </p>
        </div>
        <div className={CSSModule.GiftsPage__ThreeMethods}>
          <h3 className={CSSModule.GiftsPage__SubTitle}>
            ギフトコードは3つの方法で
            <br />
            送ることができます。
          </h3>
          <div className={CSSModule.GiftsPage__ThreeIcons}>
            <img src={MailIcon} onClick={moveToSection1} alt="メール" />
            <img src={PostIcon} onClick={moveToSection2} alt="郵送" />
            <img src={SendIcon} onClick={moveToSection3} alt="直接手渡し" />
          </div>
          <section ref={section1} className={CSSModule.GiftsPage__MethodDescription}>
            <h4 className={CSSModule.GiftsPage__MethodTitle}>1. メールで送る</h4>
            <p>
              送りたい相手のメールアドレス宛に、
              <br />
              ギフトコードを記載したメールを送ります。
            </p>
            <img src={PassByEmailImage} alt="メールでギフトコードを送る" className={CSSModule.GiftsPage__MethodImage} />
            <p>
              送りたい相手の
              <br />
              ・メールアドレス ・お名前 ・メッセージ を
              <br />
              フォームに入力することで送ることが
              <br />
              できるようになります。
            </p>
            <Link to="/mypage/gifts/new" className={CSSModule.GiftsPage__MethodLink}>
              メールで送る
            </Link>
          </section>
          <section ref={section2} className={CSSModule.GiftsPage__MethodDescription}>
            <h4 className={CSSModule.GiftsPage__MethodTitle}>2. 郵送する</h4>
            <p>
              ギフトコードの印刷されたカードを
              <br />
              送りたい相手のご住所へ郵送します。
            </p>
            <img src={PassByPostCardImage} alt="カードを郵送する" className={CSSModule.GiftsPage__MethodImage} />
            <p>
              送りたい相手の
              <br />
              ・お名前 ・住所 をフォームに入力すると、
              <br />
              郵送で送ることができます。
              <br />
              ※ご注文日から1週間ほどで発送いたします。
            </p>
            <Link to="/mypage/gifts/post-card" className={CSSModule.GiftsPage__MethodLink}>
              郵送する
            </Link>
          </section>
          <section ref={section3} className={CSSModule.GiftsPage__MethodDescription}>
            <h4 className={CSSModule.GiftsPage__MethodTitle}>3. 直接手渡しする</h4>
            <p>
              ギフトコードが印刷されたカードを
              <br />
              送り主のご住所へ郵送します。
              <br />
              受け取り後、相手にカードを
              <br />
              直接手渡しできます。
              <br />
              ※ご注文日から1週間ほどで発送いたします。
            </p>
            <img src={PassByHandImage} alt="プレゼントを手渡しする" className={CSSModule.GiftsPage__MethodImage} />
            <p>
              カードにメッセージを
              <br />
              記入することも可能です。
            </p>
            <Link to="/mypage/gifts/by-hands" className={CSSModule.GiftsPage__MethodLink}>
              直接手渡しする
            </Link>
          </section>
          <div>
            <Link to="/" className={CSSModule.GiftsPage__BackButton}>
              TOPに戻る
            </Link>
          </div>
        </div>
      </div>
    </AppTemplate>
  );
};

export default GiftsPage;
