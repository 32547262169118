import classNames from 'classnames';
import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { User } from '../../../interfaces/user';

import CSSModule from './AdminUserDetail.module.scss';
import Button from '../../atoms/button/Button';

interface Props {
  user: User;
  onClickLogin: () => void;
}

const AdminUserDetail: React.FC<Props> = ({ user, onClickLogin }) => {
  const tableClasses = classNames('table table-bordered', CSSModule.table);
  const registeredAt = format(parseISO(user.createdAt), 'yyyy/MM/dd hh:mm');

  return (
    <div className={CSSModule.adminUserDetail}>
      <table className={tableClasses}>
        <colgroup>
          <col className={CSSModule.col1} />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>ID</th>
            <td>{user.id}</td>
          </tr>
          <tr>
            <th>氏名</th>
            <td>{user.name}</td>
          </tr>
          <tr>
            <th>氏名（かな）</th>
            <td>{user.nameKana}</td>
          </tr>
          <tr>
            <th>メールアドレス</th>
            <td>{user.email}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{user.tel}</td>
          </tr>
          <tr>
            <th>住所</th>
            <td>
              <div>〒{user.postalCode}</div>
              <div>{user.address}</div>
            </td>
          </tr>
          {(user.shippingPostalCode || user.shippingAddress) && (
            <tr>
              <th>配送先住所</th>
              <td>
                <div>〒{user.shippingPostalCode || '-'}</div>
                <div>{user.shippingAddress}</div>
              </td>
            </tr>
          )}
          <tr>
            <th>登録日時</th>
            <td>{registeredAt}</td>
          </tr>
        </thead>
      </table>
      <div>
        <Button onClick={onClickLogin}>強制ログイン</Button>
      </div>
    </div>
  );
};

export default AdminUserDetail;
