import React from 'react';

import { RejectedSelectedBook } from '../../../interfaces/order';
import RemoveIcon from '../../../assets/system/remove.png';

import CSSModule from './RejectedBook.module.scss';

interface Props {
  rejectedBook: RejectedSelectedBook;
  index: number;
  onRemove: (index: number) => void;
}

const RejectedBook: React.FC<Props> = ({ rejectedBook, index, onRemove }) => {
  const onRemoveBook = () => onRemove(index);

  return (
    <div className={CSSModule.rejectedBook}>
      <div className={CSSModule.content}>
        <div className={CSSModule.removeButtonWrapper}>
          <img src={RemoveIcon} alt="remove" className={CSSModule.removeButton} onClick={onRemoveBook} />
        </div>
        <div className={CSSModule.bookContent}>
          <div className={CSSModule.basicInfo}>
            <div className={CSSModule.title}>{rejectedBook.title}</div>
            <div className={CSSModule.isbn}>{rejectedBook.isbn}</div>
          </div>
          <div className={CSSModule.reason}>{rejectedBook.rejectedReason}</div>
        </div>
      </div>
    </div>
  );
};

export default RejectedBook;
