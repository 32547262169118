import React, { useEffect } from 'react';
import { ResolveThunks, connect } from 'react-redux';

import useCurrentUser from '../../hooks/store/useCurrentUser';
import { RootState } from '../../reducers';
import AppLoading from '../../components/common/AppLoading';
import { fetchMe } from '../../actions/auth';
import { fetchNotifiableOrderCount } from '../../actions/orders';

const mapStateToProps = (state: RootState) => ({
  isLoadingApp: state.auth.token && !state.auth.user,
});

const mapDispatchToProps = {
  fetchMyProfile: fetchMe.action,
  fetchNotificationCount: fetchNotifiableOrderCount.action,
};

interface OwnProps {
  children: React.ReactNode;
}

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps> & OwnProps;

const Authentication: React.FC<Props> = ({ isLoadingApp, fetchMyProfile, fetchNotificationCount, children }) => {
  const [user] = useCurrentUser();

  useEffect(() => {
    if (isLoadingApp) {
      fetchMyProfile();
    }
  }, [isLoadingApp, fetchMyProfile]);

  useEffect(() => {
    if (user) {
      fetchNotificationCount({ userId: user.id });
    }
  }, [user, fetchNotificationCount]);

  if (isLoadingApp) {
    return <AppLoading />;
  }

  return <>{children}</>;
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
