interface Props<T> {
  headers: string[];
  items: T[];
  generateRow: (item: T) => string[];
  sortItems?: (item1: T, item2: T) => number;
}

class CsvGenerator<T> {
  private headers: string[];
  private items: T[];
  private generateRow: (item: T) => string[];
  private sortItems?: (item1: T, item2: T) => number;

  constructor({ headers, items, generateRow, sortItems }: Props<T>) {
    this.headers = headers;
    this.items = items;
    this.generateRow = generateRow;
    this.sortItems = sortItems;
  }

  public call() {
    return `${this.header}\n${this.body}`;
  }

  private get header() {
    return this.headers.map(header => this.escape(header)).join();
  }

  private get body() {
    const sortedItems = this.sortItems ? this.items.sort(this.sortItems) : this.items;
    const rows = sortedItems.map(item => this.generateRow(item).map(cell => this.escape(cell)));
    return rows.map(row => row.join()).join('\n');
  }

  private escape(text: string) {
    const escapedText = text.replace(/"/g, '\\"');
    return `"${escapedText}"`;
  }
}

export default CsvGenerator;
