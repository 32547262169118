import has from 'lodash-es/has';
import isEqual from 'lodash-es/isEqual';
import isNumber from 'lodash-es/isNumber';
import React, { useEffect, useMemo, useRef } from 'react';
import { FieldErrors, Path, useForm } from 'react-hook-form';

import { Karte, Gender, KarteTemplate, GenderLabel } from '../../interfaces/karte';
import Input from '../atoms/form/Input';
import TextArea from '../atoms/form/TextArea';
import Select from '../atoms/form/Select';
import Button from '../atoms/button/Button';
import Alert from '../atoms/text/Alert';

import CSSModule from './KarteForm.module.scss';

export type FormValues = {
  age: number;
  gender: Gender;
  answer1: string | null;
  answer2: string | null;
  answer3: string | null;
  answer4: string | null;
  answer5: string | null;
  answer6: string | null;
  answer7: string | null;
  answer8: string | null;
  answer9: string | null;
  answer10: string | null;
};

interface Props {
  karte: Karte | KarteTemplate | null;
  selectedTemplate?: KarteTemplate;
  questions: string[];
  disabled: boolean;
  error: Error | null;
  onSubmit: (values: FormValues) => void;
  onSave?: (values: FormValues) => void;
  onAutoSave?: (values: FormValues) => void;
}

const genderOptions = [
  {
    label: GenderLabel[Gender.Male],
    value: Gender.Male,
  },
  {
    label: GenderLabel[Gender.Female],
    value: Gender.Female,
  },
  {
    label: GenderLabel[Gender.XGender],
    value: Gender.XGender,
  },
];

const defaultFormValues: Partial<FormValues> = {
  answer1: '',
  answer2: '',
  answer3: '',
  answer4: '',
  answer5: '',
  answer6: '',
  answer7: '',
  answer8: '',
  answer9: '',
  answer10: '',
};

const getDefaultValues = (karte: Karte): Partial<FormValues> => ({
  age: isNumber(karte.age) ? karte.age : undefined,
  gender: karte.gender ?? undefined,
  answer1: karte.answer1,
  answer2: karte.answer2,
  answer3: karte.answer3,
  answer4: karte.answer4,
  answer5: karte.answer5,
  answer6: karte.answer6,
  answer7: karte.answer7,
  answer8: karte.answer8,
  answer9: karte.answer9,
  answer10: karte.answer10,
});

const KarteForm: React.FC<Props> = ({
  karte,
  selectedTemplate,
  questions,
  disabled,
  error,
  onSubmit,
  onSave,
  onAutoSave,
}) => {
  const defaultValues = useMemo(() => {
    return karte ? getDefaultValues(karte) : defaultFormValues;
  }, [karte]);
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useForm<FormValues>({ defaultValues });

  useEffect(() => {
    if (selectedTemplate) {
      Object.keys(selectedTemplate).forEach(key => {
        // must modify when defaultFormValues is changed
        const isKeyOfValue = (key: string): key is keyof FormValues =>
          key === 'age' || key === 'gender' || has(defaultFormValues, key);

        if (isKeyOfValue(key)) {
          setValue(key, selectedTemplate[key]);
        }
      });
    } else {
      reset();
    }
  }, [setValue, reset, selectedTemplate, defaultValues]);

  const prevValueRef = useRef<FormValues>();

  useEffect(() => {
    const autoSave = async () => {
      const values = getValues();
      if (!isEqual(values, prevValueRef.current)) {
        prevValueRef.current = values;
        onAutoSave?.(values);
      }
    };

    const timerId = setInterval(autoSave, 5000);

    return () => {
      clearInterval(timerId);
    };
  }, [getValues, onAutoSave]);

  const onSaveKarte = () => {
    onSave?.(getValues());
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <Select
            {...register('gender', { required: '性別をご入力ください。' })}
            options={genderOptions}
            label="性別"
            required
            hasEmptyOption
            emptyOptionLabel=""
          />
          {errors.gender && <Alert message={errors.gender.message} />}
        </div>
        <div className="form-group">
          <Input
            {...register('age', {
              required: '年齢をご入力ください。',
              min: { value: 0, message: '年齢は0以上の値をご入力ください。' },
              max: { value: 120, message: '年齢は120以下の値をご入力ください。' },
            })}
            type="number"
            label="年齢"
            required
          />
          {errors.age && <Alert message={errors.age.message} />}
        </div>
        {questions.map((question, i) => (
          <div key={i} className="form-group">
            <TextArea
              {...register(`answer${i + 1}` as Path<FormValues>, { required: `Q${i + 1}をご入力ください。` })}
              label={`Q${i + 1}.${question}`}
              required
            />
            {getAnswerErrorByIndex(errors, i) && <Alert message={getAnswerErrorByIndex(errors, i)!.message} />}
          </div>
        ))}
        {error && <Alert error={error} />}
        <Button disabled={disabled}>{onSave ? '次へ' : '登録'}</Button>
      </form>
      {onSave && (
        <>
          <div className={CSSModule.or}>or</div>
          <div className={CSSModule.fixedButton}>
            <Button color="default" disabled={disabled} onClick={onSaveKarte}>
              下書き保存
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default KarteForm;

const getAnswerErrorByIndex = (errors: FieldErrors<FormValues>, index: number) => {
  const answers = [
    errors.answer1,
    errors.answer2,
    errors.answer3,
    errors.answer4,
    errors.answer5,
    errors.answer6,
    errors.answer7,
    errors.answer8,
    errors.answer9,
    errors.answer10,
  ];

  return answers[index];
};
