import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { parse } from 'query-string';

import useQuery from '../../hooks/common/useQuery';
import useMutation from '../../hooks/common/useMutation';
import useCurrentUser from '../../hooks/store/useCurrentUser';
import { setFlashMessage } from '../../actions/common';
import AppTemplate from '../../components/templates/AppTemplate';
import UiStackTemplate from '../../components/common/UiStackTemplate';
import ResetPasswordPage from '../../components/auth/ResetPasswordPage';
import ExpiresResetTokenPage from '../../components/auth/ExpiresResetTokenPage';

interface FetchResetPasswordTokenStatusParams {
  email: string;
  token: string;
}

interface FetchResetPasswordTokenStatusResult {
  resetPasswordTokenStatus: boolean;
}

const fetchResetPasswordTokenStatusQuery = `
  query getResetPasswordTokenStatus(
    $email: String!,
    $token: String!
  ) {
    resetPasswordTokenStatus(
      email: $email,
      token: $token
    )
  }
`;

interface ResetPasswordParams {
  email: string;
  password: string;
  token: string;
}

interface ResetPasswordResult {
  resetPassword: {
    error: string | null;
  };
}

const resetPasswordQuery = `
  mutation resetPassword(
    $email: String!,
    $password: String!,
    $token: String!
  ) {
    resetPassword(input: {
      email: $email,
      password: $password,
      token: $token
    }) { error }
  }
`;

interface FormValues {
  password: string;
  passwordConfirmation: string;
}

const ResetPasswordContainer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [user] = useCurrentUser();

  const { email, token } = parse(search);
  const { result, isLoading, error, executeQuery } = useQuery<
    FetchResetPasswordTokenStatusParams,
    FetchResetPasswordTokenStatusResult
  >(fetchResetPasswordTokenStatusQuery);
  const {
    executeMutation,
    isLoading: isResetting,
    error: resettingError,
  } = useMutation<ResetPasswordParams, ResetPasswordResult>();

  useEffect(() => {
    executeQuery({ token: token as string, email: email as string });
  }, [executeQuery, token, email]);

  const onCancel = () => {
    navigate('/forgot-password');
  };

  const onSubmit = async (values: FormValues) => {
    const mutationResult = await executeMutation(resetPasswordQuery, {
      email: email as string,
      password: values.password,
      token: token as string,
    });
    if (mutationResult && mutationResult.resetPassword.error) {
      dispatch(setFlashMessage({ message: mutationResult.resetPassword.error }));
    } else {
      dispatch(setFlashMessage({ message: 'パスワードを再設定しました。' }));
      navigate('/login');
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(setFlashMessage({ message: '既にログイン済みです。' }));
      navigate('/mypage');
    }
  }, [user, dispatch, navigate]);

  useEffect(() => {
    if (resettingError) {
      dispatch(setFlashMessage({ message: resettingError.message }));
    }
  }, [resettingError, dispatch]);

  return (
    <AppTemplate>
      <UiStackTemplate isLoading={isLoading} error={error}>
        {result && result.resetPasswordTokenStatus ? (
          <ResetPasswordPage disabled={isResetting} onSubmit={onSubmit} />
        ) : (
          <ExpiresResetTokenPage onCancel={onCancel} />
        )}
      </UiStackTemplate>
    </AppTemplate>
  );
};

export default ResetPasswordContainer;
