import React, { useEffect } from 'react';
import { ResolveThunks, connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router';

import { RootState } from '../../reducers';
import { fetchBookOrderDetail } from '../../actions/orders';
import AppTemplate from '../../components/templates/AppTemplate';
import UiStackTemplate from '../../components/common/UiStackTemplate';
import KarteDetailPage from '../../components/kartes/KarteDetailPage';

const mapStateToProps = (state: RootState) => ({
  bookOrder: state.orders.bookOrder,
  isLoading: state.orders.isFetchingBookOrderDetail,
  error: state.orders.fetchingBookOrderDetailError,
});

const mapDispatchToProps = {
  getBookOrderDetail: fetchBookOrderDetail.action,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

const KarteDetailContainer: React.FC<Props> = ({ bookOrder, isLoading, error, getBookOrderDetail }) => {
  const navigate = useNavigate();
  const { bookOrderId } = useParams<{ bookOrderId: string }>();

  const onClickButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (bookOrderId) {
      if (!bookOrder || bookOrder.id !== bookOrderId) {
        getBookOrderDetail({ bookOrderId });
      }
    }
  }, [bookOrderId, bookOrder, getBookOrderDetail]);

  return (
    <AppTemplate>
      <UiStackTemplate isLoading={isLoading} error={error}>
        {bookOrder && <KarteDetailPage karte={bookOrder.karte} onClickButton={onClickButton} />}
      </UiStackTemplate>
    </AppTemplate>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KarteDetailContainer);
