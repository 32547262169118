import React, { useEffect } from 'react';
import { ResolveThunks, connect } from 'react-redux';
import { useNavigate, Route, Routes } from 'react-router';

import { RootState } from '../../reducers';
import { fetchKarteTemplates } from '../../actions/kartes';
import Authenticated from '../common/Authenticated';
import KarteTemplateDetailContainer from './KarteTemplateDetailContainer';
import EditKarteTemplateContainer from './EditKarteTemplateContainer';
import AppTemplate from '../../components/templates/AppTemplate';
import UiStackTemplate from '../../components/common/UiStackTemplate';
import KarteTemplateListPage from '../../components/karteTemplates/KarteTemplateListPage';
import useCurrentUser from '../../hooks/store/useCurrentUser';

const mapStateToProps = (state: RootState) => ({
  karteTemplates: state.kartes.templates,
  isLoading: state.kartes.isFetchingTemplates,
  error: state.kartes.fetchingTemplatesError,
});

const mapDispatchToProps = {
  getKarteTemplates: fetchKarteTemplates.action,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

const KarteTemplatesContainer: React.FC<Props> = ({ karteTemplates, isLoading, error, getKarteTemplates }) => {
  const navigate = useNavigate();
  const [user] = useCurrentUser();

  const onClickButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (user) {
      getKarteTemplates({ userId: user.id });
    }
  }, [user, getKarteTemplates]);

  return (
    <AppTemplate>
      <Authenticated>
        <UiStackTemplate isLoading={isLoading} error={error} isEmpty={karteTemplates.length === 0}>
          <Routes>
            <Route
              path="/"
              element={<KarteTemplateListPage karteTemplates={karteTemplates} onClickButton={onClickButton} />}
            />
            <Route path="/:templateId" element={<KarteTemplateDetailContainer />} />
            <Route path="/:templateId/edit" element={<EditKarteTemplateContainer />} />
          </Routes>
        </UiStackTemplate>
      </Authenticated>
    </AppTemplate>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KarteTemplatesContainer);
