import React from 'react';

import { Staff } from '../../interfaces/staff';
import AppTemplate from '../templates/AppTemplate';
import UiStackTemplate from '../common/UiStackTemplate';
import StaffDetail from './StaffDetail';
import StickyButton from '../atoms/button/StickyButton';
import Breadcrumbs from '../common/Breadcrumbs';

import CSSModule from './StaffDetailPage.module.scss';

interface Props {
  staff: Staff | null;
  isLoading: boolean;
  error: Error | null;
}

const StaffDetailPage: React.FC<Props> = ({ staff, isLoading, error }) => {
  const breadcrumbs = [
    {
      label: 'ホーム',
      link: '/',
    },
    {
      label: staff ? staff.name : '',
    },
  ];
  const disabled = staff && staff.monthlyCapacity === 0;

  return (
    <AppTemplate marginBottom="64px">
      <UiStackTemplate isLoading={isLoading} error={error}>
        {staff && (
          <>
            <Breadcrumbs pages={breadcrumbs} />
            <div className={CSSModule.staffDetailPage}>
              <StaffDetail staff={staff} />
            </div>
            <StickyButton
              to={`/staffs/${staff.id}/orders/new`}
              text={disabled ? '来月お申し込みください' : `選書してもらう（あと${staff.monthlyCapacity}名）`}
              disabled={!!disabled}
            />
          </>
        )}
      </UiStackTemplate>
    </AppTemplate>
  );
};

export default StaffDetailPage;
