import React from 'react';

import SceneTitle from '../../assets/welcome/scene-title.svg';
import Scene1 from '../../assets/welcome/scene1.jpg';
import Scene2 from '../../assets/welcome/scene2.jpg';
import Scene3 from '../../assets/welcome/scene3.jpg';

import CSSModule from './GiftDescription.module.scss';

const GiftDescription: React.FC = () => {
  return (
    <div className={CSSModule.GiftDescription}>
      <div className={CSSModule.GiftDescription__TopImage} />
      <h2>ギフトを贈る</h2>
      <p className={CSSModule.GiftDescription__Summary}>
        選書体験をプレゼントできる
        <br />
        ギフト機能
      </p>
      <div className={CSSModule.GiftDescription__Description}>
        <p>
          ブックカルテの「選書してもらう体験」を
          <br />
          ギフトとして贈ることができます。
        </p>
        <p>
          受け取った相手はブックカルテの
          <br />
          決済画面にて、お支払いの代わりに
          <br />
          発行されたギフトコードを入力すると、
          <br />
          選書を依頼できるようになります。
        </p>
      </div>
      <img src={SceneTitle} alt="こんなシーンにおすすめ！" className={CSSModule.GiftDescription__SceneTitle} />
      <div className={CSSModule.GiftDescription__SceneList}>
        <div className={CSSModule.GiftDescription__SceneListItem}>
          <p className={CSSModule.GiftDescription__SceneText}>
            出産祝いや
            <br />
            入学祝いに
          </p>
          <img src={Scene1} alt="写真1" className={CSSModule.GiftDescription__SceneImage} />
        </div>

        <div className={CSSModule.GiftDescription__SceneListItem}>
          <img src={Scene2} alt="写真2" className={CSSModule.GiftDescription__SceneImage} />
          <p className={CSSModule.GiftDescription__SceneText}>
            離れて暮らす
            <br />
            お孫さんに
          </p>
        </div>

        <div className={CSSModule.GiftDescription__SceneListItem}>
          <p className={CSSModule.GiftDescription__SceneText}>
            社内コンテストの
            <br />
            景品に
          </p>
          <img src={Scene3} alt="写真3" className={CSSModule.GiftDescription__SceneImage} />
        </div>
      </div>
    </div>
  );
};

export default GiftDescription;
