import React from 'react';

import { SelectedBook } from '../../../interfaces/order';

import CSSModule from './SelectedBookListItem.module.scss';

interface Props {
  selectedBook: SelectedBook;
}

const SelectedBookListItem: React.FC<Props> = ({ selectedBook }) => {
  return (
    <div className={CSSModule.selectedBookListItem}>
      <div className={CSSModule.isbn}>{selectedBook.isbn}</div>
      <div className={CSSModule.title}>{selectedBook.title}</div>
    </div>
  );
};

export default SelectedBookListItem;
