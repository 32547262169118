import { useState, useCallback } from 'react';

import API from '../../actions/API';
import useMounted from './useMounted';

interface QueryState<R> {
  isLoading: boolean;
  error: Error | null;
  result: R | null;
}

const useQuery = <P = void, R = void>(query: string) => {
  const mounted = useMounted();
  const [result, setResult] = useState<QueryState<R>>({
    isLoading: false,
    error: null,
    result: null,
  });

  const updateState = useCallback(
    (newState: Partial<QueryState<R>>) => {
      if (mounted.current) {
        setResult(prevState => ({ ...prevState, ...newState }));
      }
    },
    [setResult, mounted],
  );

  const executeQuery = async (params: P): Promise<R> => {
    updateState({ isLoading: true, error: null });

    try {
      const response = await API.query(query, params);
      updateState({ isLoading: false, error: null, result: response });
      return response;
    } catch (error) {
      const queryError = error instanceof Error ? error : new Error('unexpected query error');
      updateState({ isLoading: false, error: queryError });
      throw error;
    }
  };

  return { ...result, executeQuery: useCallback(executeQuery, [updateState, query]) };
};

export default useQuery;
