import React from 'react';

import { DraftBookOrder } from '../../interfaces/order';
import ActionMessage from './ActionMessage';
import OrderHeader from './OrderHeader';
import StaffLink from './StaffLink';
import KarteLink from './KarteLink';

interface Props {
  bookOrder: DraftBookOrder;
}

const DraftOrderItem: React.FC<Props> = ({ bookOrder }) => (
  <div>
    <ActionMessage>
      注文が完了していません。
      <br />
      カルテを編集後、注文を完了してください。
    </ActionMessage>
    <OrderHeader id={bookOrder.id} time={bookOrder.karte.updatedAt} status={bookOrder.status} />
    <StaffLink staff={bookOrder.staff} />
    <KarteLink staffId={bookOrder.staff.id} bookOrderId={bookOrder.id} isDraft />
  </div>
);

export default DraftOrderItem;
