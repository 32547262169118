import React from 'react';
import classNames from 'classnames';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { BookOrderStatus, BookOrderStatusLabel } from '../../interfaces/order';

import CSSModule from './OrderHeader.module.scss';

interface Props {
  id: string;
  time: string;
  status: BookOrderStatus;
}

const OrderHeader: React.FC<Props> = ({ id, time, status }) => {
  const isDraft = status === BookOrderStatus.Draft;
  const label = isDraft ? '記入日' : '注文日';
  const timeLabel = format(parseISO(time), 'yyyy/MM/dd');
  const statusLabel = BookOrderStatusLabel[status];
  const isActive = status === BookOrderStatus.Draft || status === BookOrderStatus.Listed;
  const activeClassName = classNames({ [CSSModule.isActive]: isActive });
  const classes = classNames(CSSModule.orderHeader, activeClassName);
  const statusClassName = classNames(CSSModule.status, activeClassName);

  return (
    <div className={classes}>
      <div className={CSSModule.contentWrapper}>
        {label}：
        <br />
        {timeLabel}（ID: {id}）
      </div>
      <div className={CSSModule.statusWrapper}>
        <div className={statusClassName}>{statusLabel}</div>
      </div>
    </div>
  );
};

export default OrderHeader;
