import React, { useEffect } from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { StripeApiKey } from 'src/app.constants';
import Button from 'src/components/atoms/button/Button';
import useQuery from 'src/hooks/common/useQuery';

import CSSModule from './PaymentForm.module.scss';

const CreateSetupIntentQuery = `mutation createSetupIntent {
  createSetupIntent(input: {}) {
    setupIntentId
    clientSecret
  }
}`;

interface CreateSetupIntentResult {
  createSetupIntent: {
    setupIntentId: string;
    clientSecret: string;
  };
}

const stripePromise = loadStripe(StripeApiKey);

interface Props {
  returnTo: string;
}

const PaymentForm: React.FC<Props> = ({ returnTo }) => {
  const { executeQuery: createSetupIntent, result: createSetupSecretResult } = useQuery<void, CreateSetupIntentResult>(
    CreateSetupIntentQuery,
  );

  const clientSecret = createSetupSecretResult?.createSetupIntent.clientSecret ?? null;

  useEffect(() => {
    createSetupIntent();
  }, [createSetupIntent]);

  if (!clientSecret) {
    return null;
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret, locale: 'ja' }}>
      <PaymentRegistrationForm clientSecret={clientSecret} returnTo={returnTo} />
    </Elements>
  );
};

export default PaymentForm;

interface FormProps {
  clientSecret: string;
  returnTo: string;
}

const PaymentRegistrationForm: React.FC<FormProps> = ({ clientSecret, returnTo }) => {
  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    const callbackLocation = ['/mypage/payments/edit/callback', new URLSearchParams({ returnTo }).toString()].join('?');
    const returnUrl = new URL(callbackLocation, window.location.origin);

    await elements.submit();
    await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: returnUrl.toString(),
      },
    });
  };

  return (
    <form onSubmit={onSubmit} className={CSSModule.PaymentForm}>
      <div className={CSSModule.PaymentForm__StripeForm}>
        <PaymentElement />
      </div>
      <Button type="submit">送信</Button>
    </form>
  );
};
