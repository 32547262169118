import React from 'react';

import { Karte, KarteTemplate } from '../../interfaces/karte';
import KarteDetail from './KarteDetail';
import Breadcrumbs from '../common/Breadcrumbs';
import Button from '../atoms/button/Button';

import CSSModule from './KarteDetailPage.module.scss';

const breadcrumbs = [
  {
    label: 'ホーム',
    link: '/',
  },
  {
    label: 'マイページ',
    link: '/mypage',
  },
  {
    label: 'カルテ詳細',
  },
];

interface Props {
  karte: Karte | KarteTemplate;
  onClickButton: () => void;
}

const KarteDetailPage: React.FC<Props> = ({ karte, onClickButton }) => {
  return (
    <div className={CSSModule.karteDetailPage}>
      <Breadcrumbs pages={breadcrumbs} />
      <KarteDetail karte={karte} />
      <div className={CSSModule.button}>
        <Button onClick={onClickButton}>戻る</Button>
      </div>
    </div>
  );
};

export default KarteDetailPage;
