import React from 'react';

import { KarteTemplate } from '../../interfaces/karte';
import KarteTemplateList from './KarteTemplateList';
import Breadcrumbs from '../common/Breadcrumbs';
import Button from '../atoms/button/Button';

import CSSModule from './KarteTemplateListPage.module.scss';

const breadcrumbs = [
  {
    label: 'ホーム',
    link: '/',
  },
  {
    label: 'マイページ',
    link: '/mypage',
  },
  {
    label: 'マイカルテ',
  },
];

interface Props {
  karteTemplates: KarteTemplate[];
  onClickButton: () => void;
}

const KarteTemplateListPage: React.FC<Props> = ({ karteTemplates, onClickButton }) => {
  return (
    <div className={CSSModule.karteTemplateListPage}>
      <Breadcrumbs pages={breadcrumbs} />
      <KarteTemplateList karteTemplates={karteTemplates} />
      <div className={CSSModule.button}>
        <Button onClick={onClickButton}>戻る</Button>
      </div>
    </div>
  );
};

export default KarteTemplateListPage;
