import React from 'react';

import MainLogo from '../../assets/logo/main.svg';

import CSSModule from './AppLoading.module.scss';

const AppLoading: React.FC = () => (
  <div className={CSSModule.wrapper}>
    <img src={MainLogo} alt="Book Karte Logo" className={CSSModule.logo} />
    <div className={CSSModule.text}>Loading...</div>
  </div>
);

export default AppLoading;
