import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import LoginContainer from './LoginContainer';
import AppTemplate from '../../components/templates/AppTemplate';
import LoginFirstView from '../../components/welcome/LoginFirstView';
import useCurrentUser from '../../hooks/store/useCurrentUser';

const LoginPageContainer: React.FC = () => {
  const navigate = useNavigate();
  const [user] = useCurrentUser();

  useEffect(() => {
    if (user) {
      navigate('/mypage');
    }
  }, [user, navigate]);

  return (
    <AppTemplate>
      <LoginFirstView />
      <LoginContainer />
    </AppTemplate>
  );
};

export default LoginPageContainer;
