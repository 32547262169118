import React from 'react';

import { StaffCategoryName } from '../../interfaces/staff';

import CSSModule from './StaffCategoryList.module.scss';

interface Props {
  categories: StaffCategoryName[];
}

const StaffCategoryList: React.FC<Props> = ({ categories }) => (
  <div className={CSSModule.categories}>
    {categories.map((name, i) => (
      <div key={`${name}-${i}`} className={CSSModule.categoryItem}>
        <img src={require(`../../assets/categories/${name}.svg`)} alt={name} className={CSSModule.categoryIcon} />
      </div>
    ))}
  </div>
);

export default StaffCategoryList;
