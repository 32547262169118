import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

import API from './API';
import { FETCH_KARTE_TEMPLATES, REGISTER_KARTE_TEMPLATE } from './actionTypes';
import { getKarteTemplatesQuery, registerKarteTemplateQuery } from './queries/karteQueries';
import { KarteTemplate, KarteQuestionTemplate, KarteInput } from '../interfaces/karte';
import { KarteTemplatesLimit } from '../app.constants';
import parseToInt from '../utils/parseToInt';

const actionCreator = actionCreatorFactory();
const asyncActionCreator = asyncFactory(actionCreator);

interface FetchKarteTemplatesParams {
  userId: string;
}

interface FetchKarteTemplatesResult {
  karteTemplates: KarteTemplate[];
  defaultKarteQuestionTemplate: KarteQuestionTemplate;
}

export const fetchKarteTemplates = asyncActionCreator<FetchKarteTemplatesParams, FetchKarteTemplatesResult>(
  FETCH_KARTE_TEMPLATES,
  async ({ userId }) =>
    API.query(getKarteTemplatesQuery, {
      userId,
      size: KarteTemplatesLimit,
    }),
);

interface RegisterKarteTemplateParams {
  orderNumber: number;
  karte: KarteInput;
}

interface RegisterKarteTemplateResult {
  karteTemplates: KarteTemplate[];
}

export const registerKarteTemplate = asyncActionCreator<RegisterKarteTemplateParams, RegisterKarteTemplateResult>(
  REGISTER_KARTE_TEMPLATE,
  async ({ orderNumber, karte }) => {
    const { registerKarteTemplate: result } = await API.query(registerKarteTemplateQuery, {
      orderNumber,
      karte: { ...karte, age: parseToInt(karte.age) },
    });
    return result;
  },
);
