import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { User } from '../../interfaces/user';
import UserPhoto from '../users/UserPhoto';
import MainLogo from '../../assets/logo/main.png';

import CSSModule from './Header.module.scss';

interface Props {
  user: User | null;
  notificationCount: number;
}

const Header: React.FC<Props> = ({ user, notificationCount }) => (
  <header className={CSSModule.Header}>
    <Link to="/" className={CSSModule.Header__LogoLink}>
      <img src={MainLogo} alt="Book Karte" className={CSSModule.Header__Logo} />
    </Link>
    <div className={CSSModule.Header__Links}>
      {user ? (
        <Link to="/mypage" className={CSSModule.Header__MyPageLink}>
          <UserPhoto src={user.photo} />
          {notificationCount > 0 && <i className={CSSModule.Header__Notification}>{notificationCount}</i>}
        </Link>
      ) : (
        <>
          <Link to="/login" className={classNames(CSSModule.Header__LinkButton, CSSModule.SecondaryColor)}>
            ログイン
          </Link>
          <Link to="/gifts" className={classNames(CSSModule.Header__LinkButton, CSSModule.PrimaryColor)}>
            ギフトを贈る
          </Link>
        </>
      )}
    </div>
  </header>
);

export default Header;
