import { Photo } from './common';
import { KarteQuestionTemplate } from './karte';

export const ParentStaffCategories: ParentStaffCategoryName[] = ['BookType', 'StoryGenre', 'OtherGenre', 'Emotion'];

export const ParentStaffCategoryLabels: { [key in ParentStaffCategoryName]: string } = {
  BookType: '書籍分野',
  StoryGenre: '小説•まんがジャンル',
  OtherGenre: 'その他ジャンル',
  Emotion: '気持ち',
};

export const StaffCategories = [
  {
    parent: 'BookType',
    label: '小説',
    value: 'novel',
  },
  {
    parent: 'BookType',
    label: 'エッセイ',
    value: 'essay',
  },
  {
    parent: 'BookType',
    label: 'まんが',
    value: 'comic',
  },
  {
    parent: 'BookType',
    label: 'ラノベ',
    value: 'lightNovel',
  },
  {
    parent: 'BookType',
    label: '絵本',
    value: 'pictureBook',
  },
  {
    parent: 'BookType',
    label: '児童書',
    value: 'childrenBook',
  },
  {
    parent: 'BookType',
    label: '詩・短歌',
    value: 'poem',
  },
  {
    parent: 'BookType',
    label: '洋書',
    value: 'foreignBook',
  },
  {
    parent: 'StoryGenre',
    label: '純文学',
    value: 'literature',
  },
  {
    parent: 'StoryGenre',
    label: '歴史',
    value: 'history',
  },
  {
    parent: 'StoryGenre',
    label: 'ミステリー',
    value: 'mystery',
  },
  {
    parent: 'StoryGenre',
    label: 'SF',
    value: 'sf',
  },
  {
    parent: 'StoryGenre',
    label: '犯罪もの',
    value: 'crime',
  },
  {
    parent: 'StoryGenre',
    label: '海外文学',
    value: 'foreignLiterature',
  },
  {
    parent: 'StoryGenre',
    label: '青春',
    value: 'youth',
  },
  {
    parent: 'StoryGenre',
    label: '恋愛',
    value: 'romance',
  },
  {
    parent: 'StoryGenre',
    label: 'BL',
    value: 'bl',
  },
  {
    parent: 'StoryGenre',
    label: 'GL',
    value: 'gl',
  },
  {
    parent: 'StoryGenre',
    label: 'TL',
    value: 'tl',
  },
  {
    parent: 'OtherGenre',
    label: 'ビジネス',
    value: 'business',
  },
  {
    parent: 'OtherGenre',
    label: '哲学',
    value: 'philosophy',
  },
  {
    parent: 'OtherGenre',
    label: '日本史',
    value: 'japaneseHistory',
  },
  {
    parent: 'OtherGenre',
    label: '世界史',
    value: 'globalHistory',
  },
  {
    parent: 'OtherGenre',
    label: '社会科学',
    value: 'socialScience',
  },
  {
    parent: 'OtherGenre',
    label: '自然科学',
    value: 'natureScience',
  },
  {
    parent: 'OtherGenre',
    label: '技術・工学',
    value: 'technology',
  },
  {
    parent: 'OtherGenre',
    label: 'デザイン',
    value: 'design',
  },
  {
    parent: 'OtherGenre',
    label: 'アート',
    value: 'art',
  },
  {
    parent: 'OtherGenre',
    label: '芸能・音楽',
    value: 'entertainment',
  },
  {
    parent: 'OtherGenre',
    label: '教育',
    value: 'education',
  },
  {
    parent: 'OtherGenre',
    label: '子育て',
    value: 'childCare',
  },
  {
    parent: 'OtherGenre',
    label: '健康',
    value: 'health',
  },
  {
    parent: 'OtherGenre',
    label: 'スポーツ',
    value: 'sports',
  },
  {
    parent: 'OtherGenre',
    label: '旅行',
    value: 'travel',
  },
  {
    parent: 'OtherGenre',
    label: '料理',
    value: 'cooking',
  },
  {
    parent: 'OtherGenre',
    label: 'サブカル',
    value: 'subCulture',
  },
  {
    parent: 'OtherGenre',
    label: 'スピリチュアル',
    value: 'spiritual',
  },
  {
    parent: 'OtherGenre',
    label: 'アダルト',
    value: 'adult',
  },
  {
    parent: 'OtherGenre',
    label: '特集',
    value: 'featured',
  },
  {
    parent: 'Emotion',
    value: 'wakuwaku',
    label: 'ワクワク',
  },
  {
    parent: 'Emotion',
    value: 'harahara',
    label: 'ハラハラ',
  },
  {
    parent: 'Emotion',
    value: 'moyamoya',
    label: 'モヤモヤ',
  },
  {
    parent: 'Emotion',
    value: 'laugh',
    label: '笑える',
  },
  {
    parent: 'Emotion',
    value: 'cry',
    label: '泣ける',
  },
  {
    parent: 'Emotion',
    value: 'painful',
    label: '切ない',
  },
  {
    parent: 'Emotion',
    value: 'depression',
    label: '沈む',
  },
  {
    parent: 'Emotion',
    value: 'catharsis',
    label: 'スッキリ',
  },
  {
    parent: 'Emotion',
    value: 'emotional',
    label: 'エモい',
  },
] as const;

export type StaffCategoryType = (typeof StaffCategories)[number];
export type ParentStaffCategoryName = StaffCategoryType['parent'];
export type StaffCategoryName = StaffCategoryType['value'];
export type StaffCategoryLabel = StaffCategoryType['label'];

export const StaffCategoryLabels = StaffCategories.reduce(
  (result, category) => ({
    ...result,
    [category.value]: category.label,
  }),
  {},
) as { [key in StaffCategoryName]: StaffCategoryLabel };

export interface StaffIntroduction {
  id: string;
  name: string;
  bookStoreName: string;
  photos: Photo[];
  karteQuestionTemplate: KarteQuestionTemplate;
}

export type CategoryType = 'genre' | 'emotion';

export interface StaffCategory {
  name: StaffCategoryName;
  categoryType: CategoryType;
}

export interface Staff extends StaffIntroduction {
  slogan: string;
  profile: string;
  monthlyCapacity: number;
  categories: StaffCategory[];
}

export interface AdminStaff extends Staff {
  email: string;
  monthlyLimit: number;
  completedOrderCount: number;
  unlistedOrderCount: number;
  undeliveredOrderCount: number;
  searchable: 'true' | 'false';
  adminRecommendationNumber: number;
}

export type PhotoData =
  | {
      id: string;
      src: string;
      key?: null;
      file?: null;
    }
  | {
      id?: null;
      src: string;
      key: string;
      file: File;
    };
