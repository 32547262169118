import React from 'react';

import { User } from '../../interfaces/user';
import OrderListContainer from '../../containers/orders/OrderListContainer';
import Breadcrumbs from '../common/Breadcrumbs';
import ProfilePanel from './ProfilePanel';

import CSSModule from './MyPage.module.scss';

const breadcrumbs = [
  {
    label: 'ホーム',
    link: '/',
  },
  {
    label: 'マイページ',
  },
];

interface Props {
  user: User;
}

const MyPage: React.FC<Props> = ({ user }) => (
  <div className={CSSModule.mypage}>
    <div className={CSSModule.breadcrumbs}>
      <Breadcrumbs pages={breadcrumbs} />
    </div>
    <ProfilePanel user={user} />
    <OrderListContainer />
  </div>
);

export default MyPage;
