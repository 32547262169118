import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

import GATracker from 'src/utils/GATracker';

const Analytics: React.FC = () => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    GATracker.trackPageEvent(pathname);
  }, [pathname]);

  return null;
};

export default Analytics;
