import React from 'react';
import { ResolveThunks, connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router';

import { setFlashMessage as setFlashMessageAction } from 'src/actions/common';
import { registerKarteTemplate } from 'src/actions/kartes';
import { optionNames } from 'src/interfaces/karte';
import { RootState } from 'src/reducers';
import EditKarteTemplatePage from 'src/components/kartes/EditKarteTemplatePage';
import { FormValues } from 'src/components/kartes/KarteForm';

const mapStateToProps = (state: RootState) => ({
  karteTemplates: state.kartes.templates,
  defaultKarteQuestionTemplate: state.kartes.defaultKarteQuestionTemplate,
  isLoading: state.kartes.isRegisteringTemplate,
  error: state.kartes.registeringTemplateError,
});

const mapDispatchToProps = {
  registerTemplate: registerKarteTemplate.action,
  setFlashMessage: setFlashMessageAction,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

const EditKarteTemplateContainer: React.FC<Props> = ({
  karteTemplates,
  defaultKarteQuestionTemplate,
  isLoading,
  error,
  registerTemplate,
  setFlashMessage,
}) => {
  const navigate = useNavigate();
  const params = useParams<{ templateId: string }>();
  const templateId = Number(params.templateId);
  const karteTemplate = karteTemplates.find(template => template.orderNumber + 1 === templateId) || null;
  const questionTemplate = karteTemplate?.karteQuestionTemplate ?? defaultKarteQuestionTemplate;

  if (!templateId) {
    return null;
  }

  const karteName = optionNames[templateId - 1];

  const onSubmit = async (values: FormValues) => {
    if (questionTemplate) {
      await registerTemplate({
        orderNumber: templateId - 1,
        karte: {
          gender: values.gender,
          age: values.age,
          answer1: values.answer1!,
          answer2: values.answer2!,
          answer3: values.answer3!,
          answer4: values.answer4!,
          answer5: values.answer5!,
          answer6: values.answer6!,
          answer7: values.answer7!,
          answer8: values.answer8!,
          answer9: values.answer9!,
          answer10: values.answer10!,
          karteQuestionTemplateId: questionTemplate.id,
        },
      });
      setFlashMessage({ message: `${karteName}を編集しました。` });
      navigate(`/mypage/karte-templates/${templateId}`);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    questionTemplate && (
      <EditKarteTemplatePage
        templateId={templateId}
        karteTemplate={karteTemplate}
        karteQuestionTemplate={questionTemplate}
        disabled={isLoading}
        error={error}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditKarteTemplateContainer);
