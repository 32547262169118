import React from 'react';
import { ResolveThunks, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import Authenticated from '../common/Authenticated';
import ProfileMenuPage from '../../components/mypage/ProfileMenuPage';
import AppTemplate from '../../components/templates/AppTemplate';
import { logout, unregisterUser } from '../../actions/auth';
import { setFlashMessage as setFlashMessageAction } from '../../actions/common';
import GATracker from '../../utils/GATracker';
import useCurrentUser from '../../hooks/store/useCurrentUser';

const mapDispatchToProps = {
  signOut: logout,
  unregister: unregisterUser.action,
  setFlashMessage: setFlashMessageAction,
};

type Props = ResolveThunks<typeof mapDispatchToProps>;

const ProfileMenuListContainer: React.FC<Props> = ({ signOut, unregister, setFlashMessage }) => {
  const navigate = useNavigate();
  const [user] = useCurrentUser();

  const onSignOut = () => {
    signOut();
    setFlashMessage({ message: 'ログアウトしました。' });
    navigate('/login');
  };

  const onUnregister = () => {
    if (window.confirm('本当に退会しますか？')) {
      unregister();
      setFlashMessage({ message: '退会処理が完了しました。' });
      navigate('/');
    }

    GATracker.trackEvent('click_withdrawal_button');
  };

  return (
    <AppTemplate>
      <Authenticated>
        {user && <ProfileMenuPage user={user} signOut={onSignOut} unregister={onUnregister} />}
      </Authenticated>
    </AppTemplate>
  );
};

export default connect(undefined, mapDispatchToProps)(ProfileMenuListContainer);
