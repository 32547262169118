import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import CSSModule from './KarteTemplateListItem.module.scss';

interface Props {
  name: string;
  index: number;
  disabled: boolean;
}

const KarteTemplateListItem: React.FC<Props> = ({ name, index, disabled }) => {
  return (
    <Link
      to={`/mypage/karte-templates/${index}`}
      className={classNames(CSSModule.karteTemplateListItem, { [CSSModule.disabled]: disabled })}
    >
      {name}
    </Link>
  );
};

export default KarteTemplateListItem;
