import { useSearchParams } from 'react-router-dom';

import Authentication from 'src/containers/common/Authentication';
import PaymentForm from 'src/components/payment/PaymentForm';
import AppTemplate from 'src/components/templates/AppTemplate';
import UrlRedirectChecker from 'src/utils/UrlRedirectChecker';

const EditPaymentPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo') ?? '/mypage/payments';
  const [isSameHost] = UrlRedirectChecker.isSameHost(returnTo);

  if (!isSameHost) {
    throw new Error(`invalid hostname: ${returnTo}`);
  }

  return (
    <AppTemplate>
      <Authentication>
        <PaymentForm returnTo={returnTo} />
      </Authentication>
    </AppTemplate>
  );
};

export default EditPaymentPage;
