import React from 'react';

import { ReactComponent as Checked } from '../../assets/system/checked.svg';
import { ReactComponent as Unchecked } from '../../assets/system/unchecked.svg';

import CSSModule from './CheckedIcon.module.scss';

interface Props {
  checked: boolean;
}

const CheckedIcon: React.FC<Props> = ({ checked }) => (
  <div className={CSSModule.checkedIcon}>
    {checked ? <Checked className={CSSModule.icon} /> : <Unchecked className={CSSModule.icon} />}
  </div>
);

export default CheckedIcon;
