import { isString } from 'lodash-es';

type Column = string | { [key: string]: Column[] };

export const generateColumnQuery: (columns: Column[]) => string = columns =>
  columns
    .map(column =>
      isString(column) ? column : Object.keys(column).map(key => `${key} { ${generateColumnQuery(column[key])} }`),
    )
    .join(' ');
