import React from 'react';

import { User } from '../../interfaces/user';
import Button from '../atoms/button/Button';
import Alert from '../atoms/text/Alert';
import PriceConfirmation from '../payment/PriceConfirmation';
import { PaymentMethodType } from './PaymentMethodSelect';

import CSSModule from './OrderConfirmationPage.module.scss';

interface Props {
  paymentType: PaymentMethodType;
  couponCode: string | null;
  user: User;
  disabled: boolean;
  serverError: string | null;
  onSubmit: () => void;
  onCancel: () => void;
}

const OrderConfirmationPage: React.FC<Props> = ({
  paymentType,
  couponCode,
  user,
  disabled,
  serverError,
  onSubmit,
  onCancel,
}) => {
  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={onSubmitForm}>
      <div className="form-group">
        <div className={CSSModule.label}>お名前</div>
        <div className={CSSModule.value}>{user.name}</div>
      </div>
      <div className="form-group">
        <div className={CSSModule.label}>フリガナ</div>
        <div className={CSSModule.value}>{user.nameKana}</div>
      </div>
      <div className="form-group">
        <div className={CSSModule.label}>メールアドレス</div>
        <div className={CSSModule.value}>{user.email}</div>
      </div>
      <div className="form-group">
        <div className={CSSModule.label}>電話番号</div>
        <div className={CSSModule.value}>{user.tel}</div>
      </div>
      {!user.shippingPostalCode && (
        <div className="form-group">
          <div className={CSSModule.label}>郵便番号</div>
          <div className={CSSModule.value}>{user.postalCode}</div>
        </div>
      )}
      {!user.shippingAddress && (
        <div className="form-group">
          <div className={CSSModule.label}>配送先住所</div>
          <div className={CSSModule.value}>{user.address}</div>
        </div>
      )}
      {user.shippingPostalCode && (
        <div className="form-group">
          <div className={CSSModule.label}>お届け先郵便番号</div>
          <div className={CSSModule.value}>{user.shippingPostalCode}</div>
        </div>
      )}
      {user.shippingAddress && (
        <div className="form-group">
          <div className={CSSModule.label}>配送先住所</div>
          <div className={CSSModule.value}>{user.shippingAddress}</div>
        </div>
      )}
      {paymentType === 'card' && (
        <div className="form-group">
          <div className={CSSModule.label}>クレジットカード</div>
          <div className={CSSModule.value}>
            {user.cardBrand} **** **** **** {user.cardLast4}
            <br />
            有効期限: {user.cardExpMonth}/{user.cardExpYear}
          </div>
        </div>
      )}
      {paymentType === 'coupon' && (
        <div className="form-group">
          <div className={CSSModule.label}>ギフト利用</div>
          <div className={CSSModule.value}>{couponCode}</div>
        </div>
      )}
      {paymentType === 'card' && <PriceConfirmation />}
      {serverError && <Alert error={new Error(serverError)} />}
      <div className={CSSModule.button}>
        <Button disabled={disabled}>OK</Button>
      </div>
      <div className={CSSModule.button}>
        <Button color="default" onClick={onCancel}>
          戻る
        </Button>
      </div>
    </form>
  );
};

export default OrderConfirmationPage;
