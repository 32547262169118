import React from 'react';
import classNames from 'classnames';

import CSSModule from './Input.module.scss';

const DEFAULT_ROWS = 5;

interface Props {
  id?: string;
  name: string;
  value?: string;
  type?: string;
  rows?: number;
  color?: 'white' | 'gray';
  disabled?: boolean;
  label?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ id, name, rows = DEFAULT_ROWS, label, required, onChange, color = 'gray', ...props }, ref) => {
    const classes = classNames(CSSModule.input, 'form-control', {
      [CSSModule.gray]: color === 'gray',
    });
    const inputId = id || name;

    return (
      <>
        {label && (
          <label htmlFor={name} className={CSSModule.label}>
            {label}
            {required && <span className={CSSModule.required}>※</span>}
          </label>
        )}
        <textarea
          {...props}
          id={inputId}
          name={name}
          className={classes}
          onChange={onChange}
          required={required}
          rows={rows}
          ref={ref}
        />
      </>
    );
  },
);

export default TextArea;
