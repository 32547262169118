import React from 'react';

import { KarteTemplate, optionNames, KarteQuestionTemplate } from '../../interfaces/karte';
import Breadcrumbs from '../common/Breadcrumbs';
import KarteForm, { FormValues } from './KarteForm';
import Button from '../atoms/button/Button';

import CSSModule from './EditKarteTemplatePage.module.scss';

const breadcrumbs = [
  {
    label: 'ホーム',
    link: '/',
  },
  {
    label: 'マイページ',
    link: '/mypage',
  },
  {
    label: 'マイカルテ',
    link: '/mypage/karte-templates',
  },
];

interface Props {
  templateId: number;
  karteTemplate: KarteTemplate | null;
  karteQuestionTemplate: KarteQuestionTemplate;
  disabled: boolean;
  error: Error | null;
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
}

const EditKarteTemplatePage: React.FC<Props> = ({
  templateId,
  karteTemplate,
  karteQuestionTemplate,
  disabled,
  error,
  onSubmit,
  onCancel,
}) => {
  const karteName = optionNames[templateId - 1];
  const pages = [...breadcrumbs, { label: karteName }];

  return (
    <div className={CSSModule.editKarteTemplatePage}>
      <Breadcrumbs pages={pages} />
      <KarteForm
        karte={karteTemplate}
        questions={karteQuestionTemplate.questions}
        disabled={disabled}
        error={error}
        onSubmit={onSubmit}
      />
      <div className={CSSModule.button}>
        <Button color="default" onClick={onCancel}>
          戻る
        </Button>
      </div>
    </div>
  );
};

export default EditKarteTemplatePage;
