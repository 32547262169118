import React from 'react';

import CSSModule from './Terms.module.scss';

const Terms: React.FC = () => {
  return (
    <div className={CSSModule.terms}>
      第１条（適用範囲）
      <br />
      1.
      本利用規約は、株式会社エイトリンクス（以下「当社」といいます。）が本サービス（第２条１項に定義します。）及び当社ウェブサイト（下記第２条２項に定義します）についての利用条件を定めるものであり、本サービスの利用にかかる当社と登録会員の一切の関係に適用されます。
      <br />
      2.
      本サービスに関して、本利用規約とは別に「使い方」、「ポリシー」等の名称で当社が配布又はウェブサイト上に掲載している文書（以下「個別利用規約」といいます。）がある場合、登録会員は、本利用規約のほか個別利用規約の定めにも従って本サービスを利用しなければなりません。
      <br />
      3.
      個別利用規約と本利用規約とで矛盾する内容が規定されている場合、矛盾する箇所に限り、個別利用規約の内容が優先して適用されます。
      <br />
      第２条（用語の定義）
      <br />
      1.
      「本サービス」とは、当社が「ブックカルテ」のサービス名称で提供する、企業及び個人とユーザーのマッチングサービス、及び、関連サービスをいいます。
      <br />
      2.
      「当社ウェブサイト」とは、当社が「ブックカルテ」の名称で運営するウェブサイト及びその他の関連ウェブサイトをいい、メールマガジン等本ウェブサイトから発信する情報を含むものをいいます。
      <br />
      3.
      「ユーザー」とは、第３条（登録）３項記載の登録手続きを完了し、本サービスにおいて、選書を依頼する個人をいいます。
      <br />
      4.
      「書店員」とは、第３条４項記載の登録手続きを完了し、本サービスにおいて、本を選び発送する自然人又は法人をいいます。
      <br />
      5. 「登録会員」とは、ユーザー及び書店員をいいます。
      <br />
      6.
      「マッチングサービス」とは、ユーザーと書店員が取引を行うためのツール及びプラットフォームの提供及びこれに付随又は関連するサービスをいいます。
      <br />
      7. 「コンテンツ」とは、文章、画像、動画、音楽、音声、プログラム、コードその他の情報をいいます。
      <br />
      8.
      「登録会員コンテンツ」とは、登録会員が本サービスに、送信・アップロードする等して当社の管理するサーバに保存させたコンテンツのことをいいます。
      <br />
      9.
      「当社提供コンテンツ」とは、当社が本サービスの提供の一環として、本サービス上で登録会員に対し利用可能なものとして提供したコンテンツのことをいいます。
      <br />
      第３条（登録）
      <br />
      本サービスの利用を希望する者は、当社の定める一定の情報（以下、「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録（以下、「利用登録」といいます。）を申請することができます（以下、「登録申請者」といいます。）。なお、未成年者は、会員登録の申請を含む当社サービスの利用に関する一切の行為につき、親権者等の法定代理人の同意を得た上でこれを行うものとします。
      <br />
      1. 会員登録は、会員となる本人（法人の場合には対外的な契約権限を有する者とします。）のみが申請できるものとします。
      <br />
      2. 会員登録は、本名及び正式名称で行うものとします。
      <br />
      3.
      登録申請者は、利用登録の申請により、本利用規約の全ての記載内容について有効かつ取り消し不能な同意をしたものとみなされます。
      <br />
      4.
      登録申請者は、登録事項を当社の定める方法で当社に提供することにより登録手続きを完了し登録会員となり、本サービスを本利用規約に従い利用することができるようになります。
      <br />
      5. 登録申請者は、登録事項の提供にあたり、提供した情報は全て真実であることを保証するものとします。
      <br />
      6.
      当社は、登録申請者が以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
      <br />
      1. 当社に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
      <br />
      2.
      反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずるものを意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与を行っていると当社が判断した場合
      <br />
      3. 登録申請者が過去当社との契約に違反した者又はその関係者であると当社が判断した場合
      <br />
      4. 第１６条（登録抹消等）に定める措置を受けたことがある場合
      <br />
      5. 本利用規約に違反するおそれがある場合
      <br />
      6. その他、当社が登録を適当でないと判断した場合
      <br />
      7.
      登録会員は、登録情報に変更があった場合、遅滞なく最新の情報に登録内容を変更するための当社所定の手続きを取るものとします。
      <br />
      第４条（アカウント）
      <br />
      1. 本サービスの登録に伴い、登録会員は１会員につき１つのアカウントを保有するものとします。
      <br />
      2. 書店員が所属する法人の従業員は、書店員のアカウントを用いて本サービスを利用することができます。
      <br />
      第５条（複数アカウントの保有及びアカウントの共有等の禁止）
      <br />
      1.
      当社が別に認めたものを除き、一登録会員が複数のアカウントを保有すること、複数の登録会員が１つ又は複数のアカウントを共同して保有することはできません。
      <br />
      2. 登録会員はいかなる場合においても、アカウントを第三者に譲渡又は貸与することはできません。
      <br />
      第６条（本サービスの提供方法）
      <br />
      1.
      当社は、書店員としての登録においては本サービスの一部の提供にあたり、事業規模、中心業務、設立からの年数、経験、経歴その他当社が適切と判断する条件を満たした登録会員に限定することができるものとします。
      <br />
      2. 当社は、本サービスに当社又は第三者の広告を掲載することができるものとします。
      <br />
      第７条（本サービスの無保証）
      <br />
      当社は、本サービス（本サービスにより提供される全てのコンテンツを含みます。）に事実上又は法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティ等に関する欠陥、入力情報が消失する等のエラーやバグ、権利侵害等を含みますが、これらに限りません。）がないことを保証しておりません。当社は、登録会員に対して、かかる瑕疵を除去して本サービスを提供する義務を負わず、本サービスの利用は、全て登録会員自らの責任において行うものとします。また、当社は、ユーザーと書店員間で生じたいかなる紛争について責任を負いません。
      <br />
      第８条（登録会員による本規約違反の場合の措置）
      <br />
      当社は、登録会員が本規約に違反して本サービスを利用していると判断した場合、必要かつ適切と当社が判断する措置を講じることができます。但し、当社は、登録会員及び第三者に対して、登録会員その他の者が本規約に違反しないことを保証するものではありません。また、当社は、登録会員及び第三者に対して、かかる違反を防止又は是正する義務を負いません。
      <br />
      第９条（本サービスの内容）
      <br />
      1.
      当社は、本サービスによる情報提供を通じて、ユーザーと書店員とのマッチングサービスを提供します。当社は、ユーザーと書店員にそれぞれ本サービスを提供しますが、当社が別段の定めを置く場合除き、ユーザーと書店員との間の契約の当事者とはなりません。
      <br />
      2. ユーザーは、当社が指定する方法により、本サービスとして次のサービスを利用することができます。
      <br />
      1. データベースから書店員を選ぶこと
      <br />
      2. カルテを記入することで、書店員に選書を依頼すること
      <br />
      3. 書店員は、当社が指定する方法により、本サービスとして次のサービス利用することができます。
      <br />
      1. 指名のあったユーザーに対して選書をすること
      <br />
      2. 選書に基づき書籍を発送すること
      <br />
      3. 当社が別途指定する方法により、ユーザーから直接提案依頼書に対する提案の依頼を受けること
      <br />
      第１０条（本サービスの利用料）
      <br />
      1. 書店員は、当社が別途定める場合を除き、本サービスを無料で利用できるものとします。
      <br />
      2.
      ユーザーは、本サービスのうち、当社が別途定める特定のサービス（以下「有料サービス」といいます。）を利用するために、利用料を支払わなければなりません。
      <br />
      3. 前項の利用料は、当社が別途定める料金によります。
      <br />
      第１１条（有料サービスにおけるキャンセルの取扱い）
      <br />
      1. ユーザーは、選書依頼の申し込み、その他有料サービスの利用の申し込みを取り消すことはできないものとします。
      <br />
      2.
      理由の如何にかかわらず、当社は受領した有料サービスの対価の変換を行う義務を負わないものとします。但し、当社の責により有料サービスの提供が不能となった場合はこの限りではありません。
      <br />
      第１２条（評価機能）
      <br />
      1.
      登録会員は、書店員がユーザーに提案した書店リストを当該ユーザーが評価を行うことができる評価機能が当社サービス上に設けられており、評価内容が登録会員に対し公開されることについて同意するものとします。
      <br />
      2.
      ユーザーは、書店員の評価を行う際に、虚偽の事実、提案依頼書に無関係な内容、他人の権利を侵害する内容、公序良俗に反する内容を記述することを禁止します。当社は、当該禁止事項に違反していると判断する評価を削除ないし非公開にすることができます。
      <br />
      3.
      登録会員は、評価内容について、他の登録会員及び当社をいかなる責任からも免除することについて同意することとします。
      <br />
      第１３条（支払方法）
      <br />
      書店員は、本サービスの利用によって発生する料金の支払を、当該登録会員の登録事項として登録されているクレジットカード情報に基づく、クレジットカード払いで行うものとし、当社が別途指定する場合を除き、Stripeの提供するクレジットカード決済サービスを利用することに同意するものとする。
      <br />
      第１４条（本サービスの停止）
      <br />
      1.
      当社は、以下のいずれかに該当する場合には、事前の通知又は告知なく、本サービスの全部又は一部の提供を停止又は中断することができるものとします。
      <br />
      1. 本サービスに係るコンピューター・システムの点検又は保守作業を行う必要がある場合
      <br />
      2. コンピューター、通信回線等が事故により停止した場合
      <br />
      3. 地震、落雷、風水害、停電、天災地変等の不可抗力により本サービスの運営ができなくなった場合
      <br />
      4. 本サービスの改善、改修等のため緊急の停止又は中断の必要がある場合
      <br />
      5. その他、当社が緊急の停止又は中断を必要と判断した場合
      <br />
      2.
      当社は、以下のいずれかに該当する場合には、当社ウェブサイト上もしくはメールで事前告知のうえ、本サービスの全部又は一部の提供を停止又は中断することができるものとします。
      <br />
      1. 本サービスに係るコンピューター・システムの点検又は保守作業を行う必要がある場合
      <br />
      2. 本サービスの改善、改修等のため必要がある場合
      <br />
      3. 当社は、本条に基づき当社が行った措置に基づき登録会員に生じた損害について一切の責任を負いません。
      <br />
      第１５条（本サービスの内容の変更、終了）
      <br />
      1.
      当社は、事前に通知することなく、当社の都合により、本サービスの内容を変更し、又は本サービスの提供を終了することができます。
      <br />
      2. 当社は、本条に基づき当社が行った措置に基づき登録会員に生じた損害について一切の責任を負いません。
      <br />
      第１６条（利用環境等）
      <br />
      登録会員は、本サービスを利用するにあたり、必要なパーソナルコンピューター、スマートフォン、通信機器、オペレーションシステム、通信手段及び電力等を、登録会員の費用と責任で用意するものとします。
      <br />
      第１７条（登録会員ＩＤ及びパスワードの管理）
      <br />
      1.
      登録会員は、自己の責任において、本サービスに関する登録会員ＩＤ及びパスワードを適切に管理及び保管するものとし、これを第三者（登録会員に付与される登録会員ＩＤ及びパスワードについては当該登録会員以外の者を「第三者」とします。）に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。なお、ユーザーが登録会員の場合、ユーザーの役員及び従業員は当該登録会員とみなされます。
      <br />
      2.
      当社は、登録された登録会員ＩＤ又はパスワードを利用して行われた一切の行為を、登録会員ご本人の行為とみなすことができます。
      <br />
      3.
      登録会員ＩＤ又はパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録会員が負うものとし、当社は一切の責任を負いません。
      <br />
      4.
      登録会員は、登録会員ＩＤ又はパスワードの盗難があった場合、又は登録会員ＩＤ又はパスワードが第三者に使用されていることが判明した場合には、直ちに当社にその旨を連絡するとともに、当社からの指示がある場合にはこれに従うものとします。
      <br />
      第１８条（権利帰属）
      <br />
      1.
      当社は登録会員からのいかなるフィードバック、要望及び提案について、登録会員の許可無く使用する権限を有するものとします。
      <br />
      2.
      登録会員は、登録会員コンテンツについて、自らが、送信、アップロード等することについて適法な権利を有していること、及び登録会員コンテンツが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
      <br />
      3.
      登録会員は、すべての登録会員コンテンツに対して有する権利を従前どおり保持し、当社が第１７条（コンテンツの取扱い）２項に定める利用権を除き、かかる権利を取得することはありません。
      <br />
      第１９条（コンテンツの取り扱い）
      <br />
      1.
      前条第１項に拘らず、登録会員は、本サービスの利用目的の範囲で、登録会員の責任で、当社提供コンテンツを利用することができます。但し、本サービスが当然に予定している利用態様を超えて当社提供コンテンツを利用（複製、送信、転載、改変等の行為を含みます。）してはなりません。
      <br />
      2.
      登録会員は、当社に対し、当該登録会員の登録会員コンテンツのすべての著作権について、当社が当該登録会員に本サービスを提供するために必要な範囲で、必要な態様（複製、公衆送信、翻案、翻訳、上演、演奏、上映、口述、展示、貸与、二次的利用、再許諾を含むが、これらに限られません。）で、地域、期間の限定なく利用することができる、無償のサブライセンス可能な、非独占的利用を許諾します。また、登録会員は、当社に対し、登録会員コンテンツの著作者人格権を行使しないことについて同意するものとします。
      <br />
      3.
      当社は、法令又は本利用規約の遵守状況等を確認する必要がある場合、登録会員コンテンツの内容を確認することができます。但し、当社はかかる確認を行う義務を負うものではありません。
      <br />
      4.
      登録会員が登録会員コンテンツ等に関し法令若しくは本利用規約に違反し又は違反するおそれのあると当社が認めた場合、その他業務上の必要性がある場合には、当社は、あらかじめ登録会員に通知することなく、当社の管理するサーバから登録会員コンテンツを削除する等の方法により、本サービス又は登録会員コンテンツを利用できないようにすることができます。そのため、登録会員は常に登録会員コンテンツのバックアップを作成しておくことが推奨されます。また、当社は、かかる場合に本サービス又は登録会員コンテンツを利用できなくなったことについて、登録会員に対し一切責任を負いません。
      <br />
      第２０条（登録会員情報の取り扱い）
      <br />
      1. 当社は、本利用規約の他、当社のプライバシーポリシーに従って、登録会員の個人情報を取り扱います。
      <br />
      2.
      当社は、法令による場合、又は、裁判所、行政機関その他公的機関、日本証券業協会等の自主規制機関の命令、要請がある場合には、登録会員の個人情報を開示することができます。
      <br />
      第２１条（ビックデータの取扱い）
      <br />
      1.
      本条は、当社が取り扱う、以下に記載する情報（以下、当社が収集する情報を総称して「当社取得情報」といいます。）について適用されます。
      <br />
      1. 登録事項その他登録会員が当社に提供した情報
      <br />
      2. 登録会員が本サービスの利用・閲覧に伴って自動的に送信される情報
      <br />
      3. 登録会員コンテンツ
      <br />
      2.
      当社は、前項に基づき取得した当社取得情報を利用する場合は、個人として識別できる情報（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む）ではない情報に改変するものとします（以下この改変された情報を「改変データ」といいます。）。
      <br />
      3. 当社は、以下のことを行うため改変データを利用させていただきます。
      <br />
      1. 登録会員に適したサービス等をご提供するため
      <br />
      2. 登録会員からのお問い合わせに対応するため
      <br />
      3.
      本サービス等を安全にご提供するため（これには、本利用規約に違反している登録会員を発見して当該登録会員に通知をしたり、本サービス等を悪用した詐欺や不正アクセスなどの不正行為を調査・検出・予防したり、これらに対応することが含まれます）
      <br />
      4. 本サービス等の改善および新たなサービス等を検討するため
      <br />
      5. 本サービス等のご利用状況等を調査、分析するため
      <br />
      6. 当社の製品やサービスに反映させ、又はマーケティング調査に活用するため
      <br />
      7. 他社へ販売し、又は公開（販売先による公開を含みます。）させて頂くため
      <br />
      4.
      当社は、本条に従って、改変データを適切に取り扱います。改変データを適切に取り扱うため、社内規程および組織を整備し、パーソナルデータを処理する情報システムの安全を確保します。
      <br />
      第２２条（秘密保持）
      <br />
      1.
      当社は、登録会員の事前の書面による承諾がある場合及び本利用規約第１２条（登録会員情報の取り扱い）に定める場合を除き、登録会員コンテンツを秘密に取り扱います。
      <br />
      2.
      登録会員は、本サービスに関連して当社が登録会員に対して秘密に取り扱うことを求めて開示した非公知の情報につき、当社の事前の書面による承諾がある場合を除き、秘密に取り扱います。
      <br />
      3.
      前２項に拘らず、以下の情報については当社及び登録会員は秘密保持義務を負わないものとします。但し、個人情報（生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができる事となるものを含みます。））についてはこの限りではありません。
      <br />
      1. 相手方の開示時点で、既に公知又は一般に入手可能であった情報
      <br />
      2. 相手方の開示後に、自己の責めに帰すべき事由によらず公知又は一般に入手可能になった情報
      <br />
      3. 相手方の開示時点で、既に第三者から秘密保持義務を課されることなく自己が所有していたことを証明し得る情報
      <br />
      4. 相手方の開示後に、秘密情報を用いずに自己が独自に開発したことを証明し得る情報
      <br />
      5. 第三者から秘密保持義務を課されることなく正当に入手した情報
      <br />
      第２３条（禁止事項）
      <br />
      登録会員は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為又は該当すると当社が判断する行為をしてはなりません。
      <br />
      1.
      本サービスを利用する上で必要となる専用のソフトウェア（以下「本アプリ」といいます。）の改変、リバースエンジニアリング（主にソフトウェアの内容を解析して、人間が読み取り可能な形に変換することを指します。）、逆コンパイル、逆アセンブルその他これらに類する行為
      <br />
      2.
      本サービス利用目的を超えて本サービスを使用する行為、又は当社が許諾した利用態様を超えて、本サービスのコンテンツを複製、公衆送信、頒布、譲渡、貸与する行為
      <br />
      3. 本サービスを第三者に利用させる行為又は第三者が利用できる状態にする行為
      <br />
      4. 本サービスを通じ、以下に該当し、又は該当すると当社が判断する情報を送信、アップロード等すること
      <br />
      ・コンピューター・ウイルスその他の有害なコンピューター・プログラムを含む情報
      <br />
      ・当社、本サービスの他の登録会員又はその他の第三者の名誉又は信用を毀損する表現を含む情報
      <br />
      ・反社会的な表現や他人に不快感を与える表現を含む情報
      <br />
      ・チェーンメール等の第三者への情報の拡散を求める情報
      <br />
      5. 本サービスのネットワーク又はシステム等に過度な負担をかける行為、本サービスを用いての営利を目的とする行為
      <br />
      6. 当社のネットワーク又はシステム等に不正にアクセスし、又は不正なアクセスを試みる行為
      <br />
      7. 本サービスの他の登録会員のＩＤ又はパスワードを利用する行為
      <br />
      8. 第三者になりすます行為
      <br />
      9.
      当社、本サービスの他の登録会員又はその他の第三者の知的財産権、肖像権、プライバシー、名誉、その他の権利又は利益を侵害する行為
      <br />
      10. 本サービスの他の登録会員又はその他の第三者の個人情報、登録情報、利用履歴情報等を収集、開示又は提供する行為
      <br />
      11. 宣伝、広告、勧誘、又は営業行為
      <br />
      12. 宗教活動又は宗教団体への勧誘行為
      <br />
      13. 反社会勢力への利益供与
      <br />
      14. 本サービスの運営を妨害するおそれのある行為
      <br />
      15. 当社、本サービスの他の登録会員又はその他の第三者に対する詐欺又は強迫行為
      <br />
      16. 当社、本サービスの他の登録会員又はその他の第三者に不利益、損害、不快感を与える行為
      <br />
      17. 法令、裁判所の確定した判決、決定若しくは命令、又は法令上拘束力のある行政措置に違反する行為
      <br />
      18. 公序良俗に反する行為
      <br />
      19. 当社ウェブサイト上で掲載する本サービス利用に関するルールに抵触する行為
      <br />
      20. 前各号の行為を直接又は間接に惹起し、又は容易にする行為
      <br />
      21. その他、当社が不適切と判断する行為
      <br />
      第２４条（登録会員によるアカウントの削除）
      <br />
      登録会員は、いつでもアカウントを削除し、本サービスの利用を終了することができます。この場合、当社が保有する登録会員ご本人に関する情報は、個人情報保護法その他の関連法規及びプライバシーポリシーに従い処理されます。なお、当社は削除されたアカウント上の情報についてバックアップその他情報を保存する義務を負わないものとします。
      <br />
      第２５条（利用停止等）
      <br />
      1.
      当社は、登録会員が本利用規約に違反し又は違反するおそれがあると認めた場合、あらかじめ登録会員に通知することなく、アカウントを停止することができます。
      <br />
      2.
      当社は、他の事業者が提供するアカウント（以下「他社アカウント」といいます。）から本サービスにログインすることを認める場合がありますが、他社アカウントから本サービスにログインする登録会員は、当該他社アカウントが削除される等により、当該他社アカウントがこれを提供する事業者によって認証されなくなった場合、当該他社アカウントにより本サービスにログインして、本サービスを利用することはできなくなります。当社はかかる事態に対し一切の責任を負いません。
      <br />
      第２６条（登録抹消等）
      <br />
      1.
      当社は、登録会員が、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、登録会員コンテンツを削除し若しくは登録会員について本サービスの利用を停止し、又はアカウント及び登録会員としての登録を抹消、若しくはサービス利用契約を解除することができます。なお、当社は削除されたアカウント上の情報についてバックアップその他情報を保存する義務を負わないものとします。
      <br />
      1. 本利用規約のいずれかの条項に違反した場合
      <br />
      2.
      支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
      <br />
      3. 当社からの問い合わせその他の回答を求める連絡に対して、３０日間以上応答がない場合
      <br />
      4. 第３条（登録）第７項各号に該当する場合
      <br />
      5. その他、当社が本サービスの利用、登録会員としての登録、又はサービス利用契約の継続を適当でないと判断した場合
      <br />
      2.
      前項各号のいずれかの事由に該当した場合、登録会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      <br />
      第２７条（当社に対する補償）
      <br />
      登録会員は、登録会員が法令又は本利用規約に違反して本サービスを利用したことに起因して（かかる趣旨のクレームを第三者より当社が受けた場合を含みます。）、当社が直接的若しくは間接的に何らかの損害、損失又は費用負担（弁護士費用の負担を含みます。）を被った場合、当社の請求にしたがって直ちにこれを賠償又は補償しなければなりません。
      <br />
      第２８条（当社の責任の免除・限定）
      <br />
      1.
      当社は、本サービスに起因して登録会員に生じたあらゆる損害について一切の責任を負いません。但し、本サービスに関する当社と登録会員との間の契約（本利用規約を含みます。）が消費者契約法に定める消費者契約となり、同法が適用される場合、当項の免責規定は適用されません。
      <br />
      2.
      前項但し書に定める場合であっても、当社は、当社の過失（重過失を除きます。以下、当項において同じ。）による債務不履行又は不法行為により登録会員に生じた損害のうち特別な事情から生じた損害（当社又は登録会員が損害発生につき予見し、又は予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失による債務不履行又は不法行為により登録会員に生じた損害の賠償は当社が当該登録会員から当該損害が発生した月に受領した利用料の額を上限とします。
      <br />
      第２９条（契約上の地位の譲渡）
      <br />
      1.
      登録会員は、当社の書面による事前の承諾なく、当社との間の契約上の地位又は本利用規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
      <br />
      2.
      当社は、本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い登録会員との間の契約上の地位、当利用契約及び個別利用規約に基づく権利及び義務ならびに登録会員の登録事項その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録会員はかかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他の事業が移転するあらゆる場合を含むものとします。
      <br />
      第３０条（本利用規約等の変更）
      <br />
      当社は、当社が必要と判断する場合、事前の通知又は告知なく、いつでも、本利用規約及び個別利用規約を変更できるものとします。変更後の本利用規約及び個別利用規約は、当社ウェブサイト内の適宜の場所に掲示された時点からその効力を生じるものとし、登録会員は本利用規約及び個別利用規約の変更後も本サービスを使い続けることにより、変更後の本利用規約及び適用のある個別利用規約に対する有効かつ取消不能な同意をしたものとみなされます。
      <br />
      第３１条（連絡・通知）
      <br />
      1.
      本サービスに関する当社から登録会員への通知、告知その他の連絡は、当社ウェブサイト内の適宜の場所への掲示その他、当社が適当と判断する方法により行ないます。
      <br />
      2.
      本サービスに関する登録会員から当社への通知又は連絡は、当社ウェブサイト内の適宜の場所に設置するお問い合わせフォームの送信又は当社が指定する方法により行っていただきます。
      <br />
      第３２条（分離可能性）
      <br />
      本利用規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本利用規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全な効力を有するものとします。
      <br />
      第３３条（残存条項）
      <br />
      本契約中、各条項に特に指定があるものに加え、第１７条（登録会員ＩＤ及びパスワードの管理）、第１８条（権利帰属）、第１９条（コンテンツの取り扱い）、第２０条（登録会員情報の取り扱い）、第２２条（秘密保持）、第２７条（当社に対する補償）、第２８条（当社の責任の免除・限定）、第２９条（契約上の地位の譲渡）、第３１条（連絡・通知）、第３２条（分離可能性）、本条及び第３４条（準拠法及び管轄裁判所）は、本契約終了後もその効力を維持するものとします。
      <br />
      第３４条（準拠法及び管轄裁判所）
      <br />
      1. 本利用規約は日本語を正文とし、その準拠法は日本法とします。
      <br />
      2.
      本利用規約又はサービス利用契約に起因し、又は関連する一切の紛争については、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。かかる管轄裁判所で得られた勝訴判決は、いずれの国の裁判所においても執行可能とします。
    </div>
  );
};

export default Terms;
