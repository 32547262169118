import config from './config';

export const TokenKey = 'BookKarte/Token';
export const ApiDomain = config.API_DOMAIN;
export const CdnDomain = config.CDN_DOMAIN;
export const StripeApiKey = config.STRIPE_API_KEY;

export const StaffPhotoMaxSize = 500 * 1024; // 500KB
export const EmailRegexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.\-+]*@[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/;
export const TelRegexp = /^\d+$/;
export const PostalCodeRegexp = /^\d+$/;
export const IsbnRegexp = /^\d+(-?\d+){3,4}$/;
export const UserNameMaxLength = 20;
export const UserNameKanaMaxLength = 20;
export const PasswordMinLength = 8;
export const KarteTemplatesLimit = 5;
