import React from 'react';
import classNames from 'classnames';

import { KarteTemplate, optionNames } from '../../interfaces/karte';
import KarteDetail from '../kartes/KarteDetail';
import Button from '../atoms/button/Button';
import Breadcrumbs from '../common/Breadcrumbs';

import CSSModule from './KarteTemplateDetailPage.module.scss';

const breadcrumbs = [
  {
    label: 'ホーム',
    link: '/',
  },
  {
    label: 'マイページ',
    link: '/mypage',
  },
  {
    label: 'マイカルテ',
    link: '/mypage/karte-templates',
  },
];

interface Props {
  karteTemplate?: KarteTemplate;
  templateId: number;
  onClickEditButton: () => void;
}

const KarteTemplateDetailPage: React.FC<Props> = ({ karteTemplate, templateId, onClickEditButton }) => {
  const karteName = optionNames[templateId - 1];
  const pages = [...breadcrumbs, { label: karteName }];

  if (!karteTemplate) {
    return (
      <div className={CSSModule.karteTemplateDetailPage}>
        <Breadcrumbs pages={pages} />
        <div className={CSSModule.contentWrapper}>
          <div className={CSSModule.content}>
            <div className={CSSModule.noItemDescription}>{karteName}は未登録です。</div>
            <div className={CSSModule.button}>
              <Button onClick={onClickEditButton}>新規登録</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={CSSModule.karteTemplateDetailPage}>
      <Breadcrumbs pages={pages} />
      <KarteDetail karte={karteTemplate} />
      <div className={classNames(CSSModule.button, 'my-3')}>
        <Button onClick={onClickEditButton}>編集</Button>
      </div>
    </div>
  );
};

export default KarteTemplateDetailPage;
