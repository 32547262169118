import React from 'react';

import CSSModule from './PriceDescription.module.scss';

const PriceDescription: React.FC = () => (
  <div className={CSSModule.PriceDescription}>
    <div className={CSSModule.PriceDescription__Content}>
      <h2 className={CSSModule.title}>料金</h2>
      <div className={CSSModule.description}>書店員が選書した約1万円分の本がご自宅に届きます。</div>
      <div className={CSSModule.priceArea}>
        <div className={CSSModule.price}>
          <strong>10,000</strong>
          <span className={CSSModule.yen}>円</span>＋税＋送料・選書料
        </div>
        <div className={CSSModule.additionalFee}>総計　税込 12,500円</div>
      </div>
    </div>
  </div>
);

export default PriceDescription;
