import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { StaffCategoryName } from '../../interfaces/staff';
import SelectCategoriesPage from '../../components/staffs/SelectCategoriesPage';

interface Props {
  categories: StaffCategoryName[];
  onChangeCategories: (categories: StaffCategoryName[]) => void;
}

const StaffCategoriesContainer: React.FC<Props> = ({ categories, onChangeCategories }) => {
  const [selectedCategories, setCategories] = useState(categories);

  const navigate = useNavigate();

  const onSubmit = () => {
    onChangeCategories(selectedCategories);
    navigate('/staffs');
  };

  const onClear = () => {
    setCategories([]);
  };

  return (
    <SelectCategoriesPage
      categories={selectedCategories}
      onChangeCategories={setCategories}
      onSubmit={onSubmit}
      onClear={onClear}
    />
  );
};

export default StaffCategoriesContainer;
