import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../../atoms/form/Input';
import Button from '../../atoms/button/Button';
import Alert from '../../atoms/text/Alert';

import CSSModule from './ShipmentForm.module.scss';

export interface FormValues {
  shipmentCode: string;
}

interface Props {
  initialValues?: Partial<FormValues>;
  disabled: boolean;
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
}

const ShipmentForm: React.FC<Props> = ({ initialValues, disabled, onSubmit, onCancel }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: initialValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={CSSModule.shipmentForm}>
      <div className="form-group">
        <Input {...register('shipmentCode', { required: '配送番号をご入力ください。' })} label="配送番号" />
        {errors.shipmentCode && <Alert message={errors.shipmentCode.message} />}
      </div>

      <div className={CSSModule.buttons}>
        <div className={CSSModule.button}>
          <Button type="button" color="default" onClick={onCancel}>
            キャンセル
          </Button>
        </div>
        <div className={CSSModule.button}>
          <Button type="submit" color="primary" disabled={disabled}>
            送信する
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ShipmentForm;
