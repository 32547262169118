import React, { useState } from 'react';
import classNames from 'classnames';

import { SelectedBook, SelectedBookStatus, BookRejection } from '../../interfaces/order';
import Select, { Option } from '../atoms/form/Select';
import Input from '../atoms/form/Input';

import CSSModule from './SelectedBookListItem.module.scss';

const DEFAULT_REJECTED_REASON = '既に持っていて気に入っている';
const SECOND_REJECTED_REASON = '既に持っていて好みではない';
const OTHER_REJECTED_REASON = 'その他';

const RejectionOptions: Option[] = [
  {
    label: DEFAULT_REJECTED_REASON,
    value: DEFAULT_REJECTED_REASON,
  },
  {
    label: SECOND_REJECTED_REASON,
    value: SECOND_REJECTED_REASON,
  },
  {
    label: OTHER_REJECTED_REASON,
    value: OTHER_REJECTED_REASON,
  },
];

interface Props {
  index: number;
  selectedBook: SelectedBook;
  editable?: boolean;
  onReject?: (rejection: BookRejection) => void;
  onApprove?: (bookId: string) => void;
}

const SelectedBookListItem: React.FC<Props> = ({ index, selectedBook, editable, onApprove, onReject }) => {
  const isRejected = selectedBook.status === SelectedBookStatus.Rejected;
  // set `other` option when already answered value is not included in existing options
  const hasInitiallyOtherReason =
    selectedBook.status === SelectedBookStatus.Rejected &&
    !RejectionOptions.some(option => option.value === selectedBook.rejectedReason);
  const initialRejectedReason = hasInitiallyOtherReason
    ? OTHER_REJECTED_REASON
    : selectedBook.rejectedReason || DEFAULT_REJECTED_REASON;

  const isNonActive = !editable && isRejected;

  const [currentStatus, setCurrentStatus] = useState(selectedBook.status);
  const [selectedReason, setSelectedReason] = useState(initialRejectedReason);
  const [reason, setReason] = useState<string | null>(selectedBook.rejectedReason || initialRejectedReason);

  const onApproveBook = () => {
    setCurrentStatus(SelectedBookStatus.Approved);
    onApprove && onApprove(selectedBook.id);
  };

  const onRejectBook = () => {
    setCurrentStatus(SelectedBookStatus.Rejected);
    onReject &&
      reason &&
      onReject({
        id: selectedBook.id,
        reason,
      });
  };

  const onSelectReason = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedReason(e.currentTarget.value);

    if (e.currentTarget.value === OTHER_REJECTED_REASON) {
      setReason(null);
    } else {
      setReason(e.currentTarget.value);
    }

    onReject?.({
      id: selectedBook.id,
      reason: e.currentTarget.value || '',
    });
  };

  const onChangeOtherReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.currentTarget.value);
    onReject?.({
      id: selectedBook.id,
      reason: e.currentTarget.value,
    });
  };

  return (
    <div className={classNames(CSSModule.selectedBookListItem, { [CSSModule.nonActive]: isNonActive })}>
      <div className={classNames(CSSModule.content)}>
        {index}. {selectedBook.title}
      </div>
      {isNonActive && <div className={CSSModule.rejectedReason}>拒否済み: {selectedBook.rejectedReason}</div>}
      {editable && (
        <div className={CSSModule.operation}>
          <button
            type="button"
            className={classNames(CSSModule.button, {
              [CSSModule.isActive]: currentStatus === SelectedBookStatus.Approved,
            })}
            onClick={onApproveBook}
          >
            ○
          </button>
          <button
            type="button"
            className={classNames(CSSModule.button, {
              [CSSModule.isActive]: currentStatus === SelectedBookStatus.Rejected,
            })}
            onClick={onRejectBook}
          >
            ×
          </button>
        </div>
      )}
      {editable && currentStatus === SelectedBookStatus.Rejected && (
        <div className={CSSModule.rejection}>
          <div className="my-2">
            <Select
              name="rejectedReason"
              id={`rejectedReasonSelect${selectedBook.id}`}
              options={RejectionOptions}
              label="非承認の理由"
              onChange={onSelectReason}
            />
          </div>
          {selectedReason === OTHER_REJECTED_REASON && (
            <div className="my-2">
              <Input
                name="otherReason"
                value={reason || ''}
                id={`otherReasonSelect${selectedBook.id}`}
                placeholder="理由をご入力ください。"
                onChange={onChangeOtherReason}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectedBookListItem;
