import React from 'react';
import { Link } from 'react-router-dom';

import FirstViewImage from '../../assets/welcome/eyecatch.png';
import GiftBanner from '../../assets/welcome/gift-banner.png';

import CSSModule from './FirstView.module.scss';

const FirstView: React.FC = () => (
  <div className={CSSModule.FirstView}>
    <img
      src={FirstViewImage}
      alt="あなたにぴったりの本を選書してもらえる　ブックカルテ"
      className={CSSModule.FirstView__Image}
    />
    <Link to="/gifts" className={CSSModule.FirstView__Link}>
      <img src={GiftBanner} alt="選書してもらう体験をプレゼントしてみませんか？詳しくはこちら" />
    </Link>
  </div>
);

export default FirstView;
