import ApolloClient, { gql } from 'apollo-boost';
import { isObject } from 'lodash-es';
import Cookie from 'js-cookie';

import { ApiDomain, TokenKey } from '../app.constants';

const isJsonObject = (original: JsonObject[keyof JsonObject]): original is JsonObject => {
  return isObject(original);
};

const isArray = (original: JsonObject[keyof JsonObject]): original is JsonValue[] | JsonObject[] => {
  return Array.isArray(original);
};

type JsonValue = string | number | boolean;

interface JsonObject {
  [key: string]: JsonValue | JsonObject | Array<JsonValue | JsonObject>;
}

class API {
  public static async query(query: string, params: any = {}) {
    const response = await this.request(query, params);
    return this.camelizeKeys(response.data);
  }

  private static client() {
    const headers = Cookie.get(TokenKey) ? { Authorization: Cookie.get(TokenKey) } : {};
    return new ApolloClient({
      name: 'react-web-client',
      uri: `${ApiDomain}/graphql`,
      fetchOptions: {
        fetchOptions: {
          mode: 'no-cors',
        },
      },
      headers: {
        ...headers,
      },
    });
  }

  private static async request(query: string, variables: any = {}) {
    return this.client().query({
      query: gql(query),
      variables,
      fetchPolicy: 'no-cache',
    });
  }

  private static camelizeKeys(original: JsonObject) {
    const params: JsonObject = JSON.parse(JSON.stringify(original));
    const camelizedParams: JsonObject = {};
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (isArray(value)) {
        value.forEach((element: JsonObject | JsonValue, i: number) => {
          if (isJsonObject(element)) {
            value[i] = this.camelizeKeys(element);
          }
        });
      } else if (isJsonObject(value)) {
        params[key] = this.camelizeKeys(value);
      }
      const newKey = key.replace(/(_[a-z0-9])/g, s => `${s.charAt(1).toUpperCase()}`);
      camelizedParams[newKey] = params[key];
    });
    return camelizedParams as any;
  }
}

export default API;
