import { isEqual } from 'lodash-es';
import React, { useState } from 'react';

import { ListedBookOrder, BookRejection, isApprovedBook } from '../../interfaces/order';
import Alert from '../atoms/text/Alert';
import Button from '../atoms/button/Button';
import UiStackTemplate from '../common/UiStackTemplate';
import ActionMessage from './ActionMessage';
import OrderHeader from './OrderHeader';
import StaffLink from './StaffLink';
import KarteLink from './KarteLink';
import SelectedBookList from './SelectedBookList';

import CSSModule from './ListedOrderItem.module.scss';

interface Props {
  bookOrder: ListedBookOrder;
  isLoading: boolean;
  onSubmitReview: (bookOrderId: string, approvedIds: string[], rejections: BookRejection[]) => void;
}

const ListedOrderItem: React.FC<Props> = ({ bookOrder, isLoading, onSubmitReview }) => {
  const approvedBooks = bookOrder.selectedBooks.filter(isApprovedBook);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [approvedIds, setApprovedIds] = useState(approvedBooks.map(book => book.id));
  const [rejections, setRejections] = useState<BookRejection[]>([]);

  const selectedBookIds = bookOrder.selectedBooks.map(book => book.id);
  const rejectedBookIds = rejections.map(rejection => rejection.id);
  const isAllSelected = isEqual(selectedBookIds.sort(), [...approvedIds, ...rejectedBookIds].sort());

  const toggleBookList = () => setIsOpen(!isOpen);

  const onApproveBook = (bookId: string) => {
    setApprovedIds([...approvedIds.filter(approvedId => approvedId !== bookId), bookId]);
    setRejections(rejections.filter(rejection => rejection.id !== bookId));
  };

  const onRejectBook = (rejection: BookRejection) => {
    setApprovedIds(approvedIds.filter(approvedId => approvedId !== rejection.id));
    setRejections([...rejections.filter(r => r.id !== rejection.id), rejection]);
  };

  const onSubmitForm = () => {
    setIsSubmitted(true);

    if (isAllSelected) {
      onSubmitReview(bookOrder.id, approvedIds, rejections);
    }
  };

  return (
    <div className={CSSModule.listedOrderItem}>
      <ActionMessage>
        選書リストが届いていますので、
        <br />
        フィードバックを送信してください、
      </ActionMessage>
      <OrderHeader id={bookOrder.id} time={bookOrder.orderedAt} status={bookOrder.status} />
      <StaffLink staff={bookOrder.staff} />
      <UiStackTemplate isLoading={isLoading} error={null}>
        <SelectedBookList
          selectedBooks={bookOrder.selectedBooks}
          editable
          isOpen={isOpen}
          toggleBookList={toggleBookList}
          onApprove={onApproveBook}
          onReject={onRejectBook}
        />
      </UiStackTemplate>
      <KarteLink staffId={bookOrder.staff.id} bookOrderId={bookOrder.id} isDraft={false} />
      {isSubmitted && !isAllSelected && <Alert message="承認・非承認が未選択の本があります。" />}
      <div className={CSSModule.button}>
        <Button onClick={onSubmitForm} disabled={isLoading}>
          送信
        </Button>
      </div>
    </div>
  );
};

export default ListedOrderItem;
