import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Loading from 'src/components/common/Loading';
import useMutation from 'src/hooks/common/useMutation';
import useCurrentUser from 'src/hooks/store/useCurrentUser';
import UrlRedirectChecker from 'src/utils/UrlRedirectChecker';

const UpdatePaymentMethodQuery = `mutation updatePaymentMethod(
    $setupIntentId: String!,
    $clientSecret: String!
  ) {
    updatePaymentMethod(input: {
      setupIntentId: $setupIntentId,
      clientSecret: $clientSecret
    }) {
      paymentMethodId
      user { cardBrand cardLast4 cardExpYear cardExpMonth }
    }
  }`;

interface UpdatePaymentMethodParams {
  setupIntentId: string;
  clientSecret: string;
}

interface UpdatePaymentMethodResult {
  updatePaymentMethod: {
    paymentMethodId: string;
    user: {
      cardBrand: string;
      cardLast4: string;
      cardExpYear: number;
      cardExpMonth: number;
    };
  };
}

const EditPaymentCompletedPage: React.FC = () => {
  const isFetched = useRef<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [, setCurrentUser] = useCurrentUser();
  const { executeMutation: updatePaymentMethod } = useMutation<UpdatePaymentMethodParams, UpdatePaymentMethodResult>();

  const returnTo = searchParams.get('returnTo');
  const setupIntentId = searchParams.get('setup_intent');
  const clientSecret = searchParams.get('setup_intent_client_secret');
  const redirectStatus = searchParams.get('redirect_status');

  useEffect(() => {
    if (!setupIntentId || !clientSecret || redirectStatus !== 'succeeded' || !returnTo || isFetched.current) {
      return;
    }

    // avoid to fetch twice after calling setCurrentUser
    isFetched.current = true;

    const [isSameHost, returnUrl] = UrlRedirectChecker.isSameHost(returnTo);
    if (!isSameHost) {
      return;
    }

    const run = async () => {
      const result = await updatePaymentMethod(UpdatePaymentMethodQuery, { setupIntentId, clientSecret });
      const cardInfo = result?.updatePaymentMethod.user ?? null;

      if (result) {
        setCurrentUser(user => user && { ...user, ...cardInfo });
      }

      navigate({
        pathname: returnUrl.pathname,
        search: returnUrl.search,
      });
    };

    run();
  }, [isFetched, returnTo, setupIntentId, clientSecret, redirectStatus, updatePaymentMethod, navigate, setCurrentUser]);

  return <Loading />;
};

export default EditPaymentCompletedPage;
