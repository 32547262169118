import React from 'react';

import AppTemplate from '../../components/templates/AppTemplate';
import ProfileDetail from '../../components/mypage/ProfileDetail';
import Authenticated from '../common/Authenticated';
import useCurrentUser from '../../hooks/store/useCurrentUser';

const ProfileDetailContainer: React.FC = () => {
  const [user] = useCurrentUser();

  return (
    <AppTemplate>
      <Authenticated>{user && <ProfileDetail user={user} />}</Authenticated>
    </AppTemplate>
  );
};

export default ProfileDetailContainer;
