import { generateColumnQuery } from '.';

export const userColumns = ['id', 'name', 'nameKana', 'photo', 'email'];

export const meColumns = [
  ...userColumns,
  'photoFilename',
  'tel',
  'postalCode',
  'address',
  'shippingPostalCode',
  'shippingAddress',
  'cardBrand',
  'cardLast4',
  'cardExpYear',
  'cardExpMonth',
  'createdAt',
  'isAdmin',
];

export const getMeQuery = `
  query getMe {
    me { ${generateColumnQuery(meColumns)} }
  }
`;

export const registerQuery = `
  mutation registerUser(
    $user: UserInput!
  ) {
    registerUser(input: { user: $user }) {
      user { ${generateColumnQuery(meColumns)} }
      token
      error
    }
  }
`;

export const updateUserQuery = `
  mutation updateUser(
    $user: UserInput!
  ) {
    updateUser(input: { user: $user }) {
      user { ${generateColumnQuery(meColumns)} }
      error
    }
  }
`;

export const unregisterQuery = 'mutation unregisterUser { unregisterUser(input: {}) { error } }';

export const getAccessTokenQuery = `
  query getAccessToken(
    $email: String!,
    $password: String!
  ) {
    accessToken(
      email: $email,
      password: $password
    ) { token }
  }
`;

export const getUserPresignedUrlQuery = `
  query getUserPresignedUrl(
    $extname: String!
  ) {
    userPresignedUrl(extname: $extname) {
      presignedUrl filename expiresAt
    }
  }
`;
