import React from 'react';

import AboutImage from '../../assets/welcome/about.png';

import CSSModule from './ServiceDescription.module.scss';

const ServiceDescription: React.FC = () => (
  <div className={CSSModule.serviceDescription}>
    <img src={AboutImage} alt="Welcome!" className={CSSModule.bgCover} />
  </div>
);

export default ServiceDescription;
