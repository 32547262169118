import { AdminStaff } from '../../interfaces/staff';
import { generateColumnQuery } from './';
import { karteQuestionTemplateColumns } from './karteQueries';

export const columnsForIntroduction = [
  'id',
  'name',
  'bookStoreName',
  { photos: ['src'], karteQuestionTemplate: karteQuestionTemplateColumns },
];

const listColumns = [...columnsForIntroduction, 'profile', 'monthlyCapacity', { categories: ['name', 'categoryType'] }];

const detailColumns = [...listColumns, 'slogan'];

const adminListColumns = [
  'id',
  'name',
  'bookStoreName',
  'email',
  'monthlyLimit',
  'monthlyCapacity',
  'completedOrderCount',
  'unlistedOrderCount',
  'undeliveredOrderCount',
];

const adminDetailColumns = [
  ...adminListColumns,
  'slogan',
  'profile',
  {
    photos: ['id', 'src'],
    categories: ['name'],
  },
  'searchable',
  'adminRecommendationNumber',
];

export const getStaffIntroductionQuery = `
  query getStaffIntroduction(
    $categories: [String!],
    $keyword: String,
    $hasInventory: Boolean,
    $page: Int,
    $size: Int,
    $randomize: Int!
  ) {
    staffs(
      categories: $categories,
      keyword: $keyword,
      hasInventory: $hasInventory,
      page: $page,
      size: $size,
      randomize: $randomize
    ) { ${generateColumnQuery(columnsForIntroduction)} }
    bookStoreCount
  }`;

export const getStaffListQuery = `
  query getStaffList(
    $categories: [String!],
    $keyword: String,
    $hasInventory: Boolean,
    $page: Int,
    $size: Int,
    $randomize: Int!
  ) {
    staffs(
      categories: $categories,
      keyword: $keyword,
      hasInventory: $hasInventory,
      page: $page,
      size: $size,
      randomize: $randomize
    ) { ${generateColumnQuery(listColumns)} }
    staffCount(
      categories: $categories,
      keyword: $keyword
    )
  }`;

export const getStaffDetailQuery = `
  query getStaffDetail(
    $id: String!
  ) {
    staff(id: $id) { ${generateColumnQuery(detailColumns)} }
  }
`;

export interface GetAdminStaffListParams {
  page: number;
  size: number;
}

export interface GetAdminStaffListResult {
  adminStaffs: AdminStaff[];
  staffCount: number;
}

export const getAdminStaffListQuery = `
  query getAdminStaffList(
    $page: Int,
    $size: Int
  ) {
    adminStaffs(page: $page, size: $size) { ${generateColumnQuery(adminListColumns)} }
    staffCount
  }
`;

export interface GetAdminStaffDetailParams {
  id: string;
}

export interface GetAdminStaffDetailResult {
  adminStaff: AdminStaff;
}

export const getAdminStaffDetailQuery = `
  query getAdminStaffDetail(
    $id: String!
  ) {
    adminStaff(id: $id) { ${generateColumnQuery(adminDetailColumns)} }
  }
`;
