import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import CSSModule from './MenuLink.module.scss';

interface Props {
  label: string;
  to?: string;
  onClick?: () => void;
}

const MenuLink: React.FC<Props> = ({ label, to, onClick }) => {
  if (onClick) {
    return (
      <div className={CSSModule.link} onClick={onClick}>
        {label}
      </div>
    );
  }

  if (!to) {
    throw new Error("`to` is required when you didn't pass `onClick` prop.");
  }

  return (
    <Link to={to} className={classNames(CSSModule.link, 'linkNavigator')}>
      {label}
    </Link>
  );
};

export default MenuLink;
