import React, { useRef } from 'react';

import CSSModule from './FileChangeButton.module.scss';
import classNames from 'classnames';

interface Props {
  name: string;
  onChange: (file: File | null) => void;
  className?: string;
  children: React.ReactNode;
}

const FileChangeButton: React.FC<Props> = ({ name, onChange, className, children }) => {
  const ref = useRef<HTMLInputElement>(null);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    ref.current && ref.current.click();
  };

  const onFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files && e.currentTarget.files[0];
    onChange(file || null);
  };

  const classes = classNames(CSSModule.button, className);

  return (
    <>
      <div onClick={onClick} className={classes}>
        {children}
      </div>
      <input type="file" name={name} ref={ref} onChange={onFileChanged} className="d-none" />
    </>
  );
};

export default FileChangeButton;
