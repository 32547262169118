import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import CSSModule from './StickyButton.module.scss';

interface Props {
  text: string;
  to: string;
  disabled?: boolean;
}

const StickyButton: React.FC<Props> = ({ text, to, disabled }) => (
  <div className={CSSModule.stickyButton}>
    {disabled ? (
      <div className={classNames(CSSModule.button, CSSModule.disabled)}>{text}</div>
    ) : (
      <Link to={to} className={CSSModule.button}>
        {text}
      </Link>
    )}
  </div>
);

export default StickyButton;
