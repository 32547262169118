import React from 'react';

import { KarteTemplate, Karte, KarteQuestionTemplate } from '../../interfaces/karte';
import KarteTemplateSelect from '../karteTemplates/KarteTemplateSelect';
import KarteForm, { FormValues } from './KarteForm';

import CSSModule from './EditKartePage.module.scss';

interface Props {
  karteTemplates: KarteTemplate[];
  questionTemplate: KarteQuestionTemplate;
  selectedTemplate?: KarteTemplate;
  onSelectTemplate: (selectedTemplate?: KarteTemplate) => void;
  karte: Karte | null;
  disabled: boolean;
  error: string | null;
  onSubmit: (values: FormValues) => void;
  onSave: (values: FormValues) => void;
  onAutoSave: (values: FormValues) => void;
}

const EditKartePage: React.FC<Props> = ({
  karteTemplates,
  questionTemplate,
  selectedTemplate,
  onSelectTemplate,
  karte,
  disabled,
  error,
  onSubmit,
  onSave,
  onAutoSave,
}) => {
  return (
    <div className={CSSModule.editKartePage}>
      <p className={CSSModule.note}>
        作動によっては、データが消失する場合があります。初回ご利用の際は、あらかじめメモなどに入力いただいたものをコピーアンドペーストすることをおすすめしております。またこまめな下書き保存をお願いいたします。
      </p>
      {karteTemplates.length > 0 && (
        <KarteTemplateSelect
          selectedQuestoinTemplateId={questionTemplate.id}
          selectedTemplate={selectedTemplate}
          templates={karteTemplates}
          onChange={onSelectTemplate}
        />
      )}
      <KarteForm
        karte={karte}
        selectedTemplate={selectedTemplate}
        questions={questionTemplate.questions}
        disabled={disabled}
        error={error ? new Error(error) : null}
        onSubmit={onSubmit}
        onSave={onSave}
        onAutoSave={onAutoSave}
      />
    </div>
  );
};

export default EditKartePage;
