import { useNavigate } from 'react-router';

import { DraftBookCouponLetter } from 'src/interfaces/order';
import Button from 'src/components/atoms/button/Button';

import CSSModule from './ApplyGiftResult.module.scss';

interface Props {
  draftBookCouponLetter: DraftBookCouponLetter;
}

const ApplyGiftResult: React.FC<Props> = ({ draftBookCouponLetter }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/mypage');
  };

  return (
    <div className={CSSModule.ApplyGiftResult}>
      <h2 className={CSSModule.ApplyGiftResult__Heading}>ギフトを送信しました</h2>
      {draftBookCouponLetter.shipmentType === 'email' && (
        <p>{draftBookCouponLetter.email} 宛にギフトを送付しました。</p>
      )}
      {draftBookCouponLetter.shipmentType !== 'email' && (
        <div>
          <p className={CSSModule.ApplyGiftResult__Description}>下記住所にギフトを送付いたします。</p>
          <div className={CSSModule.ApplyGiftResult__Address}>
            <p>〒{draftBookCouponLetter.shippingAddress.postalCode}</p>
            <p>{draftBookCouponLetter.shippingAddress.address}</p>
          </div>
        </div>
      )}
      <Button onClick={onClick}>マイページTOPへ</Button>
    </div>
  );
};

export default ApplyGiftResult;
