import React from 'react';
import classNames from 'classnames';

import CSSModule from './ToggleHeader.module.scss';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  children: React.ReactNode;
}

const ToggleHeader: React.FC<Props> = ({ isOpen, toggle, children }) => (
  <div className={classNames(CSSModule.ToggleHeader, { [CSSModule.isOpen]: isOpen })} onClick={() => toggle()}>
    <div>{children}</div>
  </div>
);

export default ToggleHeader;
