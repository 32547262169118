import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUser } from '../../actions/auth';
import { isFunctionalUpdate, SetState } from '../../interfaces/store';
import { User } from '../../interfaces/user';
import { RootState } from '../../reducers';

interface SelectedState {
  user: User | null;
}

const useCurrentUser = () => {
  const dispatch = useDispatch();
  const { user } = useSelector<RootState, SelectedState>(state => ({
    user: state.auth.user,
  }));

  const setCurrentUser: SetState<User | null> = useCallback(
    argument => {
      if (isFunctionalUpdate(argument)) {
        dispatch(updateCurrentUser({ user: argument(user) }));
      } else {
        dispatch(updateCurrentUser({ user: argument }));
      }
    },
    [dispatch, user],
  );

  return [user, setCurrentUser] as const;
};

export default useCurrentUser;
