import React from 'react';
import { Link } from 'react-router-dom';

import { Staff } from '../../interfaces/staff';
import TextWithEllipsis from '../atoms/text/TextWithEllipsis';

import CSSModule from './StaffThumbnail.module.scss';

interface Props {
  staff: Staff;
}

const StaffThumbnail: React.FC<Props> = ({ staff }) => (
  <Link to={`/staffs/${staff.id}`} className={CSSModule.staffThumbnail}>
    {staff.photos[0] && <img src={staff.photos[0].src} alt={staff.name} className={CSSModule.staffIcon} />}
    <div className={CSSModule.bookStoreName}>
      <TextWithEllipsis text={staff.bookStoreName} />
    </div>
  </Link>
);

export default StaffThumbnail;
