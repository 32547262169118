import React from 'react';
import { Link } from 'react-router-dom';

import { Staff } from '../../interfaces/staff';
import StaffCategoryList from './StaffCategoryList';
import TextWithEllipsis from '../atoms/text/TextWithEllipsis';
import Button from '../atoms/button/Button';

import CSSModule from './StaffListItem.module.scss';

const MAX_PROFILE_TEXT_LENGTH = 100;
const MAX_CATEGORY_LENGTH = 5;

interface Props {
  staff: Staff;
}

const StaffListItem: React.FC<Props> = ({ staff }) => {
  const profileText =
    staff.profile.length > MAX_PROFILE_TEXT_LENGTH
      ? `${staff.profile.slice(0, MAX_PROFILE_TEXT_LENGTH)}...`
      : staff.profile;

  const categories = staff.categories
    .filter(category => category.categoryType === 'genre')
    .map(category => category.name)
    .slice(0, MAX_CATEGORY_LENGTH);

  return (
    <Link to={`/staffs/${staff.id}`} className={CSSModule.StaffListItem}>
      <div className={CSSModule.StaffListItem__StaffInfo}>
        <div className={CSSModule.StaffListItem__StaffPhoto}>
          <img
            src={staff.photos[0] && staff.photos[0].src}
            alt={staff.name}
            className={CSSModule.StaffListItem__Photo}
          />
        </div>
        <div className={CSSModule.StaffListItem__StaffProfile}>
          <div className={CSSModule.StaffListItem__BookStoreName}>
            <TextWithEllipsis text={staff.bookStoreName} />
          </div>
          <div className={CSSModule.StaffListItem__StaffName}>
            <TextWithEllipsis text={staff.name} />
          </div>
        </div>
      </div>
      <div className={CSSModule.StaffListItem__StaffProfileText}>{profileText}</div>
      <div className={CSSModule.StaffListItem__CategoryList}>
        <StaffCategoryList categories={categories} />
      </div>
      <div className={CSSModule.StaffListItem__Button}>
        <Button size="small">あと{staff.monthlyCapacity}名</Button>
      </div>
    </Link>
  );
};

export default StaffListItem;
