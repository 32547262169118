import React from 'react';

import { BookOrder } from 'src/interfaces/order';
import ListPageTemplate from 'src/components/admin/common/ListPageTemplate';
import AdminOrderList from 'src/components/admin/orders/AdminOrderList';
import Button from 'src/components/atoms/button/Button';

interface Props {
  bookOrders: BookOrder[];
  isLoading: boolean;
  error: Error | null;
  page: number;
  size: number;
  totalCount: number;
  disableDownload: boolean;
  disableKarteDownload: boolean;
  onClickCsvDownloadButton: () => void;
  onClickKarteCsvDownloadButton: () => void;
  onClickRow: (bookOrderId: string) => void;
  onChangePage: (page: number) => void;
}

const AdminOrderListPage: React.FC<Props> = ({
  bookOrders,
  isLoading,
  error,
  page,
  size,
  totalCount,
  disableDownload,
  disableKarteDownload,
  onClickCsvDownloadButton,
  onClickKarteCsvDownloadButton,
  onClickRow,
  onChangePage,
}) => {
  return (
    <ListPageTemplate
      title="注文管理"
      isLoading={isLoading}
      error={error}
      page={page}
      size={size}
      totalCount={totalCount}
      isEmpty={bookOrders.length === 0}
      onChangePage={onChangePage}
      rightSideButton={
        <>
          <Button color="default" disabled={disableDownload} onClick={onClickCsvDownloadButton}>
            注文一覧CSV出力
          </Button>
          <Button color="default" disabled={disableKarteDownload} onClick={onClickKarteCsvDownloadButton}>
            カルテ一覧CSV出力
          </Button>
        </>
      }
    >
      <AdminOrderList bookOrders={bookOrders} onClickRow={onClickRow} />
    </ListPageTemplate>
  );
};

export default AdminOrderListPage;
