import React, { useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import Input from '../atoms/form/Input';
import Checkbox from '../atoms/form/Checkbox';
import Alert from '../atoms/text/Alert';
import Button from '../atoms/button/Button';
import {
  EmailRegexp,
  TelRegexp,
  PostalCodeRegexp,
  PasswordMinLength,
  UserNameMaxLength,
  UserNameKanaMaxLength,
} from '../../app.constants';
import Terms from './Terms';

import CSSModule from './SignUpForm.module.scss';

export interface FormValues {
  name: string;
  nameKana: string;
  email: string;
  emailConfirmation: string;
  password: string;
  passwordConfirmation: string;
  tel: string;
  postalCode: string;
  address: string;
  shippingPostalCode: string;
  shippingAddress: string;
  agreeTerms: boolean;
}

interface Props {
  disabled: boolean;
  error: Error | null;
  onSubmit: (values: FormValues) => void;
}

const SignUpForm: React.FC<Props> = ({ disabled, error, onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    watch,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      agreeTerms: false,
    },
  });

  const toggleShippingAddress = () => {
    setIsOpen(prevState => !prevState);
  };

  const values = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('name', {
          required: 'お名前をご入力ください。',
          maxLength: {
            value: UserNameMaxLength,
            message: `お名前は${UserNameMaxLength}文字以内でご入力ください。`,
          },
        })}
        label="お名前(20文字以内)"
        required
      />
      {errors.name && <Alert message={errors.name.message} />}
      <Input
        {...register('nameKana', {
          required: 'フリガナをご入力ください。',
          maxLength: {
            value: UserNameKanaMaxLength,
            message: `フリガナは${UserNameKanaMaxLength}文字以内でご入力ください。`,
          },
        })}
        label="フリガナ(20文字以内)"
        required
      />
      {errors.nameKana && <Alert message={errors.nameKana.message} />}
      <Input
        {...register('email', {
          required: 'Eメールアドレスをご入力ください。',
          pattern: {
            value: EmailRegexp,
            message: 'Eメールアドレスの形式が不正です。',
          },
        })}
        id="registrationEmail"
        label="Eメールアドレス"
        required
      />
      {errors.email && <Alert message={errors.email.message} />}
      <Input
        {...register('emailConfirmation', { required: 'Eメールアドレスを再度ご入力ください。' })}
        label="Eメールアドレス再入力"
        required
      />
      {values.email && values.emailConfirmation && values.email !== values.emailConfirmation && (
        <Alert message="入力されたEメールアドレスが異なります。" />
      )}
      {errors.emailConfirmation && <Alert message={errors.emailConfirmation.message} />}
      <Input
        {...register('password', {
          required: 'パスワードをご入力ください。',
          minLength: {
            value: PasswordMinLength,
            message: `パスワードは最低${PasswordMinLength}文字以上ご入力ください。`,
          },
        })}
        id="registrationPassword"
        type="password"
        label="パスワード"
        required
      />
      {errors.password && <Alert message={errors.password.message} />}
      <Input
        {...register('passwordConfirmation', { required: 'パスワードを再度ご入力ください。' })}
        type="password"
        label="パスワード再入力"
        required
      />
      {errors.passwordConfirmation && <Alert message={errors.passwordConfirmation.message} />}
      {values.password && values.passwordConfirmation && values.password !== values.passwordConfirmation && (
        <Alert message="入力されたパスワードが異なります。" />
      )}
      <Input
        {...register('tel', {
          required: '電話番号をご入力ください。',
          pattern: { value: TelRegexp, message: '電話番号はハイフンなしでご入力ください。' },
        })}
        label="電話番号(ハイフンなしでご記入ください)"
        required
      />
      {errors.tel && <Alert message={errors.tel.message} />}
      <Input
        {...register('postalCode', {
          required: '郵便番号をご入力ください。',
          pattern: { value: PostalCodeRegexp, message: '郵便番号はハイフンなしでご入力ください。' },
        })}
        label="郵便番号(ハイフンなしでご記入ください)"
        required
      />
      {errors.postalCode && <Alert message={errors.postalCode.message} />}
      <Input {...register('address', { required: 'ご自宅住所をご入力ください。' })} label="ご自宅住所" required />
      {errors.address && <Alert message={errors.address.message} />}
      {!isOpen && (
        <div className={CSSModule.toggleBox} onClick={toggleShippingAddress}>
          <div className={CSSModule.toggleText}>別の配送先を設定する</div>
        </div>
      )}
      {isOpen && (
        <div>
          <Input
            {...register('shippingPostalCode', {
              required: isOpen && '郵便番号をご入力ください。',
              pattern: { value: PostalCodeRegexp, message: '郵便番号はハイフンなしでご入力ください。' },
            })}
            label="郵便番号(ハイフンなしでご記入ください)"
            required
          />
          {errors.shippingPostalCode && <Alert message={errors.shippingPostalCode.message} />}
          <Input
            {...register('shippingAddress', { required: isOpen && 'お届け先住所をご入力ください。' })}
            label="お届け先住所"
            required
          />
          {errors.shippingAddress && <Alert message={errors.shippingAddress.message} />}
          <div className={CSSModule.toggleBox} onClick={toggleShippingAddress}>
            <div className={classNames(CSSModule.toggleText, { [CSSModule.isOpen]: isOpen })}>閉じる</div>
          </div>
        </div>
      )}
      <hr />
      <div>
        <Terms />
        <Checkbox name="agreeTerms" onChange={isChecked => setValue('agreeTerms', isChecked)} value={values.agreeTerms}>
          利用規約に同意します。
        </Checkbox>
        {errors.agreeTerms && <Alert message={errors.agreeTerms.message} />}
      </div>
      <div className="mt-3">
        {error && <Alert error={error} />}
        <Button type="submit" disabled={disabled || isSubmitting || !values.agreeTerms}>
          次へ
        </Button>
      </div>
    </form>
  );
};

export default SignUpForm;
