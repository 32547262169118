import React from 'react';
import classNames from 'classnames';

import { StaffCategoryType, StaffCategoryName } from '../../../interfaces/staff';

import CSSModule from './StaffCategoryItem.module.scss';

interface Props {
  category: StaffCategoryType;
  isSelected: boolean;
  onClick: (category: StaffCategoryName) => void;
}

const StaffCategoryItem: React.FC<Props> = ({ category, isSelected, onClick }) => {
  const onClickItem = () => {
    onClick(category.value);
  };

  return (
    <div className={CSSModule.staffCategoryItem} onClick={onClickItem}>
      <img
        src={require(`../../../assets/categories/${category.value}.svg`)}
        alt={category.label}
        className={classNames(CSSModule.icon, { [CSSModule.isSelected]: isSelected })}
      />
      <div className={CSSModule.label}>{category.label}</div>
    </div>
  );
};

export default StaffCategoryItem;
