import React from 'react';

import AppTemplate from '../templates/AppTemplate';

import CSSModule from './NotFoundPage.module.scss';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  return (
    <AppTemplate>
      <div className={CSSModule.notFoundPage}>
        <div className={CSSModule.content}>
          <h2 className={CSSModule.title}>ページが見つかりませんでした。</h2>
          <Link to="/" className={CSSModule.link}>
            ホームへ戻る
          </Link>
        </div>
      </div>
    </AppTemplate>
  );
};

export default NotFoundPage;
