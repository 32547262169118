import React from 'react';
import Linkify from 'linkify-react';

import { Staff } from '../../interfaces/staff';
import StaffPhotoList from './StaffPhotoList';
import StaffCategoryList from './StaffCategoryList';
import TextWithLineBreak from '../atoms/text/TextWithLineBreak';

import CSSModule from './StaffDetail.module.scss';

interface Props {
  staff: Staff;
}

const StaffDetail: React.FC<Props> = ({ staff }) => (
  <div className={CSSModule.StaffDetail}>
    <StaffPhotoList photos={staff.photos} />
    <div className={CSSModule.StaffDetail__BookStoreName}>{staff.bookStoreName}</div>
    <div className={CSSModule.StaffDetail__StaffName}>{staff.name}</div>
    <div className={CSSModule.StaffDetail__Slogan}>"{staff.slogan}"</div>
    <div className={CSSModule.StaffDetail__Categories}>
      <StaffCategoryList categories={staff.categories.map(({ name }) => name)} />
    </div>
    <div className={CSSModule.StaffDetail__StaffProfile}>
      <h3 className={CSSModule.StaffDetail__StaffProfileTitle}>プロフィール</h3>
      <TextWithLineBreak>
        <Linkify options={{ target: '_blank' }}>{staff.profile}</Linkify>
      </TextWithLineBreak>
    </div>
  </div>
);

export default StaffDetail;
