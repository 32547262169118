import React from 'react';
import classNames from 'classnames';

import CSSModule from './ProgressBar.module.scss';

interface Props {
  position: number;
  total: number;
}

const ProgressBar: React.FC<Props> = ({ total, position }) => {
  return (
    <div>
      <div className={CSSModule.itemList}>
        {new Array(total).fill(undefined).map((_, i) => {
          const classes = classNames(CSSModule.item, { [CSSModule.active]: i + 1 <= position });
          return <div key={`item${i}`} className={classes} />;
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
