import React, { useState } from 'react';
import { pullAll, uniq } from 'lodash-es';

import {
  ParentStaffCategoryName,
  StaffCategoryName,
  StaffCategoryType,
  ParentStaffCategoryLabels,
} from '../../interfaces/staff';
import CategorySelector from './CategorySelector';
import CheckedIcon from './CheckedIcon';
import { ReactComponent as PlusIcon } from '../../assets/system/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/system/minus.svg';

import CSSModule from './ParentCategorySelector.module.scss';

interface Props {
  parentCategoryName: ParentStaffCategoryName;
  categories: StaffCategoryType[];
  selectedCategories: StaffCategoryName[];
  onChangeCategories: (categories: StaffCategoryName[]) => void;
}

const ParentCategorySelector: React.FC<Props> = ({
  parentCategoryName,
  categories,
  selectedCategories,
  onChangeCategories,
}) => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onChangeAllChecked = () => {
    const categoryNames = categories.map(category => category.value);
    if (isAllChecked) {
      onChangeCategories(pullAll(selectedCategories, categoryNames));
    } else {
      onChangeCategories(uniq([...selectedCategories, ...categoryNames]));
    }
    setIsAllChecked(!isAllChecked);
  };

  const onChange = (selectedCategoryName: StaffCategoryName) => {
    const isSelected = selectedCategories.includes(selectedCategoryName);
    if (isSelected) {
      onChangeCategories(selectedCategories.filter(categoryName => categoryName !== selectedCategoryName));
    } else {
      onChangeCategories([...selectedCategories, selectedCategoryName]);
    }
  };

  const toggleItems = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={CSSModule.wrapper}>
      <div className={CSSModule.categoryWrapper}>
        <div className={CSSModule.parentCategory} onClick={toggleItems}>
          <h3 className={CSSModule.title}>{ParentStaffCategoryLabels[parentCategoryName]}</h3>
          {isOpen ? <MinusIcon className={CSSModule.toggleIcon} /> : <PlusIcon className={CSSModule.toggleIcon} />}
        </div>
      </div>
      {isOpen && (
        <div className={CSSModule.checkAll} onClick={onChangeAllChecked}>
          <div className={CSSModule.checkContent}>
            <span className={CSSModule.checkAllText}>すべて</span>
            <div className={CSSModule.icon}>
              <CheckedIcon checked={isAllChecked} />
            </div>
          </div>
        </div>
      )}
      {isOpen &&
        categories.map(category => (
          <CategorySelector
            key={category.value}
            category={category}
            isSelected={selectedCategories.includes(category.value)}
            onChange={onChange}
          />
        ))}
    </div>
  );
};

export default ParentCategorySelector;
