import React from 'react';

import { BookOrder } from '../../../interfaces/order';
import ShipmentForm, { FormValues } from './ShipmentForm';

import CSSModule from './EditShipmentPage.module.scss';

interface Props {
  bookOrder: BookOrder;
  disabled: boolean;
  onSubmit: (shipmentCode: string) => void;
  onCancel: () => void;
}

const EditShipmentPage: React.FC<Props> = ({ bookOrder, disabled, onSubmit, onCancel }) => {
  const onSubmitForm = (values: FormValues) => {
    onSubmit(values.shipmentCode);
  };

  const initialValues = bookOrder.shipmentCode ? { shipmentCode: bookOrder.shipmentCode } : undefined;

  return (
    <div className={CSSModule.editShipmentPage}>
      <h1 className={CSSModule.title}>配送番号</h1>
      <ShipmentForm initialValues={initialValues} disabled={disabled} onSubmit={onSubmitForm} onCancel={onCancel} />
    </div>
  );
};

export default EditShipmentPage;
