import React from 'react';

import LoginIcon from '../../assets/welcome/login.png';

import CSSModule from './LoginFirstView.module.scss';

const LoginFirstView: React.FC = () => {
  return (
    <div className={CSSModule.firstview}>
      <img src={LoginIcon} className={CSSModule.firstviewImage} alt="ログイン" />
    </div>
  );
};

export default LoginFirstView;
