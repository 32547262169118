import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../atoms/form/Input';
import Button from '../atoms/button/Button';
import { EmailRegexp } from '../../app.constants';
import Alert from '../atoms/text/Alert';

export interface FormValues {
  email: string;
}

interface Props {
  disabled: boolean;
  onSubmit: (values: FormValues) => void;
}

const ForgotPasswordForm: React.FC<Props> = ({ disabled, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <Input
          {...register('email', {
            required: 'メールアドレスをご入力ください。',
            pattern: {
              value: EmailRegexp,
              message: 'Eメールアドレスの形式が不正です。',
            },
          })}
          label="メールアドレス"
        />
        {errors.email && <Alert message={errors.email.message} />}
      </div>
      <Button disabled={disabled}>送信</Button>
    </form>
  );
};

export default ForgotPasswordForm;
