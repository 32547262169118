import React from 'react';

import { ReactComponent as ShuffleIcon } from '../../assets/system/shuffle.svg';

import CSSModule from './ShuffleButton.module.scss';

interface Props {
  onClick: () => void;
}

const ShuffleButton: React.FC<Props> = ({ onClick }) => (
  <button onClick={onClick} className={CSSModule.ShuffleButton}>
    <div>表示</div>
    <div>シャッフル</div>
    <ShuffleIcon className={CSSModule.ShuffleButton__Icon} />
  </button>
);

export default ShuffleButton;
