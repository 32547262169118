import actionCreatorFactory from 'typescript-fsa';

import { SET_FLASH_MESSAGE } from './actionTypes';

const actionCreator = actionCreatorFactory();

interface SetFlashMessageParams {
  message: string | null;
}

export const setFlashMessage = actionCreator<SetFlashMessageParams>(SET_FLASH_MESSAGE);
