import React from 'react';
import { UserListItem } from '../../../interfaces/user';

interface Props {
  users: UserListItem[];
  onClickRow: (userId: string) => void;
}

const AdminUserList: React.FC<Props> = ({ users, onClickRow }) => (
  <table className="table table-bordered">
    <thead>
      <tr>
        <th>ID</th>
        <th>ユーザー名</th>
        <th>メールアドレス</th>
      </tr>
    </thead>
    <tbody>
      {users.map(user => (
        <tr key={user.id} onClick={() => onClickRow(user.id)}>
          <td>{user.id}</td>
          <td>
            {user.name}（{user.nameKana}）
          </td>
          <td>{user.email}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default AdminUserList;
