import React from 'react';
import { useSelector } from 'react-redux';

import { StaffCategoryName } from '../../interfaces/staff';
import { RootState } from '../../reducers';
import AppTemplate from '../../components/templates/AppTemplate';
import ShuffleButton from '../../components/staffs/ShuffleButton';
import StaffFilter from '../../components/staffs/StaffFilter';
import StaffList from '../../components/staffs/StaffList';

interface Props {
  categories: StaffCategoryName[];
  keyword: string;
  page: number;
  size: number;
  setKeyword: (keyword: string) => void;
  onSearch: () => void;
  onChangePage: (page: number) => void;
  onReset: () => void;
}

const StaffListContainer: React.FC<Props> = ({
  categories,
  keyword,
  page,
  size,
  setKeyword,
  onChangePage,
  onSearch,
  onReset,
}) => {
  const { staffs, isFetching, error, totalCount } = useSelector((state: RootState) => ({
    staffs: state.staffs.staffs,
    isFetching: state.staffs.isFetchingStaffs,
    error: state.staffs.fetchingStaffsError,
    totalCount: state.staffs.staffCount,
  }));

  return (
    <AppTemplate>
      <StaffFilter categories={categories} keyword={keyword} onChangekeyword={setKeyword} onSubmit={onSearch} />
      <StaffList
        staffs={staffs}
        isFetching={isFetching}
        error={error}
        page={page}
        size={size}
        totalCount={totalCount}
        onChangePage={onChangePage}
      />
      <ShuffleButton onClick={onReset} />
    </AppTemplate>
  );
};

export default StaffListContainer;
