import { generateColumnQuery } from '.';
import { karteColumns } from './karteQueries';
import { columnsForIntroduction as staffListColumns } from './staffQueries';
import { BookOrder, SelectedBookFieldValue } from '../../interfaces/order';

const selectedBookColumns = ['id', 'title', 'isbn', 'status', 'rejectedReason', 'createdAt'];
const orderColumns = [
  'id',
  'status',
  'createdAt',
  'orderedAt',
  'shipmentCode',
  { karte: karteColumns, staff: staffListColumns, selectedBooks: selectedBookColumns },
];
const adminOrderColumns = [
  'id',
  'status',
  'createdAt',
  'orderedAt',
  'shipmentCode',
  {
    user: ['id', 'name', 'email', 'tel'],
    karte: ['gender'],
    staff: ['id', 'name', 'bookStoreName'],
    selectedBooks: ['id', 'title', 'isbn', 'status', 'rejectedReason'],
  },
];

export const getBookOrdersQuery = `
  query getBookOrders(
    $userId: String,
    $size: Int,
    $page: Int
  ) {
    bookOrders(
      userId: $userId,
      size: $size,
      page: $page
    ) { ${generateColumnQuery(orderColumns)} }
    bookOrderCount(
      userId: $userId
    )
  }
`;

export interface GetBookOrderDetailParams {
  bookOrderId: string;
}

export interface GetBookOrderDetailResult {
  bookOrder: BookOrder;
}

export const getBookOrderDetailQuery = `
  query getBookOrderDetail(
    $bookOrderId: String!
  ) {
    bookOrder(id: $bookOrderId) { ${generateColumnQuery(orderColumns)} }
  }
`;

export const getAdminBookOrderDetailQuery = `
query getBookOrderDetail(
  $bookOrderId: String!
) {
  bookOrder(id: $bookOrderId) { ${generateColumnQuery([...orderColumns, { user: ['id', 'name'] }])} }
}`;

export const getDraftBookOrderQuery = `
  query getDraftBookOrder(
    $staffId: String,
    $userId: String,
    $status: [String!],
    $size: Int
  ) {
    bookOrders(
      staffId: $staffId,
      userId: $userId,
      status: $status,
      size: $size
    ) { ${generateColumnQuery(orderColumns)} }
  }
`;

export const getNotifiableOrderCountQuery = `
  query getNotifiableOrderCountQuery(
    $userId: String!,
    $status: [String!]
  ) {
    bookOrderCount(userId: $userId, status: $status)
  }
`;

export const postOrderQuery = `
  mutation postOrder(
    $staffId: String!,
    $isDraft: Boolean!,
    $karte: KarteInput!,
    $couponCode: String
  ) {
    createBookOrder(input: {
      staffId: $staffId,
      isDraft: $isDraft,
      karte: $karte,
      couponCode: $couponCode
    }) {
      bookOrder { ${generateColumnQuery(orderColumns)} }
      error
    }
  }
`;

export const createDraftBookCouponLetterQuery = `mutation createDraftBookCouponLetter(
  $shipmentType: String!,
  $recipientName: String,
  $email: String,
  $message: String,
  $postalCode: String,
  $address: String,
) {
  createDraftBookCouponLetter(input: {
    shipmentType: $shipmentType,
    recipientName: $recipientName,
    email: $email,
    message: $message,
    postalCode: $postalCode,
    address: $address,
  }) { draftBookCouponLetterId }
}`;

export interface CreateDraftBookCouponLetterParams {
  shipmentType: string;
  recipientName?: string;
  email?: string;
  message?: string;
  postalCode?: string;
  address?: string;
}

export interface CreateDraftBookCouponLetterResult {
  createDraftBookCouponLetter: {
    draftBookCouponLetterId?: string;
  };
}

export const reviewSelectedBooksQuery = `
  mutation reviewSelectedBooks(
    $bookOrderId: String!,
    $approvedBookIds: [String!]!,
    $rejections: [SelectedBookRejectionInput!]!
  ) {
    approveSelectedBooks(input: {
      bookOrderId: $bookOrderId,
      selectedBookIds: $approvedBookIds
    }) { error }
    rejectSelectedBooks(input: {
      bookOrderId: $bookOrderId,
      rejections: $rejections
    }) { error status }
  }
`;

export interface GetAdminBookOrderListParams {
  page?: number;
  size?: number;
  sortBy?: string;
}

export interface GetAdminBookOrderListResult {
  bookOrders: BookOrder[];
  bookOrderCount: number;
}

export const createKarteCsvQuery = `
  mutation createKarteCsv {
    createKarteCsv(input: {}) { uploadedUrl }
  }
`;

export interface CreateKarteCsvResult {
  createKarteCsv: {
    uploadedUrl: string;
  };
}

export const getAdminBookOrderListQuery = `
  query getAdminBookOrders(
    $page: Int,
    $size: Int,
    $sortBy: String
  ) {
    bookOrders(page: $page, size: $size, sortBy: $sortBy) {
      ${generateColumnQuery(adminOrderColumns)}
    }
    bookOrderCount
  }
`;

export interface RegisterShipmentCodeParams {
  bookOrderId: string;
  shipmentCode: string;
}

export interface RegisterShipmentCodeResult {
  registerShipmentCode: {
    error: string | null;
  };
}

export const registerShipmentCodeQuery = `
  mutation registerShipmentCode(
    $bookOrderId: String!,
    $shipmentCode: String!
  ) {
    registerShipmentCode(input: {
      bookOrderId: $bookOrderId,
      shipmentCode: $shipmentCode
    }) { error }
  }
`;

export interface UpdateSelectedBooksParams {
  bookOrderId: string;
  selectedBooks: SelectedBookFieldValue[];
}

export interface UpdateSelectedBooksResult {
  updateSelectedBooks: {
    error: string | null;
  };
}

export const updateSelectedBooksQuery = `
  mutation updateSelectedBooks(
    $bookOrderId: String!,
    $selectedBooks: [SelectedBookInput!]!
  ) {
    updateSelectedBooks(input: {
      bookOrderId: $bookOrderId,
      selectedBooks: $selectedBooks
    }) { error }
  }
`;
