import React, { useState } from 'react';
import classNames from 'classnames';

import { Photo } from '../../interfaces/common';
import StaffPhotoListItem from './StaffPhotoListItem';

import CSSModule from './StaffPhotoList.module.scss';

interface Props {
  photos: Photo[];
}

const StaffPhotoList: React.FC<Props> = ({ photos }) => {
  const [index, setIndex] = useState(0);

  const onSwipeLeft = () => {
    if (photos[index + 1]) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  };
  const onSwipeRight = () => {
    if (photos[index - 1]) {
      setIndex(index - 1);
    } else {
      setIndex(photos.length - 1);
    }
  };

  return (
    <>
      <div className={CSSModule.staffPhotoList}>
        {photos.map((photo, i) => (
          <StaffPhotoListItem
            key={photo.src}
            photo={photo}
            onSwipeRight={onSwipeRight}
            onSwipeLeft={onSwipeLeft}
            isActive={index === i}
          />
        ))}
      </div>
      <div className={CSSModule.indicatorList}>
        {photos.map(({ src }, i) => (
          <div key={src} className={classNames(CSSModule.indicator, { [CSSModule.active]: index === i })} />
        ))}
      </div>
    </>
  );
};

export default StaffPhotoList;
