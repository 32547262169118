import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../reducers';
import { setFlashMessage } from '../../actions/common';
import FlashMessage from '../../components/common/FlashMessage';

const HIDE_DELAY_TIME = 3000;

const FlashMessageContainer: React.FC = () => {
  const { message } = useSelector((state: RootState) => ({
    message: state.common.flashMessage,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(setFlashMessage({ message: null }));
      }, HIDE_DELAY_TIME);
    }
  }, [dispatch, message]);

  return <FlashMessage message={message} />;
};

export default FlashMessageContainer;
