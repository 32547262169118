import React from 'react';

import CSSModule from './GiftDescription.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

const GiftDescription: React.FC<Props> = ({ title, children }) => {
  return (
    <div className={CSSModule.GiftDescription}>
      <h2 className={CSSModule.GiftDescription__Title}>{title}</h2>
      {children}
      <p className={CSSModule.GiftDescription__Note}>
        ※ギフトコードの有効期限は発行から
        <br />
        半年間になります。
      </p>
    </div>
  );
};

export default GiftDescription;
