import React from 'react';

import AppTemplate from '../../components/templates/AppTemplate';
import Authenticated from '../common/Authenticated';
import MyPage from '../../components/mypage/MyPage';
import useCurrentUser from '../../hooks/store/useCurrentUser';

const MyPageContainer: React.FC = () => {
  const [user] = useCurrentUser();

  return (
    <AppTemplate>
      <Authenticated>{user && <MyPage user={user} />}</Authenticated>
    </AppTemplate>
  );
};

export default MyPageContainer;
