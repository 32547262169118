import React from 'react';

import ProgressBar from '../common/ProgressBar';

import CSSModule from './ProgressBarTemplate.module.scss';

interface Props {
  title?: string;
  position: number;
  total: number;
  children: React.ReactNode;
}

const ProgressBarTemplate: React.FC<Props> = ({ title, position, total, children }) => (
  <>
    <div className={CSSModule.progressBar}>
      <ProgressBar total={total} position={position} />
    </div>
    {title && <h2 className={CSSModule.title}>{title}</h2>}
    <div className={CSSModule.body}>{children}</div>
  </>
);

export default ProgressBarTemplate;
