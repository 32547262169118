import React from 'react';

import AppTemplate from '../templates/AppTemplate';
import TermsDocument from './TermsDocument';

const TermsPage: React.FC = () => (
  <AppTemplate>
    <TermsDocument />
  </AppTemplate>
);

export default TermsPage;
