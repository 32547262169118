import React from 'react';
import { useNavigate } from 'react-router';

import { User } from '../../interfaces/user';
import ProgressBarTemplate from '../templates/ProgressBarTemplate';
import Button from '../atoms/button/Button';
import { TotalProgressNumber } from '../../containers/orders/NewOrderContainer';
import ThanksImage from '../../assets/orders/thanks.png';

import CSSModule from './OrderThanksPage.module.scss';

const thanksMessages = [
  {
    title1: '読書家の一族は、',
    title2: '世界を動かす者たちなのだ。',
    description: 'ナポレオン・ボナパルト（フランスの革命家／1769〜1821）',
  },
  {
    title1: '本のない部屋は、',
    title2: '魂のない肉体のようなものだ。',
    description: 'キケロ（共和政ローマの哲学者／紀元前106～前43）',
  },
  {
    title1: '書物は我々の内なる凍った海のための',
    title2: '斧でなければならない。',
    description: 'フランツ・カフカ（チェコの作家／1883〜1924）',
  },
  {
    title1: '読書とは、自分で考える代わりに',
    title2: '他の誰かにものを考えてもらうことだ。',
    description: 'アルトゥル・ショーペンハウアー（ドイツの哲学者／1788～1860）',
  },
  {
    title1: '宝島の海賊たちが盗んだ財宝よりも、',
    title2: '本には多くの宝が眠っている。',
    description: 'ウォルト・ディズニー（米国の実業家／1901～1966）',
  },
  {
    title1: '読書は学問の術なり、',
    title2: '学問は事をなすの術なり。',
    description: '福澤諭吉（日本の作家／1922〜1999）',
  },
  {
    title1: '読書というものは、',
    title2: 'その時に応じて読み方に深浅がある。',
    description: '三浦綾子（日本の作家／1922〜1999）',
  },
  {
    title1: 'この頃みんな本を読まないらしいが、',
    title2: '本は自分自身との対話だ。',
    description: '岡本太郎（日本の芸術家／1911〜1996）',
  },
  {
    title1: '本の世界では、何でも見たいものをみて',
    title2: 'どこでも行きたいところに行ける。',
    description: 'マイケル・ジャクソン（米国の総合芸術家／1958〜2009）',
  },
  {
    title1: '今日の読書こそ、',
    title2: '真の学問である。',
    description: '吉田松陰（日本の教育者／1830〜1859）',
  },
];

interface Props {
  user: User;
}

const OrderThanksPage: React.FC<Props> = ({ user }) => {
  const navigate = useNavigate();
  const onClick = () => navigate('/mypage');
  const index = Math.floor(Math.random() * thanksMessages.length);
  const thanksMessage = thanksMessages[index];

  return (
    <ProgressBarTemplate position={TotalProgressNumber} total={TotalProgressNumber}>
      <div className={CSSModule.OrderThanksPage}>
        <img src={ThanksImage} alt="お申し込みありがとうございました。" className={CSSModule.OrderThanksPage__Image} />
        <div className={CSSModule.OrderThanksPage__Description}>
          この度は一万円選書に申込いただきありがとうございました。
          <br />
          <br />
          {user.name}様にぴったりな本をお届けできるよう、カルテを読んだうえで選書をさせていただきます。
          <br />
          <br />
          想いを巡らせながら、
          <br />
          一冊一冊丁寧に選書しておりますので1週間ほどお待ちくださいませ。
          <br />
        </div>
        <div className={CSSModule.OrderThanksPage__Email}>
          メールアドレス
          <br />
          {user.email}
        </div>
        <div className={CSSModule.OrderThanksPage__EmailDescription}>
          申込の確認として、メッセージを上記までお送りいたしました。
          <br />
          <br />
          お使いのメール環境でドメイン設定（受信拒否設定）をされているお客様の場合、弊社からお送りするメールが迷惑メールとして認識されてしまうことがございます。
          <br />
          <br />
          ドメイン設定を解除していただくか、弊社ドメイン「＠bookkarte.com」を受信リストに加えていただきますよう、お願い申し上げます。
          <br />
          <br />
          また確認のメールが届かない場合はお手数ですが、お問い合わせフォームよりご一報くださいませ。
        </div>
        <div className={CSSModule.OrderThanksPage__Panel}>
          <h3 className={CSSModule.OrderThanksPage__PanelTitle}>
            "{thanksMessage.title1}
            <br />
            {thanksMessage.title2}"
          </h3>
          <div className={CSSModule.OrderThanksPage__PanelDescription}>{thanksMessage.description}</div>
        </div>
        <Button onClick={onClick}>OK</Button>
      </div>
    </ProgressBarTemplate>
  );
};

export default OrderThanksPage;
