import React, { useEffect } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { useNavigate } from 'react-router';

import useCurrentUser from '../../hooks/store/useCurrentUser';
import { User } from '../../interfaces/user';
import { RootState } from '../../reducers';
import { updateUser, fetchUserPresignedUrl, uploadUserPhoto } from '../../actions/auth';
import { setFlashMessage as setFlashMessageAction } from '../../actions/common';
import Authenticated from '../common/Authenticated';
import AppTemplate from '../../components/templates/AppTemplate';
import UserProfileForm from '../../components/mypage/UserProfileForm';
import FileLoader from '../../utils/fileLoader';

const mapStateToProps = (state: RootState) => ({
  isUpdating: state.auth.isUpdating,
  isFetchingPresignedUrl: state.auth.isFetchingUserPresignedUrl,
  isUploadingPhoto: state.auth.isUploadingUserPhoto,
  error: state.auth.updatingError,
  fetchingPresignedUrlError: state.auth.fetchingUserPresignedUrlError,
  uploadingPhotoError: state.auth.uploadingUserPhotoError,
});

const mapDispatchToProps = {
  updateProfile: updateUser.action,
  setFlashMessage: setFlashMessageAction,
  fetchPresignedUrl: fetchUserPresignedUrl.action,
  uploadPhoto: uploadUserPhoto.action,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

const EditProfileContainer: React.FC<Props> = ({
  isUpdating,
  error,
  isFetchingPresignedUrl,
  isUploadingPhoto,
  fetchingPresignedUrlError,
  uploadingPhotoError,
  fetchPresignedUrl,
  uploadPhoto,
  updateProfile,
  setFlashMessage,
}) => {
  const navigate = useNavigate();
  const [user] = useCurrentUser();
  const isHandlingPhoto = isFetchingPresignedUrl || isUploadingPhoto;
  const isLoading = isUpdating || isHandlingPhoto;

  const onChangePhoto = async (file: File | null): Promise<string | void> => {
    if (file) {
      const loader = new FileLoader(file);
      if (loader.isImage) {
        const { presignedUrl, filename } = await fetchPresignedUrl({ extname: loader.extname });
        await uploadPhoto({ file, presignedUrl });
        return filename;
      } else {
        setFlashMessage({ message: 'エラー: 画像ファイルを選択してください。' });
      }
    }
  };

  const onSubmit = async (values: Partial<User>) => {
    const result = await updateProfile({ user: values });
    if (result.user) {
      setFlashMessage({ message: 'プロフィールを更新しました。' });
      navigate('/mypage/profile');
    } else {
      setFlashMessage({ message: result.error ?? '予期しないエラーが発生しました。' });
    }
  };

  useEffect(() => {
    if (fetchingPresignedUrlError) {
      setFlashMessage({ message: fetchingPresignedUrlError.message });
    }
  }, [fetchingPresignedUrlError, setFlashMessage]);

  useEffect(() => {
    if (uploadingPhotoError) {
      setFlashMessage({ message: uploadingPhotoError.message });
    }
  }, [uploadingPhotoError, setFlashMessage]);

  return (
    <AppTemplate>
      <Authenticated>
        {user && (
          <UserProfileForm
            initialValues={{
              name: user.name,
              nameKana: user.nameKana,
              email: user.email,
              tel: user.tel,
              postalCode: user.postalCode,
              address: user.address,
              shippingPostalCode: user.shippingPostalCode,
              shippingAddress: user.shippingAddress,
            }}
            initialPhoto={user.photo}
            isLoading={isLoading}
            updatingError={error}
            isLoadingPhoto={isHandlingPhoto}
            onChangePhoto={onChangePhoto}
            onSubmit={onSubmit}
          />
        )}
      </Authenticated>
    </AppTemplate>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileContainer);
