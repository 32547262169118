import React from 'react';
import { useForm } from 'react-hook-form';

import Input from '../atoms/form/Input';
import Alert from '../atoms/text/Alert';
import Button from '../atoms/button/Button';
import { PasswordMinLength } from '../../app.constants';

import CSSModule from './ResetPasswordPage.module.scss';

export interface FormValues {
  password: string;
  passwordConfirmation: string;
}

interface Props {
  disabled: boolean;
  onSubmit: (values: FormValues) => void;
}

const ResetPasswordPage: React.FC<Props> = ({ disabled, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FormValues>();

  const password = watch('password');
  const passwordConfirmation = watch('passwordConfirmation');

  return (
    <div className={CSSModule.resetPasswordPage}>
      <h2 className={CSSModule.title}>パスワードの再設定</h2>
      <div className={CSSModule.description}>パスワードを再設定します。パスワードを入力してください。</div>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <Input
            {...register('password', {
              required: 'パスワードをご入力ください。',
              minLength: {
                value: PasswordMinLength,
                message: `パスワードは最低${PasswordMinLength}文字以上ご入力ください。`,
              },
            })}
            type="password"
            label="パスワード"
          />
          {errors.password && <Alert message={errors.password.message} />}
        </div>
        <div className="form-group">
          <Input
            {...register('passwordConfirmation', { required: 'パスワードを再度ご入力ください。' })}
            type="password"
            label="パスワード再入力"
          />
          {errors.passwordConfirmation && <Alert message={errors.passwordConfirmation.message} />}
          {password && passwordConfirmation && password !== passwordConfirmation && (
            <Alert message="入力されたパスワードが異なります。" />
          )}
        </div>
        <Button disabled={disabled}>送信</Button>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
