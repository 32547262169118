import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AppTemplate from 'src/components/templates/AppTemplate';
import UiStackTemplate from 'src/components/common/UiStackTemplate';
import ApplyGiftResult from 'src/components/gifts/ApplyGiftResult';
import Authenticated from 'src/containers/common/Authenticated';
import useQuery from 'src/hooks/common/useQuery';
import { DraftBookCouponLetter } from 'src/interfaces/order';
import GATracker from 'src/utils/GATracker';
import useMutation from 'src/hooks/common/useMutation';
import useToast from 'src/hooks/store/useToast';

const GET_DRAFT_BOOK_COUPON_LETTER_QUERY = `query getDraftBookCouponLetter($id: String!) {
  draftBookCouponLetter(id: $id) {
    id
    shipmentType
    recipientName
    email
    message
    shippingAddress {
      postalCode
      address
    }
  }
}`;

interface GetDraftBookCouponLetterParams {
  id: string;
}

interface GetDraftBookCouponLetterResult {
  draftBookCouponLetter: DraftBookCouponLetter;
}

const APPLY_DRAFT_BOOK_COUPON_LETTER_QUERY = `mutation applyBookCouponLetter($draftId: ID!) {
  applyBookCouponLetter(input: { draftId: $draftId }) {
    success
  }
}`;

interface ApplyBookCouponLetterParams {
  draftId: string;
}

interface ApplyBookCouponLetterResult {
  applyBookCouponLetter: {
    success: boolean;
  };
}

const GiftApplyPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isMounted = useRef<boolean>(false);
  const showToast = useToast();

  const {
    isLoading: isFetching,
    error: fetchError,
    result: fetchResult,
    executeQuery: fetchDraftBookCouponLetter,
  } = useQuery<GetDraftBookCouponLetterParams, GetDraftBookCouponLetterResult>(GET_DRAFT_BOOK_COUPON_LETTER_QUERY);

  const {
    isLoading: isApplying,
    error: applyError,
    executeMutation: applyBookCoupon,
  } = useMutation<ApplyBookCouponLetterParams, ApplyBookCouponLetterResult>();

  const draftId = searchParams.get('draftId');
  const draftBookCouponLetter = fetchResult?.draftBookCouponLetter ?? null;

  useEffect(() => {
    if (!draftId || isMounted.current) return;

    isMounted.current = true;

    (async () => {
      const fetchResult = await fetchDraftBookCouponLetter({ id: draftId });
      const draftBookCouponLetter = fetchResult?.draftBookCouponLetter ?? null;
      const shipmentType = draftBookCouponLetter?.shipmentType ?? null;

      const applyResult = await applyBookCoupon(APPLY_DRAFT_BOOK_COUPON_LETTER_QUERY, { draftId });

      if (!applyResult?.applyBookCouponLetter.success) {
        showToast('予期しないエラーが発生しました。');
        navigate('/');
        return;
      }

      switch (shipmentType) {
        case 'email':
          GATracker.trackEvent('send_gift');
          break;
        case 'other':
          GATracker.trackEvent('send_gift_by_post');
          break;
        case 'self':
          GATracker.trackEvent('send_gift_by_hand');
          break;
        default:
          return;
      }
    })();
  }, [fetchDraftBookCouponLetter, applyBookCoupon, navigate, showToast, isMounted, draftId]);

  return (
    <AppTemplate>
      <Authenticated>
        <UiStackTemplate isLoading={isFetching || isApplying} error={fetchError || applyError}>
          {draftBookCouponLetter && <ApplyGiftResult draftBookCouponLetter={draftBookCouponLetter} />}
        </UiStackTemplate>
      </Authenticated>
    </AppTemplate>
  );
};

export default GiftApplyPage;
