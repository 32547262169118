import React from 'react';

import CSSModule from './FlashMessage.module.scss';
import classNames from 'classnames';

interface Props {
  message: string | null;
}

const FlashMessage: React.FC<Props> = ({ message }) => {
  const classes = classNames(CSSModule.flashMessage, {
    [CSSModule.active]: message,
  });
  return (
    <div className={classes}>
      <div className={CSSModule.message}>{message}</div>
    </div>
  );
};

export default FlashMessage;
