import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setFlashMessage } from '../../actions/common';

const useToast = () => {
  const dispatch = useDispatch();
  const showToast = useCallback((message: string) => dispatch(setFlashMessage({ message })), [dispatch]);

  return showToast;
};

export default useToast;
