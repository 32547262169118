import React from 'react';
import { Route, Routes } from 'react-router';

import AdminAuthenticated from './AdminAuthenticated';
import AdminStaffListContainer from './AdminStaffListContainer';
import AdminNewStaffContainer from './AdminNewStaffContainer';
import AdminEditStaffContainer from './AdminEditStaffContainer';
import AdminEditOrderContainer from './AdminEditOrderContainer';
import AdminOrderListContainer from './AdminOrderListContainer';
import AdminEditShipmentContainer from './AdminEditShipmentContainer';
import AdminUserListContainer from './AdminUserListContainer';
import AdminUserDetailContainer from './AdminUserDetailContainer';

const AdminContainer: React.FC = () => {
  return (
    <AdminAuthenticated>
      <Routes>
        <Route path="/" Component={AdminStaffListContainer} />
        <Route path="/staffs/new" Component={AdminNewStaffContainer} />
        <Route path="/staffs/:staffId/edit" Component={AdminEditStaffContainer} />
        <Route path="/orders" Component={AdminOrderListContainer} />
        <Route path="/orders/:bookOrderId/edit" Component={AdminEditOrderContainer} />
        <Route path="/orders/:bookOrderId/shipment/edit" Component={AdminEditShipmentContainer} />
        <Route path="/users" Component={AdminUserListContainer} />
        <Route path="/users/:userId" Component={AdminUserDetailContainer} />
      </Routes>
    </AdminAuthenticated>
  );
};

export default AdminContainer;
