import React, { useEffect } from 'react';
import { ResolveThunks, connect } from 'react-redux';
import { useParams } from 'react-router';

import { RootState } from '../../reducers';
import { fetchStaffDetail } from '../../actions/staffs';
import StaffDetailPage from '../../components/staffs/StaffDetailPage';

const mapStateToProps = (state: RootState) => ({
  staff: state.staffs.staff,
  isLoading: state.staffs.isFetchingStaffDetail,
  error: state.staffs.fetchingStaffDetailError,
});

const mapDispatchToProps = {
  getStaffDetail: fetchStaffDetail.action,
};

type Props = ReturnType<typeof mapStateToProps> & ResolveThunks<typeof mapDispatchToProps>;

const StaffDetailContainer: React.FC<Props> = ({ staff, isLoading, error, getStaffDetail }) => {
  const { id: staffId } = useParams<{ id: string }>();

  useEffect(() => {
    if (staffId) {
      getStaffDetail({ id: staffId });
    }
  }, [getStaffDetail, staffId]);

  return <StaffDetailPage staff={staff} isLoading={isLoading} error={error} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetailContainer);
