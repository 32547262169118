import React from 'react';

import SignUpContainer from '../../containers/auth/SignUpContainer';
import LoginContainer from '../../containers/auth/LoginContainer';

import CSSModule from './AuthenticatedPage.module.scss';

const AuthenticatedPage: React.FC = () => {
  return (
    <div className={CSSModule.authenticatedPage}>
      <h3 className={CSSModule.title}>会員登録がお済みの方</h3>
      <div className={CSSModule.loginForm}>
        <LoginContainer />
      </div>
      <hr />
      <h3 className={CSSModule.title}>まだ登録がお済みでない方</h3>
      <SignUpContainer />
    </div>
  );
};

export default AuthenticatedPage;
