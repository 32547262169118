import React from 'react';

import { BookOrder, BookRejection } from '../../interfaces/order';
import UiStackTemplate from '../common/UiStackTemplate';
import Pagination from '../common/Pagination';
import OrderList from './OrderList';

import CSSModule from './OrderListPage.module.scss';

const NOTIFICATION_COUNT_LIMIT = 99;

interface Props {
  bookOrders: BookOrder[];
  notificationCount: number;
  loadingOrderId: string | null;
  onSubmitReview: (bookOrderId: string, approvedIds: string[], rejections: BookRejection[]) => void;
  totalCount: number;
  page: number;
  size: number;
  onChangePage: (page: number) => void;
}

const OrderListPage: React.FC<Props> = ({
  bookOrders,
  notificationCount,
  onSubmitReview,
  loadingOrderId,
  totalCount,
  page,
  size,
  onChangePage,
}) => {
  const count = notificationCount > NOTIFICATION_COUNT_LIMIT ? NOTIFICATION_COUNT_LIMIT : notificationCount;
  return (
    <div className={CSSModule.orderListPage}>
      <h3 className={CSSModule.title}>
        注文管理
        {count > 0 && <span className={CSSModule.notificationCount}>{count}</span>}
      </h3>
      <UiStackTemplate
        isLoading={false}
        error={null}
        isEmpty={bookOrders.length === 0}
        emptyMessage="注文履歴はありません。"
      >
        <OrderList bookOrders={bookOrders} onSubmitReview={onSubmitReview} loadingOrderId={loadingOrderId} />
      </UiStackTemplate>
      <Pagination totalCount={totalCount} page={page} size={size} onChangePage={onChangePage} />
    </div>
  );
};

export default OrderListPage;
