import React from 'react';

import { Staff } from '../../interfaces/staff';
import UiStackTemplate from '../common/UiStackTemplate';
import StaffThumbnail from './StaffThumbnail';

import CSSModule from './StaffIntroduction.module.scss';

interface Props {
  staffs: Staff[];
  bookStoreCount: number;
  isLoading: boolean;
  error: Error | null;
}

const StaffIntroduction: React.FC<Props> = ({ staffs, bookStoreCount, isLoading, error }) => (
  <div className={CSSModule.StaffIntroduction}>
    <div className={CSSModule.StaffIntroduction__Title}>
      読みたい本が分からない…そんなあなたに。
      <br />
      全国{bookStoreCount}店舗の書店員たちから
      <br />
      私たちが選びます！
    </div>
    <UiStackTemplate isLoading={isLoading} error={error}>
      <div className={CSSModule.StaffIntroduction__StaffList}>
        {staffs.map(staff => (
          <StaffThumbnail key={staff.id} staff={staff} />
        ))}
      </div>
    </UiStackTemplate>
  </div>
);

export default StaffIntroduction;
