import React from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { RootState } from '../../reducers';
import KarteTemplateDetailPage from '../../components/karteTemplates/KarteTemplateDetailPage';

const KarteTemplateDetailContainer: React.FC = () => {
  const navigate = useNavigate();
  const { karteTemplates } = useSelector((state: RootState) => ({
    karteTemplates: state.kartes.templates,
  }));

  const params = useParams<{ templateId: string }>();
  const templateId = Number(params.templateId);
  const karteTemplate = karteTemplates.find(template => template.orderNumber + 1 === templateId);

  const onClickEditButton = () => {
    navigate(`/mypage/karte-templates/${templateId}/edit`);
  };

  if (!templateId) {
    return null;
  }

  return (
    <KarteTemplateDetailPage
      karteTemplate={karteTemplate}
      templateId={templateId}
      onClickEditButton={onClickEditButton}
    />
  );
};

export default KarteTemplateDetailContainer;
