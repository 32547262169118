import { useNavigate } from 'react-router';

import { User } from 'src/interfaces/user';
import Button from 'src/components/atoms/button/Button';

import CSSModule from './PaymentDetail.module.scss';

interface PaymentDetailProps {
  user: User;
}

const PaymentDetail: React.FC<PaymentDetailProps> = ({ user }) => {
  const navigate = useNavigate();

  const goToEdit = () => {
    navigate('/mypage/payments/edit');
  };

  return (
    <div className={CSSModule.PaymentDetail}>
      <h2 className={CSSModule.PaymentDetail__Title}>決済方法の設定</h2>
      {user.cardLast4 ? (
        <div>
          <div className={CSSModule.PaymentDetail__CardInfo}>
            <p>{user.cardBrand}</p>
            <p>**** **** **** {user.cardLast4}</p>
          </div>
          <p>
            有効期限: {user.cardExpMonth}/{user.cardExpYear}
          </p>
        </div>
      ) : (
        <p className={CSSModule.PaymentDetail__NoRegistration}>未登録</p>
      )}
      <Button size="small" onClick={goToEdit}>
        編集
      </Button>
    </div>
  );
};

export default PaymentDetail;
