import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../reducers';
import { fetchStaffIntroduction } from '../../actions/staffs';
import AppTemplate from '../../components/templates/AppTemplate';
import FirstView from '../../components/welcome/FirstView';
import RegistrationButton from '../../components/welcome/RegistrationButton';
import StaffIntroduction from '../../components/welcome/StaffIntroduction';
import ServiceDescription from '../../components/welcome/ServiceDescription';
import GiftDescription from '../../components/welcome/GiftDescription';
import FlowDescription from '../../components/welcome/FlowDescription';
import PriceDescription from '../../components/welcome/PriceDescription';
import PublicationRecord from '../../components/welcome/PublicationRecord';
import randomInt from '../../utils/randomInt';

const DEFAULT_SIZE = 9;

const WelcomePageContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { staffs, bookStoreCount, isFetching, error } = useSelector((state: RootState) => ({
    staffs: state.staffs.staffIntroduction,
    bookStoreCount: state.staffs.bookStoreCount,
    isFetching: state.staffs.isFetchingStaffs,
    error: state.staffs.fetchingStaffsError,
  }));

  const fetchStaffThumbnails = useCallback(
    (params: Parameters<typeof fetchStaffIntroduction.action>[0]) => {
      dispatch(fetchStaffIntroduction.action(params));
    },
    [dispatch],
  );

  useEffect(() => {
    fetchStaffThumbnails({ hasInventory: true, size: DEFAULT_SIZE, randomize: randomInt(100) });
  }, [fetchStaffThumbnails]);

  return (
    <AppTemplate marginBottom="64px">
      <FirstView />
      <RegistrationButton />
      <StaffIntroduction staffs={staffs} bookStoreCount={bookStoreCount} isLoading={isFetching} error={error} />
      <ServiceDescription />
      <PublicationRecord />
      <FlowDescription />
      <PriceDescription />
      <GiftDescription />
    </AppTemplate>
  );
};

export default WelcomePageContainer;
