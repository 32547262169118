import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useCurrentUser from 'src/hooks/store/useCurrentUser';
import useQuery from 'src/hooks/common/useQuery';
import {
  CreateDraftBookCouponLetterParams,
  createDraftBookCouponLetterQuery,
  CreateDraftBookCouponLetterResult,
} from 'src/actions/queries/orderQueries';

const useDraftGift = () => {
  const navigate = useNavigate();
  const [currentUser] = useCurrentUser();
  const { isLoading, error, executeQuery } = useQuery<
    CreateDraftBookCouponLetterParams,
    CreateDraftBookCouponLetterResult
  >(createDraftBookCouponLetterQuery);

  const cardIsRegistered = !!currentUser?.cardLast4;

  const createDraftLetter = useCallback(
    async (values: CreateDraftBookCouponLetterParams) => {
      const result = await executeQuery(values);

      const draftId = result.createDraftBookCouponLetter.draftBookCouponLetterId;
      const searchParams = new URLSearchParams({ draftId: draftId ?? '' });
      const applyPageUrl = ['/mypage/gifts/apply', searchParams.toString()].join('?');

      if (cardIsRegistered || currentUser?.isAdmin) {
        navigate(applyPageUrl);
      } else {
        const searchParams = new URLSearchParams({ returnTo: applyPageUrl });
        const paymentPageUrl = ['/mypage/payments/edit', searchParams.toString()].join('?');
        navigate(paymentPageUrl);
      }
    },
    [executeQuery, currentUser, cardIsRegistered, navigate],
  );

  return {
    isLoading,
    error,
    createDraftLetter,
  };
};

export default useDraftGift;
