import React from 'react';

import useCurrentUser from '../../../hooks/store/useCurrentUser';
import AdminHeader from './AdminHeader';
import AppTemplate from '../../templates/AppTemplate';

import CSSModule from './AdminTemplate.module.scss';

interface Props {
  children: React.ReactNode;
}

const AdminTemplate: React.FC<Props> = ({ children }) => {
  const [user] = useCurrentUser();

  if (user?.isAdmin) {
    return (
      <div className={CSSModule.adminTemplate}>
        <header className={CSSModule.header}>
          <AdminHeader />
        </header>
        <div className={CSSModule.body}>{children}</div>
      </div>
    );
  }

  return <AppTemplate>{children}</AppTemplate>;
};

export default AdminTemplate;
