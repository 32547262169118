import React from 'react';
import classNames from 'classnames';

import CSSModule from './Alert.module.scss';

interface Props {
  error?: Error;
  message?: string;
  className?: string;
}

const Alert: React.FC<Props> = ({ error, message, className }) => (
  <div className={classNames(CSSModule.alert, 'my-2', className)}>
    {message || (error && error.message.replace(/^GraphQL error: /, ''))}
  </div>
);

export default Alert;
