import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import CSSModule from './KarteLink.module.scss';

interface Props {
  staffId: string;
  bookOrderId: string;
  isDraft: boolean;
}

const KarteLink: React.FC<Props> = ({ staffId, bookOrderId, isDraft }) => {
  const text = isDraft ? '下書き中のカルテ' : '送ったカルテ';
  const to = isDraft ? `/staffs/${staffId}/orders/new` : `/orders/${bookOrderId}/karte`;

  return (
    <div className={CSSModule.karteLink}>
      <div className={CSSModule.label}>{text}</div>
      <Link to={to} className={classNames(CSSModule.link, 'linkNavigator')}>
        カルテ①
      </Link>
    </div>
  );
};

export default KarteLink;
