import React from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { BookOrder, BookOrderStatusLabel, isApprovedBookOrder, isDeliveredBookOrder } from '../../../interfaces/order';
import { GenderLabel } from '../../../interfaces/karte';

import CSSModule from './AdminOrderList.module.scss';

const formatDate = (date: string | null) => {
  if (!date) {
    return '-';
  }

  return format(parseISO(date), 'yyyy/MM/dd');
};

const stopPropagation = (e: React.MouseEvent<HTMLTableCellElement>) => e.stopPropagation();

const ShipmentLink: React.FC<{ bookOrder: BookOrder }> = ({ bookOrder }) => {
  const editShipmentLink = `/admin/orders/${bookOrder.id}/shipment/edit`;

  if (isApprovedBookOrder(bookOrder)) {
    return (
      <td onClick={stopPropagation}>
        <Link to={editShipmentLink} className={CSSModule.editLink}>
          編集
        </Link>
      </td>
    );
  }

  if (isDeliveredBookOrder(bookOrder)) {
    return (
      <td onClick={stopPropagation}>
        <Link to={editShipmentLink} className={CSSModule.editLink}>
          {bookOrder.shipmentCode}
        </Link>
      </td>
    );
  }

  return <td>-</td>;
};

interface Props {
  bookOrders: BookOrder[];
  onClickRow: (bookOrderId: string) => void;
}

const AdminOrderList: React.FC<Props> = ({ bookOrders, onClickRow }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>注文ID</th>
          <th>会員名</th>
          <th>性別</th>
          <th>注文日</th>
          <th>担当書店員</th>
          <th>メールアドレス</th>
          <th>電話番号</th>
          <th>ステータス</th>
          <th>配送番号</th>
        </tr>
      </thead>
      <tbody>
        {bookOrders.map(bookOrder => (
          <tr key={bookOrder.id} onClick={() => onClickRow(bookOrder.id)}>
            <td>{bookOrder.id}</td>
            <td>
              {bookOrder.user ? bookOrder.user.name : '退会済ユーザー'}（ID: {bookOrder.user ? bookOrder.user.id : '-'}
              ）
            </td>
            <td>{bookOrder.karte.gender ? GenderLabel[bookOrder.karte.gender] : '未選択'}</td>
            <td>{formatDate(bookOrder.orderedAt)}</td>
            <td onClick={stopPropagation}>
              <Link to={`/staffs/${bookOrder.staff.id}`} className={CSSModule.editLink}>
                {bookOrder.staff.name}（ID: {bookOrder.staff.id}）
              </Link>
            </td>
            <td>{bookOrder.user ? bookOrder.user.email : '-'}</td>
            <td>{bookOrder.user ? bookOrder.user.tel : '-'}</td>
            <td>{BookOrderStatusLabel[bookOrder.status]}</td>
            <ShipmentLink bookOrder={bookOrder} />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AdminOrderList;
