import React from 'react';

import { Staff } from '../../interfaces/staff';
import StaffListItem from './StaffListItem';
import Pagination from '../common/Pagination';

import CSSModule from './StaffList.module.scss';
import UiStackTemplate from '../common/UiStackTemplate';

interface Props {
  staffs: Staff[];
  isFetching: boolean;
  error: Error | null;
  page: number;
  size: number;
  totalCount: number;
  onChangePage: (page: number) => void;
}

const StaffList: React.FC<Props> = ({ staffs, page, size, totalCount, isFetching, error, onChangePage }) => (
  <UiStackTemplate
    isLoading={isFetching}
    error={error}
    isEmpty={staffs.length === 0}
    emptyMessage="お探しの条件での書店員は見つかりませんでした。"
  >
    <div className={CSSModule.staffList}>
      {staffs.map(staff => (
        <StaffListItem key={staff.id} staff={staff} />
      ))}
    </div>
    <div className={CSSModule.pagination}>
      <Pagination page={page} size={size} totalCount={totalCount} onChangePage={onChangePage} />
    </div>
  </UiStackTemplate>
);

export default StaffList;
