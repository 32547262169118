import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BookSelectIcon } from '../../assets/welcome/book-select.svg';
import { ReactComponent as GiftIcon } from '../../assets/welcome/gift.svg';

import CSSModule from './RegistrationButton.module.scss';

const RegistrationButton: React.FC = () => (
  <div className={CSSModule.RegistrationButton}>
    <Link to="/staffs" className={CSSModule.RegistrationButton__Link}>
      <BookSelectIcon />
      <span>選書してもらう</span>
    </Link>
    <Link to="/gifts" className={CSSModule.RegistrationButton__Link}>
      <GiftIcon />
      <span>ギフトを贈る</span>
    </Link>
  </div>
);

export default RegistrationButton;
