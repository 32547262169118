import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate, useLocation } from 'react-router';
import { parse, stringify } from 'query-string';

import { fetchStaffList } from '../../actions/staffs';
import { StaffCategoryName } from '../../interfaces/staff';
import StaffListContainer from './StaffListContainer';
import StaffDetailContainer from './StaffDetailContainer';
import StaffCategoriesContainer from './StaffCategoriesContainer';
import GATracker from '../../utils/GATracker';
import randomInt from '../../utils/randomInt';

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 10;

const StaffsContainer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<StaffCategoryName[]>([]);
  const [keyword, setKeyword] = useState('');
  const defaultRandomSeed = useMemo(() => randomInt(100000), []);

  const query = parse(location.search);

  useEffect(() => {
    const queryParams = parse(location.search);
    const pageNumber = Number(queryParams.page) || DEFAULT_PAGE;
    const randomSeed = Number(queryParams.random) || defaultRandomSeed;

    dispatch(
      fetchStaffList.action({
        categories: queryParams.categories ? ([queryParams.categories].flat() as StaffCategoryName[]) : undefined,
        keyword: (queryParams.q as string) || undefined,
        page: pageNumber,
        size: DEFAULT_SIZE,
        randomize: randomSeed,
      }),
    );
    window.scrollTo(0, 0);
  }, [dispatch, location.search, defaultRandomSeed]);

  const onChangePage = useCallback(
    (newPage: number) => {
      const queryParams = parse(location.search);
      navigate({
        search: stringify({
          ...queryParams,
          page: newPage === DEFAULT_PAGE ? undefined : newPage,
        }),
      });
    },
    [navigate, location],
  );

  const onResetSearch = () => {
    setCategories([]);
    setKeyword('');

    navigate({
      search: stringify({
        page: undefined,
        categories: undefined,
        q: undefined,
        random: randomInt(10000),
      }),
    });
  };

  const onSearch = () => {
    navigate({
      pathname: location.pathname,
      search: stringify({
        page: undefined,
        categories: categories.length > 0 ? categories : undefined,
        q: keyword || undefined,
        random: query.random ?? undefined,
      }),
    });

    GATracker.trackEvent('search_staff');

    window.scrollTo(0, 0);
  };

  const page = Number(query.page) || DEFAULT_PAGE;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <StaffListContainer
            categories={categories}
            keyword={keyword}
            setKeyword={setKeyword}
            page={page}
            size={DEFAULT_SIZE}
            onSearch={onSearch}
            onChangePage={onChangePage}
            onReset={onResetSearch}
          />
        }
      />
      <Route
        path="/categories"
        element={<StaffCategoriesContainer categories={categories} onChangeCategories={setCategories} />}
      />
      <Route path="/:id" element={<StaffDetailContainer />} />
    </Routes>
  );
};

export default StaffsContainer;
