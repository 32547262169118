import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import useQuery from '../../hooks/common/useQuery';
import {
  getAdminStaffDetailQuery,
  GetAdminStaffDetailParams,
  GetAdminStaffDetailResult,
} from '../../actions/queries/staffQueries';
import UiStackTemplate from '../../components/common/UiStackTemplate';
import StaffFormContainer from './StaffFormContainer';

const AdminEditStaffContainer: React.FC = () => {
  const { staffId } = useParams<{ staffId: string }>();
  const { isLoading, error, result, executeQuery } = useQuery<GetAdminStaffDetailParams, GetAdminStaffDetailResult>(
    getAdminStaffDetailQuery,
  );

  useEffect(() => {
    if (staffId) {
      executeQuery({
        id: staffId,
      });
    }
  }, [executeQuery, staffId]);

  return (
    <UiStackTemplate isLoading={isLoading} error={error}>
      {result && <StaffFormContainer staff={result.adminStaff} />}
    </UiStackTemplate>
  );
};

export default AdminEditStaffContainer;
