import React from 'react';
import classNames from 'classnames';

import CSSModule from './Pagination.module.scss';

interface Props {
  page: number;
  size: number;
  totalCount: number;
  onChangePage: (page: number) => void;
}

const Pagination: React.FC<Props> = ({ totalCount, page, size, onChangePage }) => {
  const totalPage = Math.ceil(totalCount / size);
  const hasPrev = page > 1;
  const hasNext = page < totalPage;

  const goPrev = () => {
    onChangePage(page - 1);
  };
  const goNext = () => {
    onChangePage(page + 1);
  };

  const classes = classNames(CSSModule.pagination, 'clearfix');
  const prevClasses = classNames(CSSModule.button, CSSModule.goPrev);
  const nextClasses = classNames(CSSModule.button, CSSModule.goNext);

  return (
    <div className={classes}>
      {hasPrev && (
        <div className={prevClasses} onClick={goPrev}>
          前へ
        </div>
      )}
      {hasNext && (
        <div className={nextClasses} onClick={goNext}>
          次へ
        </div>
      )}
    </div>
  );
};

export default Pagination;
