import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { setFlashMessage } from '../actions/common';

export interface CommonState {
  flashMessage: string | null;
}

const initialState: CommonState = {
  flashMessage: null,
};

export default reducerWithInitialState(initialState).case(setFlashMessage, (state, { message }) => ({
  ...state,
  flashMessage: message,
}));
