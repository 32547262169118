import React from 'react';

import StaffForm, { FormValues } from './StaffForm';
import { PhotoData, StaffCategoryName } from '../../../interfaces/staff';

import CSSModule from './AdminEditStaffPage.module.scss';

interface Props {
  title: string;
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => void;
  onCancel: () => void;
  onAddPhoto: (file: File | null) => void;
  onRemovePhoto: (src: string) => void;
  photos: PhotoData[];
  photoError: Error | null;
  isUploading: boolean;
  uploadingError: Error | null;
  categories: StaffCategoryName[];
  onChangeCategories: (categories: StaffCategoryName[]) => void;
  categoryError: Error | null;
  error: Error | null;
  disabled: boolean;
}

const AdminEditStaffPage: React.FC<Props> = ({
  title,
  initialValues,
  onSubmit,
  onCancel,
  onAddPhoto,
  onRemovePhoto,
  photos,
  photoError,
  isUploading,
  uploadingError,
  categories,
  onChangeCategories,
  categoryError,
  error,
  disabled,
}) => {
  return (
    <div className={CSSModule.adminEdittaffPage}>
      <h1 className={CSSModule.title}>{title}</h1>
      <StaffForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        onAddPhoto={onAddPhoto}
        onRemovePhoto={onRemovePhoto}
        photos={photos}
        photoError={photoError}
        isUploading={isUploading}
        uploadingError={uploadingError}
        categories={categories}
        onChangeCategories={onChangeCategories}
        categoryError={categoryError}
        error={error}
        disabled={disabled}
      />
    </div>
  );
};

export default AdminEditStaffPage;
