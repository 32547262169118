import React from 'react';

import Button from '../atoms/button/Button';

import CSSModule from './ExpiresResetTokenPage.module.scss';

interface Props {
  onCancel: () => void;
}

const ExpiresResetTokenPage: React.FC<Props> = ({ onCancel }) => {
  return (
    <div className={CSSModule.expiresResetTokenPage}>
      <div className={CSSModule.description}>
        トークンが無効です。
        <br />
        恐れ入りますが、下記ボタンからパスワード再設定をやり直してください。
      </div>
      <div className={CSSModule.button}>
        <Button onClick={onCancel}>戻る</Button>
      </div>
    </div>
  );
};

export default ExpiresResetTokenPage;
