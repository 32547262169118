import React from 'react';

import useCurrentUser from '../../hooks/store/useCurrentUser';
import AuthenticatedPage from '../../components/auth/AuthenticatedPage';

interface Props {
  children: React.ReactNode;
}

const Authenticated: React.FC<Props> = ({ children }) => {
  const [user] = useCurrentUser();

  if (!user) {
    return <AuthenticatedPage />;
  }

  return <>{children}</>;
};

export default Authenticated;
