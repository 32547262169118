import React from 'react';

import UiStackTemplate from 'src/components/common/UiStackTemplate';
import Pagination from 'src/components/common/Pagination';

import CSSModule from './ListPageTemplate.module.scss';

interface Props {
  title: string;
  isLoading: boolean;
  error: Error | null;
  isEmpty: boolean;
  totalCount: number;
  page: number;
  size: number;
  onChangePage: (page: number) => void;
  leftSideButton?: React.ReactNode;
  rightSideButton?: React.ReactNode;
  children: React.ReactNode;
}

const ListPageTemplate: React.FC<Props> = ({
  title,
  isLoading,
  error,
  totalCount,
  page,
  size,
  isEmpty,
  onChangePage,
  leftSideButton,
  rightSideButton,
  children,
}) => (
  <div className={CSSModule.listPageTemplate}>
    <h1 className={CSSModule.title}>{title}</h1>
    <div className={CSSModule.operations}>
      <div className={CSSModule.operationItem}>{leftSideButton}</div>
      <div className={CSSModule.operationItem}>{rightSideButton}</div>
    </div>
    <UiStackTemplate isLoading={isLoading} error={error} isEmpty={isEmpty}>
      {children}
    </UiStackTemplate>
    <div className={CSSModule.pagination}>
      <Pagination page={page} size={size} totalCount={totalCount} onChangePage={onChangePage} />
    </div>
  </div>
);

export default ListPageTemplate;
