import React from 'react';

import Logo1 from '../../assets/welcome/publication/asahi-shinbun.png';
import Logo2 from '../../assets/welcome/publication/hiruobi.png';
import Logo3 from '../../assets/welcome/publication/ichioshi.png';
import Logo4 from '../../assets/welcome/publication/J-wave.png';
import Logo5 from '../../assets/welcome/publication/KBS-radio.png';
import Logo6 from '../../assets/welcome/publication/live-news-it.png';
import Logo7 from '../../assets/welcome/publication/NihonTV-NEWS24.png';
import Logo8 from '../../assets/welcome/publication/sunday-mainichi.png';
import Logo9 from '../../assets/welcome/publication/TBS-radio.jpg';

import CSSModule from './PublicationRecord.module.scss';

const MEDIA_LOGO_LIST = [[Logo1], [Logo2], [Logo3], [Logo4], [Logo5], [Logo6], [Logo7], [Logo8], [Logo9]];

const PublicationRecord: React.FC = () => (
  <div className={CSSModule.PublicationRecord}>
    <h2 className={CSSModule.PublicationRecord__Title}>掲載実績</h2>
    <div className={CSSModule.PublicationRecord__Scrollable}>
      <ul className={CSSModule.PublicationRecord__LogoList} data-test="1">
        {MEDIA_LOGO_LIST.map(files => (
          <li key={files.join()} className={CSSModule.PublicationRecord__LogoListItem}>
            {files.map(src => (
              <img key={src} src={src} alt="メディア掲載実績" className={CSSModule.PublicationRecord__Logo} />
            ))}
          </li>
        ))}
      </ul>
      <ul className={CSSModule.PublicationRecord__LogoList} data-test="2">
        {MEDIA_LOGO_LIST.map(files => (
          <li key={files.join()} className={CSSModule.PublicationRecord__LogoListItem}>
            {files.map(src => (
              <img key={src} src={src} alt="メディア掲載実績" className={CSSModule.PublicationRecord__Logo} />
            ))}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default PublicationRecord;
