import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

import AppTemplate from '../templates/AppTemplate';
import Button from '../atoms/button/Button';
import Divider from '../atoms/divider/Divider';

import CSSModule from './ThanksPage.module.scss';

type HistoryState =
  | {
      type: 'email';
      email: string;
    }
  | {
      type: 'self';
      postalCode: string;
      address: string;
    }
  | {
      type: 'other';
      recipientName: string;
      postalCode: string;
      address: string;
    };

const ThanksPage: React.FC = () => {
  const navigate = useNavigate();
  const locationState: HistoryState = useLocation().state;

  const onClick = () => navigate('/mypage');

  useEffect(() => {
    if (!locationState?.type) {
      navigate('/mypage');
    }
  }, [navigate, locationState?.type]);

  if (!locationState?.type) {
    return null;
  }

  return (
    <AppTemplate>
      <div className={CSSModule.ThanksPage}>
        <h2 className={CSSModule.ThanksPage__Title}>
          {locationState.type === 'email' ? 'ギフトを送信しました' : 'ギフトの郵送を受付完了しました'}
        </h2>
        {locationState.type === 'email' ? (
          <EmailTypeDescription email={locationState.email} />
        ) : (
          <ShipmentTypeDescription {...locationState} />
        )}
        <Button onClick={onClick}>マイページTOPへ</Button>
      </div>
    </AppTemplate>
  );
};

export default ThanksPage;

interface EmailTypeDescriptionProps {
  email: string;
}

const EmailTypeDescription: React.FC<EmailTypeDescriptionProps> = ({ email }) => {
  return <div className={CSSModule.ThanksPage__Description}>{email} 宛にギフトを送付しました。</div>;
};

type ShipmentTypeDescriptionProps =
  | {
      type: 'self';
      postalCode: string;
      address: string;
    }
  | {
      type: 'other';
      recipientName: string;
      postalCode: string;
      address: string;
    };

const ShipmentTypeDescription: React.FC<ShipmentTypeDescriptionProps> = props => {
  return (
    <div className={CSSModule.ThanksPage__ShipmentDescription}>
      <Divider />
      <p>
        形式: {props.type === 'self' ? '手渡し' : '郵送'}
        <br />
        {props.type === 'other' && (
          <>
            {props.recipientName}様宛
            <br />
          </>
        )}
        〒{props.postalCode}
        <br />
        {props.address}
      </p>
      <Divider />
    </div>
  );
};
