import React, { useState } from 'react';

import {
  OrderedBookOrder,
  RejectedBookOrder,
  ApprovedBookOrder,
  DeliveredBookOrder,
  BookOrderStatus,
} from '../../interfaces/order';
import OrderHeader from './OrderHeader';
import StaffLink from './StaffLink';
import KarteLink from './KarteLink';
import SelectedBookList from './SelectedBookList';

interface Props {
  bookOrder: OrderedBookOrder | RejectedBookOrder | ApprovedBookOrder | DeliveredBookOrder;
}

const BasicOrderItem: React.FC<Props> = ({ bookOrder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleBookList = () => setIsOpen(!isOpen);

  return (
    <div>
      <OrderHeader id={bookOrder.id} time={bookOrder.orderedAt} status={bookOrder.status} />
      <StaffLink staff={bookOrder.staff} />
      {bookOrder.status !== BookOrderStatus.Ordered && (
        <SelectedBookList selectedBooks={bookOrder.selectedBooks} isOpen={isOpen} toggleBookList={toggleBookList} />
      )}
      <KarteLink staffId={bookOrder.staff.id} bookOrderId={bookOrder.id} isDraft={false} />
    </div>
  );
};

export default BasicOrderItem;
