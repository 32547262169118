class FileLoader {
  private file: File;

  private DEFAULT_EXTNAME = '.png';
  private IMAGE_TYPES = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg'];

  constructor(file: File) {
    this.file = file;
  }

  public get extname() {
    const matched = this.file.name.match(/\.[^.]+$/);
    return matched ? matched[0] : this.DEFAULT_EXTNAME;
  }

  public get isImage() {
    return this.IMAGE_TYPES.includes(this.file.type);
  }

  public readAsDataURL(): Promise<string> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        resolve(reader.result as string);
      });
      reader.readAsDataURL(this.file);
    });
  }
}

export default FileLoader;
