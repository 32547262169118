import { useState, useCallback } from 'react';

import API from '../../actions/API';

interface UseMutationHookResult<P, R> {
  error: Error | null;
  isLoading: boolean;
  executeMutation: (query: string, params: P) => Promise<R | void>;
}

interface FetchState {
  isLoading: boolean;
  error: Error | null;
}

const useMutation = <P = void, R = void>(): UseMutationHookResult<P, R> => {
  const [{ isLoading, error }, setState] = useState<FetchState>({
    isLoading: false,
    error: null,
  });

  const executeMutation = async (query: string, params: P) => {
    setState({ isLoading: true, error: null });

    try {
      const response: R = await API.query(query, params);
      setState({ isLoading: false, error: null });
      return response;
    } catch (error) {
      const mutationError = error instanceof Error ? error : new Error('unexpected mutation error');
      setState({ isLoading: false, error: mutationError });
      return;
    }
  };

  return { executeMutation: useCallback(executeMutation, []), isLoading, error };
};

export default useMutation;
