import React from 'react';
import { useForm } from 'react-hook-form';

import { EmailRegexp } from '../../app.constants';
import Button from '../atoms/button/Button';
import Input from '../atoms/form/Input';
import TextArea from '../atoms/form/TextArea';
import Alert from '../atoms/text/Alert';

import CSSModule from './GiftRequestForm.module.scss';

export interface FormValues {
  recipientName: string;
  email: string;
  message: string;
}

interface Props {
  disabled: boolean;
  serverErrors: string[];
  onSubmit: (values: FormValues) => void;
}

const GiftRequestForm: React.FC<Props> = ({ disabled, serverErrors, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  return (
    <div className={CSSModule.GiftRequestForm}>
      <h2 className={CSSModule.GiftRequestForm__Title}>ギフトをリクエストする</h2>
      <div className={CSSModule.GiftRequestForm__Description}>
        <p>選書のプロに約1万円分の本を選んでもらえる「権利のギフト」を、誰かに"おねだり"することができます。</p>
        <ul>
          <li>出産祝い</li>
          <li>入園・入学祝い</li>
          <li>お誕生日祝</li>
          <li>お見舞い</li>
        </ul>
        <p>などの機会に、思い出に残る読書体験を、おじいちゃんおばあちゃんやお友達にプレゼントしていただきませんか。</p>
        <p>※ギフトコードの有効期限は発行から半年間となります</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <Input
            {...register('recipientName', {
              required: 'ギフトリクエスト先名をご入力ください。',
            })}
            id="gift[recipientName]"
            required
            label="ギフトリクエスト先名"
            placeholder="例）本田　太郎"
          />
          {errors.recipientName && <Alert message={errors.recipientName.message} />}
        </div>
        <div className="form-group">
          <Input
            {...register('email', {
              required: 'メールアドレスをご入力ください。',
              pattern: {
                value: EmailRegexp,
                message: 'メールアドレスの形式が不正です。',
              },
            })}
            id="gift[email]"
            required
            label="メールアドレス"
            placeholder="例）book-karte@example.com"
          />
          {errors.email && <Alert message={errors.email.message} />}
        </div>
        <div className="form-group">
          <label htmlFor="gift[message]" className={CSSModule.GiftRequestForm__Label}>
            メッセージ
          </label>
          <div className={CSSModule.GiftRequestForm__MessageDescription}>
            ギフトをリクエストする相手にお伝えしたいメッセージがあれば、ご記入ください。
          </div>
          <TextArea
            {...register('message')}
            id="gift[message]"
            placeholder="例）子どもが本好きになるような絵本を、本屋さんに選んでもらいたいです。おねだりさせてください！"
          />
        </div>
        {serverErrors.map(error => (
          <Alert message={error} />
        ))}
        <Button color="primary" disabled={disabled}>
          リクエストを送信する
        </Button>
      </form>
    </div>
  );
};

export default GiftRequestForm;
