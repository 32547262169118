import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { User } from 'src/interfaces/user';
import Breadcrumbs from 'src/components/common/Breadcrumbs';
import UserPhoto from 'src/components/users/UserPhoto';
import TextWithEllipsis from 'src/components/atoms/text/TextWithEllipsis';
import MenuLink from 'src/components/mypage/MenuLink';

import CSSModule from './ProfileMenuPage.module.scss';

const breadcrumbs = [
  {
    label: 'ホーム',
    link: '/',
  },
  {
    label: 'マイページ',
    link: '/mypage',
  },
  {
    label: 'プロフィール情報・編集',
  },
];

interface Props {
  user: User;
  signOut: () => void;
  unregister: () => void;
}

const ProfileMenuPage: React.FC<Props> = ({ user, signOut, unregister }) => (
  <div className={CSSModule.profileMenuPage}>
    <div className={CSSModule.breadcrumbs}>
      <Breadcrumbs pages={breadcrumbs} />
    </div>
    <Link to="/mypage/profile" className={classNames(CSSModule.panelHeader)}>
      <div className={CSSModule.userPhoto}>
        <UserPhoto src={user.photo} />
      </div>
      <div className={CSSModule.userContent}>
        <h3 className={CSSModule.userName}>
          <TextWithEllipsis text={`${user.name}さん`} />
        </h3>
      </div>
    </Link>
    <MenuLink label="プロフィール" to="/mypage/profile" />
    <MenuLink label="決済方法の設定" to="/mypage/payments" />
    <MenuLink label="マイカルテ編集" to="/mypage/karte-templates" />
    <MenuLink label="ログアウト" onClick={signOut} />
    <MenuLink label="退会する" onClick={unregister} />
  </div>
);

export default ProfileMenuPage;
