import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { parse, stringify } from 'query-string';

import useQuery from '../../hooks/common/useQuery';
import useMutation from '../../hooks/common/useMutation';
import {
  getAdminStaffListQuery,
  GetAdminStaffListParams,
  GetAdminStaffListResult,
} from '../../actions/queries/staffQueries';
import AdminStaffListPage from '../../components/admin/staffs/AdminStaffListPage';
import download from '../../utils/downloader';
import CsvGenerator from '../../utils/CsvGenerator';

const DEFAULT_PAGE = 1;
const DEFAULT_SIZE = 20;

const AdminStaffListContainer: React.FC = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const query = parse(search);
  const page = Number(query.page) || DEFAULT_PAGE;

  const { isLoading, error, result, executeQuery } = useQuery<GetAdminStaffListParams, GetAdminStaffListResult>(
    getAdminStaffListQuery,
  );

  const {
    executeMutation: fetchAllStaffList,
    isLoading: isFetchingAllStaffList,
    error: fetchingAllStaffListError,
  } = useMutation<GetAdminStaffListParams, GetAdminStaffListResult>();

  useEffect(() => {
    executeQuery({
      page,
      size: DEFAULT_SIZE,
    });
  }, [executeQuery, page]);

  const onClickCreateButton = () => {
    navigate('/admin/staffs/new');
  };

  const onDownloadCSV = async () => {
    if (result) {
      const allResult = await fetchAllStaffList(getAdminStaffListQuery, {
        page: DEFAULT_PAGE,
        size: result.staffCount,
      });
      if (allResult) {
        const content = new CsvGenerator({
          items: allResult.adminStaffs,
          headers: [
            '書店員ID',
            '書店員名',
            '書店名',
            'メールアドレス',
            '累計完了数',
            '今月の注文状況',
            'リスト未送信',
            '未配送',
          ],
          generateRow: item => [
            item.id,
            item.name,
            item.bookStoreName,
            item.email,
            `${item.completedOrderCount}`,
            `${item.monthlyLimit - item.monthlyCapacity}/${item.monthlyLimit}`,
            `${item.unlistedOrderCount}`,
            `${item.undeliveredOrderCount}`,
          ],
          sortItems: (item1, item2) => Number(item1.id) - Number(item2.id),
        }).call();
        download({
          filename: 'staffs.csv',
          contentType: 'text/csv',
          content,
        });
      }
    }
  };

  const onClickRow = (staffId: string) => {
    navigate(`/admin/staffs/${staffId}/edit`);
  };

  const onChangePage = (newPage: number) => {
    const newLocation = `${pathname}?${stringify({ ...query, page: newPage })}`;
    navigate(newLocation);
  };

  return (
    <AdminStaffListPage
      staffs={(result && result.adminStaffs) || []}
      isLoading={isLoading}
      error={error || fetchingAllStaffListError}
      page={page}
      size={DEFAULT_SIZE}
      totalCount={(result && result.staffCount) || 0}
      disableDownload={isFetchingAllStaffList}
      onClickRow={onClickRow}
      onClickCreateButton={onClickCreateButton}
      onClickCsvDownloadButton={onDownloadCSV}
      onChangePage={onChangePage}
    />
  );
};

export default AdminStaffListContainer;
