import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { getDraftBookOrderQuery, postOrderQuery } from 'src/actions/queries/orderQueries';
import { PostBookOrderParams, PostBookOrderResult } from 'src/actions/orders';
import { TotalProgressNumber } from 'src/containers/orders/NewOrderContainer';
import Authenticated from 'src/containers/common/Authenticated';
import OrderPaymentMethodPage, { PaymentMethod } from 'src/components/orders/OrderPaymentMethodPage';
import OrderConfirmationPage from 'src/components/orders/OrderConfirmationPage';
import ProgressBarTemplate from 'src/components/templates/ProgressBarTemplate';
import UiStackTemplate from 'src/components/common/UiStackTemplate';
import useQuery from 'src/hooks/common/useQuery';
import useMutation from 'src/hooks/common/useMutation';
import useCurrentUser from 'src/hooks/store/useCurrentUser';
import useToast from 'src/hooks/store/useToast';
import { BookOrder, BookOrderStatus } from 'src/interfaces/order';
import GATracker from 'src/utils/GATracker';

interface GetDraftBookOrderParams {
  staffId: string;
  userId: string;
  status: BookOrderStatus[];
  size: number;
}

interface GetDraftBookOrderResult {
  bookOrders: BookOrder[];
}

const OrderPaymentPage: React.FC = () => {
  const { id: staffId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const showToast = useToast();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [currentUser] = useCurrentUser();
  const {
    result: bookOrderResult,
    isLoading: isFetchingOrder,
    error: fetchOrderError,
    executeQuery: fetchOrder,
  } = useQuery<GetDraftBookOrderParams, GetDraftBookOrderResult>(getDraftBookOrderQuery);

  const {
    executeMutation: postBookOrder,
    isLoading: isSubmitting,
    error: mutationError,
  } = useMutation<PostBookOrderParams, PostBookOrderResult>();

  const bookOrder = bookOrderResult?.bookOrders?.[0] ?? null;
  const userId = currentUser?.id ?? null;

  useEffect(() => {
    if (bookOrderResult && !bookOrder) {
      navigate(`/staffs/${staffId}/orders/new`);
    }
  }, [bookOrderResult, bookOrder, staffId, navigate]);

  useEffect(() => {
    if (!staffId || !userId) {
      return;
    }

    fetchOrder({
      staffId,
      userId,
      status: [BookOrderStatus.Draft],
      size: 1,
    });
  }, [staffId, userId, fetchOrder]);

  const goBackToKartePage = () => {
    navigate(`/staffs/${staffId}/orders/new`);
  };

  const selectPaymentMethod = (selectedPaymentMethod: PaymentMethod) => {
    setPaymentMethod(selectedPaymentMethod);
  };

  const goBackToPaymentMethodSelect = () => {
    setPaymentMethod(null);
  };

  const onSubmit = async () => {
    if (!staffId || !bookOrder) {
      return;
    }

    await postBookOrder(postOrderQuery, {
      staffId,
      isDraft: false,
      karte: {
        gender: bookOrder.karte.gender,
        age: bookOrder.karte.age,
        answer1: bookOrder.karte.answer1,
        answer2: bookOrder.karte.answer2,
        answer3: bookOrder.karte.answer3,
        answer4: bookOrder.karte.answer4,
        answer5: bookOrder.karte.answer5,
        answer6: bookOrder.karte.answer6,
        answer7: bookOrder.karte.answer7,
        answer8: bookOrder.karte.answer8,
        answer9: bookOrder.karte.answer9,
        answer10: bookOrder.karte.answer10,
        karteQuestionTemplateId: bookOrder.karte.karteQuestionTemplate.id,
      },
      couponCode: paymentMethod?.paymentMethodType === 'coupon' ? paymentMethod.couponCode : null,
    });

    showToast('注文を完了しました。');
    navigate(`/staffs/${staffId}/orders/thanks`);
    GATracker.trackEvent('purchase');
  };

  const progressPosition = paymentMethod ? 3 : 2;

  return (
    <ProgressBarTemplate title="決済方法選択" position={progressPosition} total={TotalProgressNumber}>
      <Authenticated>
        <UiStackTemplate isLoading={isFetchingOrder} error={fetchOrderError}>
          {paymentMethod === null && staffId && (
            <OrderPaymentMethodPage staffId={staffId} onSubmit={selectPaymentMethod} onCancel={goBackToKartePage} />
          )}
          {paymentMethod && currentUser && (
            <OrderConfirmationPage
              paymentType={paymentMethod.paymentMethodType}
              couponCode={paymentMethod.couponCode}
              user={currentUser}
              disabled={isSubmitting}
              serverError={mutationError?.message ?? null}
              onSubmit={onSubmit}
              onCancel={goBackToPaymentMethodSelect}
            />
          )}
        </UiStackTemplate>
      </Authenticated>
    </ProgressBarTemplate>
  );
};

export default OrderPaymentPage;
